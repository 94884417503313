/*---------------- Buttons ----------------*/
.activate-segment-content .profile-excel-buttons-wrapper .btn{
  padding: 6px 15px 6px 15px;
  font-size: 16px;
}
/*---------------- Fonts ----------------*/
.activate-segment-content,
.activate-segment-content h4,
.activate-segment-content h2 {
  font-family:Helvetica, Arial, "Roboto", sans-serif !important;
}
.activate-segment-content h2,
.activate-segment-content h4 {
  color: #00424d;
}
/*---------------- Style Card ----------------*/
.activate-segment-content .col-lg-3 {
  padding-left: 0;
  min-width: 309px;
  padding-right: 0;
}
.activate-segment-content .col-lg-3 .card{
  height: 101% !important;
 /*height:calc(100% - 20px) !important*/
}
.activate-segment-content .col-lg-3.spacer .card{
  /*height: 100% !important;*/
  height:calc(100% - 20px) !important;
}
.activate-segment-content .col-lg-3.spacer{
  z-index:-1;
}
.activate-segment-content .col-lg-3 .card-body{
  justify-content: center;
}
.activate-segment-content .col-lg-9 {
  padding-right: 0;
}
.activate-segment-content .row.nav-pills.samcast-menu{
  flex-direction: row;
}
.activate-segment-content .samcast-menu .form-card{
  padding-left: 25px;
}


/*----------- Style All Tiles -----------*/
.activate-segment-content .tile-container .activate-segment-tile {
  border: 1px solid #f4f4f4;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  box-shadow: 0 3px 10px 0 rgb(99 118 129 / 30%);
  margin-bottom: 0;

}

.activate-segment-content .tile-container button {
  background-color: white;
  border: transparent;
  padding: 0;
} 

.activate-segment-content .tile-container{
  width:fit-content;
}

.activate-segment-content .company-list {
  /*display: grid;*/
  /*grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));*/
  /*grid-auto-flow:row;*/
  /*grid-gap: 2rem;*/
  /*width:auto;*/
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: space-around;
}
.activate-segment-content .company-list .tile-container{
  padding: 9px 12px 9px 12px;
}

.activate-segment-content .company-list legend{
  padding-top: 7px;
  padding-bottom: 7px;
  font-size: medium;
  text-transform: none;
}

.plan-semcast {
  text-align: center;
    padding: 20px 20px 20px 20px;
    width: 220px;
    /* height: 220px; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
}

.plan-semcast i {
  font-size: 50px;
  color: #00055d;
  margin: 20px 0px;
  display: block;
}
.plan-semcast img {
  height: 36px;
  max-width: 100%;
}

.plan-semcast.greenline {
padding: 10px;
}
.plan-semcast.greenline img{
  height: 55px;
}
.all-in-one-tiles img{
  height: 62px;
  width: 198px;
  padding-left: 10px;
  padding-right: 10px;
  max-width: 100%;
}
.plan-semcast h5 {
  margin-top: 15px;
}
.plan-semcast p {
  font-size: 16px;
  color: #00055d;
  margin-bottom: 0px;
}

.is-error{
  border-color:#d9534f !important;
}

/*----------- Uploaded Records Section -----------*/
.activate-segment-content .uploaded-records .profile-excel-buttons-wrapper{
  margin-top: 10px;
}
.activate-segment-content .uploaded-records {
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  /*position: fixed;*/
  flex-direction: column;

  /*right: calc((100vw - 90%)/2);*/
}

.uploaded-records .basic-list-group {
  display: flex;

}

.uploaded-records .basic-list-group .list-group{
  display: flex;
  flex-wrap: wrap;
}

.activate-segment-content .col-lg-3 .list-group-item .align-items-center{
  display: flex;
  word-break: normal;
  justify-content: space-between;
}

.badge-primary.badge-pill{
  display: flex;
  flex-wrap: wrap;
  word-break:keep-all;
}

/* .row.nav-pills.samcast-menu .col-md-3.col-lg-3 .card{
  min-width: fit-content;
} */

/*----------- Status Icon On Top Right Of Each Tile -----------*/
.status-icon{
    float: right;
    font-size: 27px !important;
    position: absolute;
    right: -10px;
    top: -14px;
  }
  .status-icon-error{
    color: #d9534f !important;
  }
  .status-icon-progress{
    color: #5bc0de !important;
  }
  .status-icon-success{
    color: #5cb85c !important;
  }
  .status-label{
    color: #fff;
    padding: 5px;
  }
  .status-label-error{
    background-color: #d9534f;
  }
  .status-label-progress {
    background-color: #5bc0de;
  }
  .status-label-success {
    background-color: #5cb85c;
  }


  .activate-segment-content .card{
    height: fit-content;
    margin-top:0 !important;
  }
 /* .col-md-3 .col-lg-3 .card-body .card .mt-3 .mb-3{
    height:fit-content;
  } */

  .k-drawer-content .activate-segment-content .container-fluid{
    padding-top:0;
    padding-left:0;
  }

  .tab-content .mt-3 .main-container{
    margin-top:0;
  }

  .k-drawer-content .activate-segment-content{
    margin-left:0;
  }

  .activate-segment-content .card.p-3{
    /*margin-top:0;*/
  }

.activate-segment-content .col-lg-3 .list-group .list-group-item .record-number{
  width: fit-content;
}

.activate-segment-content .col-lg-3 .list-group .list-group-item .record-text{
width: fit-content;
}
.activate-segment-content .col-lg-3 .basic-list-group {
  width: 100%;
}

.activate-segment-content .col-lg-3 .list-group{
  width:100%;
  display: flex;
  flex-wrap: wrap;
}
@media only screen and (min-width: 1391px) {
  .activate-segment-content .col-lg-3 .list-group .list-group-item{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .activate-segment-content .col-lg-3 .list-group .list-group-item .record-number{
    /*width: fit-content;*/
  }
  .activate-segment-content .uploaded-records{
    align-items: center;
  }

  .activate-segment-content .uploaded-records {
    position: fixed;
  }
}
@media only screen and (max-width: 1390px) {
  .activate-segment-content .col-lg-3 .list-group .list-group-item{
    flex-direction: row;
    align-content: center;
  }
  .activate-segment-content .uploaded-records{
    align-items: center;
  }
}
@media only screen and (max-width: 1500px) {
  .activate-segment-content .uploaded-records{
    position: relative;
  }
  .activate-segment-content .col-lg-9{
    width:auto;
  }
  .activate-segment-content .col-lg-3{
    padding-left: 25px;
    flex: 1 1 auto;
    width: auto;
  }

  .activate-segment-content .col-lg-3 .card{
    width: auto;
    padding-left: 16px;
    padding-right: 16px;
    margin-left: 20px;
    justify-content: center;
    align-items: center;
  }
  .activate-segment-content .col-lg-3 .card-body{
    width: fit-content;
    display: flex;
  }
  .activate-segment-content .col-lg-3 .basic-list-group {
    width: max-content;
  }
  .activate-segment-content .col-lg-3 .card-body .list-group{
    flex-direction: row;
    border: solid 1px rgb(222, 226, 230);
    border-radius: 6px;
  }
  .activate-segment-content .col-lg-3 .list-group .list-group-item{
    flex-direction: column;
    align-content: center;
    border: none;
    /*border-left: none;*/
    border-right: solid 1px rgb(222, 226, 230);
  }
  .activate-segment-content .col-lg-3 .list-group .list-group-item:first-child {
    border-top-right-radius: unset;
    border-bottom-left-radius: 6px;

  }
  .activate-segment-content .col-lg-3 .list-group .list-group-item:last-child {
    border-right: unset;
    border-radius: 6px;
  }
  .activate-segment-content .form-card{
    padding-top: 16px;
  }
}
@media only screen and (min-height: 10rem) {
  .activate-segment-content .col-lg-3 .card{

    box-shadow:0 9px 0 0 white,
    0 -9px 0 0 white,
    6px 0 12px -2px rgb(99 118 129 / 30%),
    -6px 0 12px -2px rgb(99 118 129 / 30%) !important;
  }
}

  .tab-content{
    padding-left:1rem;
  }

.row .tab-content .card-body{
  height: fit-content;
}
  