.container {
    display:flex;
    width: 100%;
}
.column-1 {
    flex-shrink: 0;
    flex-basis: 50%;
}
.column-2 {
    flex-shrink: 0;
    flex-basis: 50%;
}
.section-label {
    padding-top: 1rem;
    padding-left: 1rem;
    color: #476c75;
    font-size: 1.1rem;
    font-weight: bold;
}

.onboarding-content .targeting-section-outer-div .targeting-container {
    padding-left: 0rem;
    margin-left: 0rem;
}