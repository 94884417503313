.k-button-icon.map-search-icon {
    display: inline-block;
    font: normal normal normal 20px/1 FontAwesome;
    /*padding-top: .5rem;*/
}
form {
    font-family: Helvetica, Arial, 'Roboto', sans-serif;
    color: #555;
    display: flex;
    /*padding: 2px;*/
    border: 1px solid currentColor;
    border-radius: 5px;
}
.search-input {
    display:flex;
    border: none;
    margin: 0;
    padding: 7px 8px;
    font-size: 14px;
    border: 1px solid transparent;
    border-radius: inherit;
    height: 2.3rem;
}

.open-map {
    margin-left:1rem;
}

.k-button-icon.fa-map-marker {
    color:blue;
}

input[type="search"]::placeholder {
    color: #bbb;
}
.search-button {
    width: 40px;
    padding: 1rem;
    cursor: pointer;
    opacity: 0.7;
    margin-left: 1.5rem;
    padding-top: .1rem;
    height: 2.3rem;
    background-color: #FFFFFF;
}

@keyframes pulse{
    0%{
        box-shadow:#269DB6 0 0 0 0;
    }
    75%{
        box-shadow:#269DB600 0 0 0 16px;
    }
}

@keyframes fadeinout {
    0%, 100% { opacity: 0;}
    50% { opacity: 1; }
}
.k-button.draw-success-label {
    border: none;
    background-color: green;
    outline: none;
    opacity: 1.0;
    border-radius: .5rem;
    --webkit-animation: fadeinout 3s linear forwards;
    animation: fadeinout 3s linear forwards;
}

.k-label.draw-success-label-text {
    color: white;
}

.k-label.draw-error-label-text {
    color: white;
}

.k-button.draw-error-label {
    border: none;
    background-color: red;
    outline: none;
    opacity: 1.0;
    border-radius: .5rem;
}

.k-button.map-draw-button {
    background-color: transparent;
    border: none;
    width: 3rem;
    margin-right: .5rem;
}

.k-button.map-draw-button-active {
    background-color: transparent;
    border: 1px solid;
    border-color: #269DB6;
    width: 3rem;
    margin-right: .5rem;
}

.map-file-upload-div {
    padding-left: 1.5rem;
}

.map-sample-file-link {
    padding-right: 1.5rem;
}

.map-status-area {
    padding-left: 1.5rem;
}

.map-current-radius {
    padding-left: 1.5rem;
}

.map-error-records-header  {
    padding-right: 3rem;
}

.map-error-records  {
        padding-right: 3rem;
        padding-bottom: .5rem;
        color:red;
        font-size: 11px;
}

.upload-template-dialog-warning {
    background-color: yellow;
}


/**
 * ==============================================
 * Loading Dot Flashing
 * ==============================================
 */
.dot-flashing {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #ffc107;
    color: #ffc107;
    animation: dot-flashing 1s infinite linear alternate;
    animation-delay: 0.5s;
    margin-left: 45%;
    align-self: center;
}
.dot-flashing::before, .dot-flashing::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
}
.dot-flashing::before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #ffc107;
    color: #ffc107;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 0s;
}
.dot-flashing::after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #ffc107;
    color: #ffc107;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 1s;
}

@keyframes dot-flashing {
    0% {
        background-color: #ffc107;
    }
    50%, 100% {
        background-color: rgba(255, 193, 7, 0.2);
    }
}
