.nameFieldsDiv .profileDropDown {
    height: 39px;
}

.sideText, .fontContainer,
.fontContainer .contactUsText
{
    font-family: Helvetica, Arial, "Roboto", sans-serif;

}

.update-profile-fieldset .dropdown-standard {
    width: 100%;
}

.contactUsFormFields .k-form-field-wrap {
    height: 56px;
}

.k-form-buttons .contactUsSubmitButton {
    background-color: var(--global-landing-page-color);
    width: 7em;
}

.k-form-buttons .contactUsSubmitButton:hover {
    background-color: #1d7b8d;
}

.nameFieldsDiv {
    display: flex;
    flex-direction: row;
    flex-basis: 100%;
    justify-content: space-between;

    /*flex: 1 1 0;*/
}

.resetPasswordDiv {
    display: flex;
    flex-direction: row;
    flex-basis: 100%;
    justify-content: space-between;
    width: 100%;
}

.k-form .nameFieldsDiv .k-dropdownlist {
    margin-top: 0;
}

.resetPasswordDiv .resetPasswordField {
    /*width: 32%;*/
}

.k-form .resetPasswordField .k-form-field {
    margin-top: 0;
    width: 100%;
}

.resetPasswordField {
    display: flex;
    flex-direction: row;
}

.contactUsFormFields .k-floating-label-container .profilePageField,
.contactUsFormFields .profilePageField {
    border-style: solid;
    border-width: 2px;
    border-color: rgb(0 0 0 / 8%);
    background-color: whitesmoke;
}

.spacingLine {
    margin: 0 0 1em;
    padding: 0;
    border-width: 0 0 2px;
    border-color: rgba(224, 224, 224, 1);
    border-style: solid;
    width: 100%;
    font-size: 14px;
    text-transform: uppercase;
}

.user-profile-page-container .section-card .resetPasswordDiv #newPassword_hint {
    font-style: normal;
    font-size: 14px;
}

.box {
    /*border-radius: 5px;*/
    margin: 1rem;
    background-color: white;

    border: 1px solid rgba(0,0,0,.08);
    box-shadow: 0 3px 10px 0 rgb(99 118 129 / 30%);
    padding: 25px;
}

.resetPasswordDiv .showHidePassword {
    /*height: 43px;*/
    height: 2.2rem;
    position: relative;
    /*top: 20px;*/
    left: -35px;
    border-radius: 0.25rem;
    /*border-style: solid;*/
    /*border-width: 1.5px;*/
    /*border-color: rgb(0 0 0 / 8%);*/
    background-color: whitesmoke;
    box-shadow: none;
}

@media only screen and (max-width: 900px) {
    /*.container {*/
    /*    !* it place the items in vertical direction *!*/
    /*    flex-direction: column;*/
    /*}*/

    .user-profile-page-container {
        /* it place the items in vertical direction */
        flex-direction: column;
    }

    .box {
        margin: 0 0 1rem;
    }
}
/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 1000px whitesmoke inset;
}

/*Hide show/hide password icon only for Edge browser*/
::-ms-reveal {
    display: none;
}

.user-profile-page-container .update-profile-fieldset {
    width: 100%;
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    justify-content: start;
}

.side-nav-item-wrapper .k-drawer-content .admin-tabstrip-container-card .k-tabstrip {
    width: 100%;
}

.field-and-label-wrapper {
    width: 100%;
}

.profile-page-column-2 {
    margin-left: 2rem;
}

.section-card .k-tabstrip .k-content .user-profile-page-container .resetPasswordDiv .k-form-field-wrap .k-form-error,
.resetPasswordDiv .k-form-field-wrap .k-form-error {
    font-size: 14px;
    font-style: normal;
    color: var(--global-landing-page-color);
}

/*.resetPasswordField .k-form-field .newPasswordField #newPassword_error {*/
/*    font-size: 20px;*/
/*}*/

.section-card .k-tabstrip .k-content .user-profile-page-container .resetPasswordDiv .k-form-field-wrap .k-form-hint,
.resetPasswordDiv .k-form-field-wrap .k-form-hint {
    color: rgb(0 0 0 / 45%);
    font-size: 14px;
}

.field-label-large-center,
.section-card .field-label-large,
.dialog-standard .field-label-large {
    /*color: #00424d;*/
    /*font-family: var(--global-font-family);*/
    /*font-weight: 550;*/
    /*font-size: 1.1rem;*/

    /*padding-top: 1rem;*/
    /*padding-left: 1rem;*/
    color: var(--global-landing-page-color);
    font-size: 18px;
    font-weight: bold;
    font-family: Helvetica, Arial, 'Roboto', sans-serif;
    padding-bottom: 20px;
    align-items: center;
}

.centered-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; /* Adjust the height as needed */
}

.centered-content {
    display: flex;
    justify-content: center;
    align-items: center;
}






