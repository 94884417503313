

/***************Corporate HQ box********************/
.contact-us-column-2.box.fontContainer.section-card.contact-us-page-card.box-shadow-standard.section-card-outer-margins{
    margin-top:0;
}

/***************Body and Form********************/
.contact-us-container {
  margin: 0rem 1rem 1rem 1rem;
  display: flex;
  /* overflow: auto; */
}

.contact-us-column-1.box.section-card.contact-us-page-card.box-shadow-standard.section-card-outer-margins {
  display: flex;
  margin: 0;
}

.container.contactUsContainer.contactUsOuterContainer {
  margin: 0 0 0 0;
  padding: 0 0 0 0;
}

.contact-us-background {
  display: flex;
  flex-direction: column;

  /*background-image: url("../../components/assets/img/hero-home.jpg");*/
  /* background-image: url("../../components/assets/img/landingPageDesk.jpg"); */

  background-size: cover;
  min-height: 100vh;
  min-width: 100%;
  position: fixed;
  background-position: center;

  justify-content: center;
  align-items: center;
}

.contact-us-page-card,
.contact-us-background .contactUsContainer .contact-us-page-card {
  box-shadow: 0 3px 10px 0 rgb(22 22 22 / 90%);
}

/*background-image: url("../assets/img/hero-home.jpg");*/

.container.contactUsContainer.contactUsOuterContainer h1,
.container.contactUsContainer.contactUsOuterContainer h4 {
  font-family: Helvetica, Arial, "Roboto", sans-serif;
}

.contactUsFormFields .k-form-field-wrap {
  height: 56.16px;
  font-family: Helvetica, Arial, "Roboto", sans-serif;
}

.contactUsFormFields .k-floating-label-container .contactUsField,
.contactUsFormFields .contactUsField {
  border-style: solid;
  border-width: 1.5px;
  border-color: rgb(0 0 0 / 8%);
  background-color: whitesmoke;
  height: 2.2rem;
}

.k-form-field .messageField {
  border-radius: 0.25rem;
}

.contactUsText {
  color: #00424d;
  margin: 0 0 10px 0;
  font-family: Helvetica, Arial, "Roboto", sans-serif;
  font-weight: 600;
}

.k-form-buttons .contactUsSubmitButton {
  background-color: #269db6;
  width: 7em;
}

.k-form-buttons .contactUsSubmitButton:hover {
  background-color: #1d7b8d;
}

.nameFieldsDiv {
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
  justify-content: space-between;
  /*flex: 1 1 0;*/
}

.nameFieldsDiv .k-form-field {
  width: 47%;
}

.k-form .nameFieldsDiv .k-floating-label-container {
  width: 47%;
}

.messageFieldLabel {
  font-family: Helvetica, Arial, "Roboto", sans-serif;
  color: rgb(88, 88, 88);
}

.messageFieldLabel .k-label-optional {
  font-family: Helvetica, Arial, "Roboto", sans-serif;
  color: rgb(88, 88, 88);
  font-style: italic;
}

.infoBoxesDiv {
  display: flex;
  flex-direction: column;
  /*padding-top: 1em;*/
  justify-content: space-between;
}
.infoBoxesDiv div {
  font-family: Helvetica, Arial, "Roboto", sans-serif;
  color: rgb(88, 88, 88);
  font-size: 14px;
}
.infoBoxesDiv .column-2.box.fontContainer.section-card.contact-us-page-card.box-shadow-standard.section-card-outer-margins{
  margin-bottom: 0;
}

.contact-us-column-1 {
  flex-shrink: 0; /* shrinks to 0 to apply 70% width*/
  flex-basis: 70%; /* sets initial width to 70% */
}

/*.container .contact-us-column-1 .k-notification-group {*/
/*    z-index: 99999999;*/
/*}*/

/*.contact-us-column-1 .k-notification-content {*/
/*    z-index: 999;*/
/*}*/

.spacingLine {
  margin: 0 0 1em;
  padding: 0px;
  border-width: 0 0 2px;
  border-color: rgba(224, 224, 224, 1);
  border-style: solid;
  width: 100%;
  font-size: 14px;
  text-transform: uppercase;
}

.box {
  padding: 10px;
  /*border-radius: 5px;*/
  margin: 1rem;
}

@media only screen and (max-width: 900px) {
  .contactUsContainer {
    /* it places the items in vertical direction */
    flex-direction: column;
  }

  .box {
    margin: 0 0 1rem;
  }
}
/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px whitesmoke inset;
}

input:-webkit-autofill:focus,
textarea:-webkit-autofill:focus,
select:-webkit-autofill:focus {
  outline: none;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 0px 2px;
}
