/* .side-nav-item-wrapper .k-drawer-content{
  overflow: auto;
} */

.help-center-container.k-card.box-shadow-standard.section-card{
  overflow: auto;
  background-color: #fff;
  height: calc(100vh - 96px);
}

.help-center-container {
  background-color: transparent;
  margin-left: 1%;
  margin-right: 1%;
  overflow: auto;
  height: 100%;
}

.help-center-wrapper {
  overflow: auto;
  font-family: Helvetica, Arial, "Roboto", sans-serif;
  display: flex;
  flex-direction: row;
}

.help-center-container.k-card .k-tabstrip.k-tabstrip-top {
  border-color: transparent;
  box-sizing: border-box;
  display: flex;
}

.help-center-container.k-card .k-content.k-active {
  display: block;
}

.help-center-container.k-card .k-item.k-active {
  border-color: rgba(0, 0, 0, 0.08);
}

.help-center-container .k-animation-container.k-animation-container-relative {
  width: 100%;
}

.help-center-container.k-card .k-tabstrip-items.k-reset span {
  font-family: Helvetica, Arial, "Roboto", sans-serif;
  font-size: 18px;
  color: #476c75;
}
.help-center-container.k-card .k-tabstrip-items .k-active span{
  color: #00424d;
  font-weight: bold;
}
