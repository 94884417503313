.data-enhancement-outter-div .data-enhancement-content{
    margin-left: 1%;
    margin-right: 1%;
}

.data-enhancement-outter-div .card-header{
    font-size: 22px;
    padding: 15px 30px !important;
    color: var(--global-landing-page-color);
    font-weight: 600;
}