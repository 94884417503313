/*********************** Color Drop Down ****************** */
.reports-data-content .color-dropdown{
  display: flex;
  align-self: end;
  padding-top: 5px;
}
.reports-data-content .color-dropdown-component{
  height: 23px;
}
.k-pdf-export .color-dropdown,
.k-pdf-export .business-map.card-body-graphs-map .map-style-radio{
  display: none; /*hide drop down in pdf*/
}

/*********** Table Text Align  **********/
.right{
    text-align: right;
  }

  .k-pdf-export .table-responsive.business-naics .table thead{
    background-color: #ffffff;
  }

  .k-pdf-export .table-responsive.business-naics .table tbody tr:nth-child(odd){
    background-color:#F9F9F9;
  }

.row .col-xl-6.col-lg-6.col-md-6 .report-card {
    position:relative;
    margin:0rem 0.7rem 0.7rem 0.7rem;
  transition: all 0.5s ease-in-out;
  height:fit-content;
  width:auto;
  }

  .row .col-xl-6.col-lg-6.col-md-6.col-sm-6 .card {
    border-color: none;
    box-shadow: none !important;
  }

  .row .col-xl-11.col-lg-11.col-md-11.col-sm-11 .card.business {
    border-color: none;
    box-shadow: none !important;
  }

  .row .col-xl-11.col-lg-11.col-md-11.col-sm-11 .card.business .card-body-businessgraphs{
    height:30rem;
  }