.labels {
    line-height: 20px;
    color: rgba(0, 0, 0, 0.54);
    padding-bottom: .2rem;
    font-size: 16px;
}
.nameFieldsDiv {
    display: flex;
    flex-direction: row;
    flex-basis: 100%;
    justify-content: space-between;

    /*flex: 1 1 0;*/
}
.k-multiselect.k-input.fields {
    border-style: solid;
    border-width: 1.5px;
    border-color: rgb(0 0 0 / 8%);
    background-color: whitesmoke;
    /*height: 2.2rem;*/
    border-radius: 0.25rem;
}
