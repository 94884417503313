.data-append-inner-wrapper{
    display: grid;
}
.data-append-container .tab-button-group-wrapper{
    display: flex;
    flex-direction: column;
    padding: 0 10px 0 10px;
}
.data-append-container .tree-outter-wrapper{
    display: flex;
    /*flex-wrap: wrap;*/
}

.data-append-container .tab-button-group{
    display: flex;
    align-self: center;
}

.data-append-container .tab-button-group .k-button-group{
    flex-wrap: wrap;
}

.data-append-container .tab-button-group button {
    margin-right: 10px;
    height: 40px;
}
.data-append-container .destination-dropdown-wrapper{
    margin-top: 30px;
    width: 30vw;
    display: flex;
    flex-direction: column;
    justify-self: flex-end;
}
.data-append-container .data-append-btn-wrapper{
    margin-top: 30px;
    justify-self: flex-end;
}
.data-append-container .k-table td.k-table-td{
    text-wrap: wrap !important;
}