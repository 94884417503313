.section-card .k-tabstrip .idx-gear-button .k-button {
    color: white;
    background-color: var(--global-landing-page-color);
}

.section-card .idx-grid .k-filtercell-operator {
    display: none;
}

.idx-dialog .idx-dialog-submit-button-div .button-submit {
    width: 9rem;
}

.section-card .idx-grid .k-master-row .idx-tag-gear-dropdown-div {
    vertical-align: middle;
    padding: 0px;
    text-align: center;
}

.section-card .idx-grid .k-toolbar .idx-grid-toolbar-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
}

.idx-dialog .k-dialog-content .idx-dialog-columns-container {
    display: flex;
    flex-direction: column;
}

.idx-dialog .k-dialog-content .idx-dialog-row-bottom {
    padding-top: 1rem;
}

.idx-dialog .k-dialog-content .idx-dialog-row-bottom .idx-save-to-group-div,
.idx-dialog .k-dialog-content .idx-dialog-row-bottom .idx-please-confirm-div {
    padding-top: 1rem;
}

.idx-dialog .k-dialog-content .idx-dialog-row-top {
    display: flex;
    flex-direction: row;
}

.idx-dialog .k-dialog-content .idx-dialog-row-bottom .idx-dialog-domain-tag-names-div,
.idx-dialog .k-dialog-content .idx-dialog-row-bottom .idx-dialog-group-checkbox-div {
    display: flex;
    flex-direction: row;
    flex-basis: 100%;
    justify-content: space-between;
}

.idx-dialog .k-dialog .k-dialog-content .idx-disclaimer-1,
.idx-dialog .k-dialog .k-dialog-content .idx-disclaimer-2 {
    background-color: #bdd8dd;
}

.idx-dialog .k-dialog .k-dialog-content .idx-disclaimers-div {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.idx-dialog .k-dialog .k-dialog-content .idx-form-field {
    margin-bottom: 1rem;
}

.idx-dialog .k-dialog .k-dialog-content .idx-disclaimer-1 {
    width: 100%;
    padding-bottom: 0rem;
}

.idx-dialog .k-dialog .k-dialog-content .idx-disclaimer-2 {
    width: 85%;
    padding-bottom: 0rem;
    margin-left: 1rem;
}

.idx-dialog .k-dialog .idx-dialog-submit-button-div {
    display: flex;
    flex-direction: row;
    justify-content: end;
}

.idx-dialog-save-group-hint {
    font-family: Helvetica, Arial, "Roboto", sans-serif;
    font-size: 12px;
    font-style: italic;
    color: gray;
}

.idx-dialog .k-dialog .idx-please-confirm-div .k-checkbox-label {
    font-weight: 600;
    font-size: 13px;
}

.idx-pixel-dialog .k-dialog .pixel-dialog-text-container {
    display: flex;
    flex-direction: column;
}

.idx-pixel-dialog .k-dialog .pixel-dialog-text-container .pixel-dialog-text {

}

.idx-pixel-dialog .k-dialog .pixel-dialog-text-container .pixel-dialog-text-1,
.idx-pixel-dialog .k-dialog .pixel-dialog-text-container .pixel-dialog-text-2 {
    margin-bottom: 1rem;
}

.idx-pixel-dialog .k-dialog .pixel-dialog-text-container .pixel-dialog-text-2 {
    background-color: #bdd8dd;
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-self: center;

    white-space: pre;
}

.idx-pixel-dialog .idx-pixel-dialog-button-container {
    display: flex;
    flex-direction: row;
    justify-content: end;
}

.idx-pixel-dialog .k-dialog .pixel-dialog-text-container .pixel-dialog-text-1,
.idx-pixel-dialog .k-dialog .pixel-dialog-text-container .pixel-dialog-text-3 {
    text-align: center;
}

.idx-pixel-dialog .pixel-dialog-text-3 .idx-gear-icon {
    height: 1.5rem;
}

.idx-dialog-container .idx-dialog-tree .tab-button-group{
    display: flex;
    flex-direction: row;
    justify-self: center;
}
.idx-dialog-tree .tree-table-wrapper .tab-button-group-wrapper{
    display: grid;
    margin-top: 10px;
    margin-bottom: 20px;
}

