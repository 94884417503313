.tree-list-pager-wrapper .k-pager-info {
    text-align: left;
    justify-content: flex-start;
    padding-left:6rem;
    flex: 1 1;
    order: 9;
}

/*.segment-dashboard-content .k-pager-wrap.k-pager.k-widget.k-grid-pager.k-grid-pager {*/
/*    padding-left: 600px !important;*/
/*}*/
.tree-list-pager-wrapper .k-pager-wrap {
    padding-left: 650px !important;
}
.segment-dashboard-content .k-column-title {
    padding-right:6px;
}

.segment-dashboard-content .k-grid-header .k-header{
    background-color: var(--global-landing-page-color) !important;
}

.segment-dashboard-content .k-grid.k-grid-md.k-treelist-scrollable .k-table-tbody button:hover{
    box-shadow: none !important;
}

.segment-dashboard-content .k-grid tr.k-alt{
    /*background-color:rgba(0, 0, 0, 0) !important;*/
}
.segment-dashboard-content .k-pager-numbers .k-selected {
    cursor: inherit;
    z-index: 2;
    box-shadow: inset 0 0 0 2px rgba(255, 99, 88, 0.12);
}

.segment-dashboard-content .segment-name .k-zindex-0{
    display: flex;
}

.segment-dashboard-content .k-grid td {
    border-top: 0.1mm solid rgba(0, 0, 0, 0.08) !important;
    text-overflow: clip;
}
.segment-dashboard-content .k-grid-header .k-filter-row .k-filtercell{
    min-width: 120px;
}
.segment-dashboard-content .k-grid.k-grid-md.k-treelist-scrollable .k-button-text{
    text-wrap: wrap;
    text-align: left;

}
.k-animation-container.k-animation-container-relative{
    width:fit-content;
}
.k-animation-container.k-animation-container-relative .k-column-list-wrapper form{
    display: block;
    border:none;
}

.segment-dashboard-content .k-header.k-drag-clue{
    background-color: #fafafa;
}
.segment-dashboard-content .k-pager-wrap.k-pager.k-widget.k-grid-pager.k-grid-pager {
    padding-left: 750px;
}
.segment-dashboard-content .tree-list-pager-wrapper {
    order: -1;
    position: sticky;
    top: 0px;
    z-index: 100;
    width: 100%;
    border-top: rgba(0, 0, 0, 0.08);
}

.segment-dashboard-content .k-treelist-scrollable .k-filterable.k-header,
.segment-dashboard-content .k-treelist-scrollable .k-header{
    top: 52px !important;
}

.segment-dashboard-content .k-treelist-scrollable .k-grid-header .k-filter-row th{
    top: 86px !important;
}
.segment-dashboard-content .k-grid-header .k-grid-filter:hover{
    background-color: var(--global-landing-page-color);
    border: white solid 1px;
}

.segment-party-type .segment-party-icon {
    /*font-size: 25px !important;*/
    height: 25px;
}

.segment-scheduled-type .segment-scheduled-icon {
    /*font-size: 25px !important;*/
    height: 25px;
}

.segment-dashboard-content td a:hover {
    text-decoration: underline;
}

.segment-dashboard-content .k-header,
.segment-dashboard-content .k-header .k-icon.k-i-sort-asc-sm,
.segment-dashboard-content .k-header .k-icon.k-i-sort-desc-sm,
.segment-dashboard-content .k-header .k-sort-order,
.segment-dashboard-content .k-icon.k-i-more-vertical{
    background-color: var(--global-landing-page-color);
    color: #FFFFFF;
    border-color: #FFFFFF;
    font-size: 16px;
    cursor:pointer;
}

.segment-dashboard-content .k-icon.k-i-caret-alt-down,
.segment-dashboard-content .k-icon.k-i-caret-alt-right{
    font-size: 20px;
    font-weight: bold;
}

.segment-dashboard-content .d-flex .btn.btn-primary.shadow.btn-xs.sharp button{
    background-color: var(--global-landing-page-color);
    color: #FFFFFF;
}

.disabled{
  color: currentColor;
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
}

.segment-dashboard-content .k-grid-header .k-link{
    justify-content: center;
}
/**
 * ==============================================
 * Loading Dot Flashing
 * ==============================================
 */
.dot-flashing {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #ffc107;
    color: #ffc107;
    animation: dot-flashing 1s infinite linear alternate;
    animation-delay: 0.5s;
    margin-left: 47%;
    align-self: center;
}
.dot-flashing::before, .dot-flashing::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
}
.dot-flashing::before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #ffc107;
    color: #ffc107;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 0s;
}
.dot-flashing::after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #ffc107;
    color: #ffc107;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 1s;
}

@keyframes dot-flashing {
    0% {
        background-color: #ffc107;
    }
    50%, 100% {
        background-color: rgba(255, 193, 7, 0.2);
    }
}

.error-status-icon {
    animation: error-status-fadeinout 5s infinite;
}

@keyframes error-status-fadeinout {
    50% {opacity: .2;}
}
