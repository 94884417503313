.account-details-container .user-info-section {
    display: flex;
    flex-direction: column;
}

.account-details-container .company-info-section {
    display: flex;
    flex-direction: column;
}

.account-details-container .company-info-section .account-details-state-div {
    min-width: 4rem;
}

.account-details-container .company-info-section .account-details-zip-div {
    margin-left: 1rem;
    flex-grow: 1;
}

.account-details-container .user-info-section .account-details-first-name-div,
.account-details-container .user-info-section .account-details-last-name-div,
.account-details-container .company-info-section .account-details-company-name-div,
.account-details-container .company-info-section .account-details-address-div,
.account-details-container .company-info-section .account-details-city-div,
.account-details-container .company-info-section .account-details-state-zip-container,
.account-details-container .billing-info-section .account-details-billing-contact-div,
.account-details-container .billing-info-section .account-details-billing-email-div,
.account-details-container .billing-info-section .account-details-billing-phone-div,
.account-details-container .billing-info-section .account-details-ads-type-div {
    flex-basis: 49%;
}

.account-details-container .billing-info-section {
    display: flex;
    flex-direction: column;
}

.account-details-container .user-info-section .account-details-first-last-name-container,
.account-details-container .company-info-section .account-details-city-state-zip-container,
.account-details-container .company-info-section .account-details-company-name-address-container,
.account-details-container .billing-info-section .account-details-billing-contact-email-container,
.account-details-container .billing-info-section .account-details-billing-phone-ads-type-container,
.account-details-container .company-info-section .account-details-state-zip-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

@media only screen and (max-width: 750px) {
    .account-details-container .user-info-section .account-details-first-last-name-container,
    .account-details-container .company-info-section .account-details-city-state-zip-container,
    .account-details-container .company-info-section .account-details-company-name-address-container,
    .account-details-container .billing-info-section .account-details-billing-contact-email-container,
    .account-details-container .billing-info-section .account-details-billing-phone-ads-type-container,
    .account-details-container .company-info-section .account-details-state-zip-container {
        flex-direction: column;
    }

    .account-details-container .company-info-section .account-details-zip-div {
        margin-left: 0rem;
        /*flex-grow: 1;*/
    }
}

.account-details-container .account-details-form-field[data-restricted] {
    cursor:pointer;
}

.account-details-container .account-details-form-field[data-restricted]:hover {
    cursor:not-allowed;
}

.account-details-container .account-details-contact-banner {
    height: 2.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #bdd8dd;
    align-items: center;
    border-radius: 0.25rem;
    margin-bottom: 1rem;

    margin-top: 1rem;
}

.account-details-container .account-details-contact-banner .account-details-contact-banner-text {
    color: #00424d;
}
.account-details-container .account-details-contact-email-div{
    display: flex;
    flex-direction: column;
}
.account-details-container .connection-settings{
    border-style: solid;
    border-width: 1.5px;
    border-color: rgb(0 0 0 / 30%);
    background-color: whitesmoke;
    font-family: Helvetica, Arial, "Roboto", sans-serif;
    width: 49%;
/* height: 2.2rem; */
    border-radius: 0.25rem;
}