
.k-button.employees-range-button {
    width: 8rem;
    /*height: 2.5rem;*/
    background-color: #FFFFFF;
    color: #00424D;
    border: 1px solid #00424D;
}
.k-button.employees-range-button:hover {
    background-color: #FFFFFF;
}
.k-button.k-selected.selected-range {
    background-color: #476c75;
    color: #FFFFFF;
    border-color: #FFFFFF;
}
.k-button.unselected-range {
    width: 8rem;
    /*height: 2.5rem;*/
    background-color: #FFFFFF;
    color: #00424D;
    border: 1px solid #00424D;
}
.employees-range-container {
    display:flex;
    flex-flow: row wrap;
}
.k-button-group.employees-range-button-group {
    padding-top: .1rem;
}
.employees-range-label {
    font-size:10px;
}
