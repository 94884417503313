.privacyPolicyMainDiv .c6,
.privacyPolicyMainDiv .c5,
.privacyPolicyMainDiv .c24,
.privacyPolicyMainDiv .c8,
.privacyPolicyMainDiv .c18,
.privacyPolicyMainDiv .c14,
.privacyPolicyMainDiv .c25,
.privacyPolicyMainDiv .c22,
.privacyPolicyMainDiv .c17,
.privacyPolicyMainDiv .c21,
.privacyPolicyMainDiv .c13,
.privacyPolicyMainDiv .c7,
.privacyPolicyMainDiv .c15,
.privacyPolicyMainDiv .c9
{
    font-family: 'Josefin Sans', sans-serif;
}

.privacy-policy-container {

    background-image: url("../assets/img/hero-home.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;


    min-height: 100vh;
    min-width: 100%;
    /*position: fixed;*/


    padding-top: 1.5rem;
    padding-bottom: 5rem;
}

.privacy-policy-container .privacy-policy-card .privacy-policy-title {
    font-size: 2.5rem;
}

.privacy-policy-container .privacy-policy-card {
    padding: 2rem;
    box-shadow: 0 3px 10px 0 rgb(22 22 22 / 90%);
    margin-left: auto;
    margin-right: auto;
}

.privacy-policy-footer-wrapper {
    background: rgb(2,0,36);
    background: linear-gradient(0deg, rgba(2,0,36,1) 0%, rgba(0,66,77,1) 0%, rgba(71,108,117,1) 100%);
    box-shadow: 0 6px 20px 0 rgba(99, 118, 129, 0.5);
    padding: 0.5rem;
    position: fixed;
    width: 100%;
    bottom: 0;
}