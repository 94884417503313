.admin-job-status .form-control.form-control-custom{
    height: fit-content;
    border: solid 1px #6e6e6e !important;
}
.admin-job-status .card-title{
    font-size: 16px;
    font-family: Helvetica, Arial, 'Roboto', sans-serif;
    color: #00424D;
    font-weight: bold;
}

.admin-job-status .react-bootstrap-table th{
    font-size: 16px;
}
.admin-job-status #pageDropDown{
    font-size: 12px;
    border: solid 1px rgb(222, 226, 230);
    padding: 6px 10px 6px 10px !important;
    border-radius: 5px !important;
}
.admin-job-status .dropdown-menu{
    font-size: 12px;
    right: auto !important;
    left: 50px !important;
    bottom: -10px !important;
}
.admin-job-status .btn.btn-primary.float-right{
    padding: 6px 10px 6px 10px !important;
    font-size: 14px;
}
.admin-job-status .card .react-bootstrap-table{
    overflow-x: auto;
    width: 100%;
}
.admin-job-status input{
    border-radius: 5px !important;
}
.admin-job-status select{
    border-radius: 5px !important;
}