.k-button-icon.fa-democrat {
    color: blue;
}
.k-button-icon.fa-republican {
    color: red;
}
.k-button.k-selected.selected-political-party {
    background-color: #476c75;
    color: #FFFFFF;
    border-color: #476c75;
}
.default-political-party {
    background-color: whitesmoke;
    color: black;
    /*border-color: whitesmoke;*/
    border-color: rgba(0,0,0,.08);;
}

.political-party-button-group {
    border-radius: 0.25rem;
    height: 2.2rem;
}