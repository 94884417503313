.perm-groups-dialog .k-dialog .k-dialog-content .k-dialog-buttongroup .perm-groups-dialog-buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.perm-groups-dialog .k-dialog .k-dialog-content .k-dialog-buttongroup .perm-groups-dialog-buttons-container .perm-groups-dialog-delete-button {
    margin-left: 0rem;
}

.perm-groups-dialog-form-container {
    margin-bottom: 1rem;
}

.perm-groups-dialog-container {
    margin-bottom: 1rem;
}

.admin-tabstrip-container-card .k-tabstrip .k-content .perm-groups-dash-container .k-filter-row .k-filtercell .k-filtercell-operator {
    display: none;
}

.admin-tabstrip-container-card .k-tabstrip .k-content .perm-groups-dash-container .k-grid-container {
    max-height: 55vh;
}

.perm-groups-dialog .k-dialog .k-dialog-content .perm-groups-dialog-container .perm-users-form-table-section .k-grid-container {
    max-height: 50vh;
}

.perm-groups-dialog .k-dialog {
    max-height: 100vh;
}

.perm-groups-dialog .k-dialog-content .perm-users-form-table-section .k-grid-header {
    background-color: var(--global-landing-page-color);
}

.perm-groups-dialog .k-dialog-content .perm-users-form-table-section .k-grid-header .k-header {
    background-color: var(--global-landing-page-color);
    color: white;
}

.perm-groups-dialog .k-dialog .k-dialog-content {
    padding-bottom: 0rem;
}

.perm-groups-dialog .k-dialog .wrapper .k-dialog-buttongroup {
    border-color: rgba(0,0,0,.08);
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    padding-left: 0rem;
    padding-right: 0rem;
}

.group-permissions-dialog-roles-dropdown .not-a-member-dropdown-option {
    color: darkgrey;
    font-style: italic;
}

div.k-animation-container.k-animation-container-relative.k-animation-container-shown{
    z-index:10005 !important;
}




