.upload-drop-area {
    margin-top: 1rem;
    background-color: #ffffff;
    background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%236ebdcf' fill-opacity='0.11' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E"); border: 1px dashed #00424D;
    padding: 16px 0px;
    height: 4rem;
    /*width: 70rem;*/
    text-align: center;
    vertical-align: center;
    /*border-radius: 10px;*/
    box-shadow: 0px 12px 23px 0px rgb(62 73 84 / 4%);
}
.fa-spin.upload-loading-icon {
    font-size: 24px !important;
    color: #00424D;
}
.icon-upload.upload-icon {
    font-size: 24px !important;
}
.icon-file.file-icon {
    font-size: 24px !important;
}
.upload-label {
    font-size: 16px;
    padding-left: 1rem;
}

@media (max-width: 1105px) {
    .file-upload-buttons .upload-lower-label {
        font-size: 14px;
        padding-left: 0;
        padding-top: 1rem;
    }
}

.upload-lower-label {
    font-size: 14px;
    padding-left: 1rem;
    padding-top: 0;
}

.file-upload-buttons {
    margin-top: 1.2rem;
}
.k-button.view-mapping-button {
    background-color: var(--global-landing-page-color);
    color: #FFFFFF !important;
    border-radius:.25rem;
    height: 2.2rem;
    border-color: #FFFFFF;
}
.k-button.cancel-upload-button {
    background-color: var(--global-landing-page-color);
    color: #FFFFFF !important;
    border-radius:.25rem;
    height: 2.2rem;
    border-color: #FFFFFF;
}