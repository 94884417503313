/************* Sticky Header ***************/
.business-report-dashboard.sticky{
  margin-right: 76px;
  margin-left: 28px;
  flex-direction: row;
  display:flex;
  border-radius: 0.5rem;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.08);
  border-width: 1px;
  box-shadow: 0 3px 10px 0 rgb(99 118 129 / 30%);
  background-color: white;
  margin-bottom: 5px;
}
/*********************** Color Drop Down ****************** */
.business-report-dashboard .color-dropdown{
    display: flex;
    align-items: center;
    padding-top: 5px;
}
.business-report-dashboard .color-dropdown-component{
    height: inherit;
}
/************* Edit Dialogue ***************/
.reports-display-content .select-all-wrapper{
    padding-left: 10px;
}
.rearrange-wrapper .label-wrapper{
    display: flex;
    flex-direction: row;
}
.reports-display-content .indent-wrapper-logo{
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
#history-logo-img{
    position:relative;
}
#logo-history-dropdown{
    border-radius: 0.375rem;
    color: rgba(88, 88, 88, 88);
    font-family: Helvetica, Arial, 'Roboto', sans-serif;
    font-size: 16px;
}
/************* Edit Dialogue-reset button ***************/
.rearrange-wrapper .reset-btn.btn{
  background-color: #ffffff;
  color: rgba(88, 88, 88, 88);
  height: 24px;
  border-color: var(--global-footer-color) ;
  padding: 0px 4.5px 0px 4.5px;
  margin-left: 50px;
}
/************* Edit Dialogue-include/exclude items ***************/

.reports-display-content .include-exclude-options{
  padding-bottom: 2px;
  display: flex;
}

.reports-display-content .include-exclude-options .selectall-checkbox{
  padding-right: 5px;
}

.reports-display-content .include-exclude-options .select-all-wrapper label{
  padding-left: 3px;
}

.reports-display-content input:indeterminate {
  background-color: var(--global-footer-color)  !important;
}


/************* Edit Dialogue-logo ***************/
.reports-display-content .logo-default-display{
  width:200px;
  height:20px;
  padding-left: 10px;
}

.reports-display-content .input-group .custom-logo-display{
  width:200px;
  height:30px;
  padding-left: 10px;
  display: inline-flex;
  align-self: center;
}
.reports-display-content .input-group{
  align-items: baseline;
  display: flex;
}
/************* Report ***************/
.container-fluid .row.pdf-data.reports-data-content{
  width:auto;
  }

.reports-display-content .modal.fade.bd-example-modal-lg .modal-dialog.modal-lg .logo-tooltip{
  font-size: 14px;
}
.reports-display-content .modal.fade.bd-example-modal-lg .modal-content .modal-body .form-control.input-rounded.report-date{
  width:fit-content;
}

.reports-display-content .modal.fade.bd-example-modal-lg .form-control.form-control.input-rounded{
  border-radius: 0.375rem !important;
}

.page-template.business-dashboard .header {
    width: 100%;
    height: 90px;
    background-color: #d7eef2;
    position: absolute;
    text-align: left;
}

/*PDF Footer Position*/
.page-template.business-dashboard .footer .copyright,
.page-template.business-dashboard .footer .page-number-wrapper{
    vertical-align: bottom;
    padding: 0;
    height: fit-content;
    display: inline-block;
}
.page-template.business-dashboard .footer .page-number-wrapper{
    float: right;
}
.page-template.business-dashboard .footer {
    background-color: #ffffff;
    border-top: 1px solid black;
    font-size: 20px;
    font-family: Helvetica, Arial, "Roboto", sans-serif !important;
    color:black;
    text-align: left;
    margin-left: 2%;
    margin-right: 2%;
    width: 96%;
    height: 90px;
    padding-left:0;
    position:absolute;
    bottom: 0;
}

/*****PDF Logo Position *****/
.page-template.business-dashboard .header .report-logo{
    float: left;
    width:auto;
    text-align: left;
    margin-top: 20px;
    height: 30px;
    /*margin-left: -300px;*/
    display: block;
}
.page-template.business-dashboard .header .report-information.aside{
    text-align: right;
    font-size: 25px;
    float: right;
    margin-left: auto;
    margin-right: 30px;
    font-family:Helvetica, Arial, "Roboto", sans-serif;
}

.page-template.business-dashboard .report-information.aside .report-title{
    margin-left: auto;
    margin-right: 0;
}
.page-template.business-dashboard .report-information.aside .report-date{
    margin-left: auto;
    margin-right: 0;
}


      .k-pdf-export .business-dashboard .table td,
      .k-pdf-export .business-dashboard .table p{
        /*font-size: 20px !important;*/
        padding-top: 10px;
        padding-bottom: 10px;

      }
      .k-pdf-export .business-dashboard .table th {
        /*font-size: 20px !important;*/
         padding-top: 10px;
         padding-bottom: 10px;
         /*font-weight: bolder;*/
      }
  .k-pdf-export .business-dashboard .card h4{
      /*font-size:26px;*/
      /*font-weight: 500;*/
  }
  .k-pdf-export{
      font-family:Helvetica, Arial, "Roboto", sans-serif;
      /*font-size:large;*/
  }
.k-pdf-export .business-dashboard canvas{
    width: 600px !important;
    margin-top: 20px;
    height: auto !important;
}
.k-pdf-export .card-body-businessgraphs #naics{
    width: 1200px !important;
    height: auto !important;
}

 /********START**************** Chart Size and Position For Different Window Sizes **************START************/

 /*@media screen and (min-width: 3840px){*/

 /*   }*/
 /*  */
 /*   @media screen and (min-width: 2880px) and (max-width: 3839px){*/

 /*   }*/
 /*  */
 /*   @media screen and (min-width: 2560px) and (max-width: 2879px){*/

 /*   }*/
 /*  */
 /*   @media screen and (min-width: 1921px) and (max-width: 2559px){*/

 /*   }*/
 /*  */
 /*   @media screen and (min-width: 1299px) and (max-width: 1920px){*/
 /*   }*/

 /*@media screen and (min-width:1281px) and (max-width: 1300px){*/
 /*     .col-xl-6.col-lg-6.col-md-6 .card{*/
 /*       overflow: auto;*/
 /*     }*/
 /*    }*/

 /*    @media screen and (min-width:1201px) and (max-width: 1280px){*/
 /*     .col-xl-6.col-lg-6.col-md-6 .card{*/
 /*       overflow: auto;*/
 /*     }*/
 /*    }*/
 /*   */
 /*    @media screen and (min-width:1000px) and (max-width: 1200px){*/
 /*     .col-xl-6.col-lg-6.col-md-6 .card{*/
 /*       overflow: auto;*/
 /*     }*/

 /*    }*/

    /******END****************** Chart Size and Position For Different Window Sizes **************END************/

/*----------- Backdrop-----------*/
.modal-backdrop,
.modal-backdrop.fade,
.modal-backdrop.fade.show {
  width: 100%;
  height: 100%;
}

.modal-backdrop.fade{
  position: relative;
  backdrop-filter: blur(10px);

}

.modal-backdrop{
    /* z-index: 0 !important; */
    backdrop-filter: blur(10px);

}

.modal-backdrop.show {
  opacity: 0.3 ;
  /* backdrop-filter: blur(10px)!important; */
}

/*----------- Popup Dialog-----------*/
.modal{
  position:absolute;
  z-index: 1500;
}

.reports-display-content .form-control.input-rounded{
height:2.2rem;
}
