#hero .btn-get-started {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 30px;
  border-radius: 10px;
  transition: all 0.5s ease-out;
  color: #fff;

  /* border: 2px solid #3498db;*/

  background-image: linear-gradient(96deg, #259eb7 0%, #02e6fb 100%);
}

#hero .btn-get-started:hover {
  background-image: linear-gradient(96deg, #02e6fb 0%, #259eb7 100%);
  color: #fff;
}
.template-list li {
  list-style-type: square;
  margin-left: 30px;
}

.required-asterisk:after {
  content:" *";
  color: red;
}
.MyLoader_overlay.overlay {
  background-color: #fff;
  opacity: 0.9;
}