.getting-started-container iframe{
    border-color: rgb(50, 54, 57);
    border-width: 2px;
    border-style: solid;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.getting-started-container .pdf-wrapper{
    position: relative;
    height: calc(100vh - 220px);
}
