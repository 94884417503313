.column-mapping-dialog {
    overflow: auto;
}

.column-mapping-container {
    display:flex;
    flex-direction: row;
    width: 100%;
    font-family: Helvetica, Arial, 'Roboto', sans-serif;
}

.column-mapping-instructions {
    font-size: 1rem;
}
.column-mapping-container .col-sm-6.time-dropdowns{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 0;
    flex-grow: 1;
    margin-top: auto;
}
.column-mapping-time-zone-control-div {
    margin-right: .4rem;
    float:right;
    flex-grow: 1;
    text-align: right;
}

.column-mapping-date-format-control-div {
    margin-right: .4rem;
    float:right;
    flex-grow: 1;
    margin-top: .2rem;
    text-align: right;
}

.k-dropdownlist.column-mapping-time-zone-date-format-controls {
    width: 11rem;
    background-color: whitesmoke !important;
}

.column-mapping-column-1 {
    flex-shrink: 0;
    flex-basis: 50%;
    padding-right: 2.5rem;
}

.column-mapping-control-section-left {
    margin-top: .8rem;
    margin-bottom: .8rem;
    margin-left: 1rem;
}

.column-mapping-control-section {
    background-color: whitesmoke;
    margin-top: 1rem;
}

.column-mapping-control-section-text {
    width:100%;
}

.column-mapping-control-section-text-left {
    width:100%;
    margin-right: 1rem;
}

.column-mapping-file-type-control {
    width: 18rem;
    margin-top: .2rem;
    margin-left: .8rem;
    margin-bottom: .2rem;
}

.column-mapping-header-rows-control,
.column-mapping-delimiter-control {
    width: 18rem;
    margin-top: .2rem;
    margin-bottom: .2rem;
}

.column-mapping-columns-area {
    margin-left: .5rem;
}

.column-mapping-column-error {
    border-color: red;
}

.k-window-titlebar {
    background-color:var(--global-landing-page-color) !important;
    color: #FFFFFF !important;
}

.column-mapping-template-container {
    display:flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    overflow-y:auto;
    font-family: Helvetica, Arial, 'Roboto', sans-serif;
}

.column-mapping-template-container-column-1 {
    flex-shrink: 0;
    flex-basis: 50%;
    padding-right: 2.5rem;
}

.column-mapping-template-container-column-2 {
    flex-shrink: 0;
    flex-basis: 50%;
    padding-right: 2.5rem;
}

.column-mapping-template-section-text {
    color: #FFFFFF;
    background-color:var(--global-landing-page-color);
    width:100%;
}

.k-window .k-window-content .k-grid-header .k-header {
    background-color: var(--global-landing-page-color);
    color: white;
}

.column-mapping-multiple-types-msg {
    padding-top:.6rem;
    margin-left: .5rem;
    color: red;
    font-size:14px;
}