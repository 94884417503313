.k-button.test-connection {
    color: #00424D;
    border-color: #00424D;
    background-color: #FFFFFF;
}
.ftp-settings-label-font {
    /*font-weight: bold;*/
    /*font-size: 1.1rem;*/
    color: rgba(0, 0, 0, 0.54);

    line-height: 20px;
    padding-bottom: 0.2rem;
    font-size: 14px;
}
.ftp-settings-controls {
    height: 2.5rem;
    color: rgba(0, 0, 0, 0.54);
    line-height: 20px;
    font-size: 16px;
}
.ftp-settings-controls.ftp-protocols {
    background-color: #FFFFFF;
}
.ftp-settings-controls.transfer-mode {
    background-color: #FFFFFF;
}
.k-button.delete-settings {
    color: red;
    text-decoration: underline;
    padding-left: 3rem;
}

.dialog-standard .k-dialog-buttongroup .test-con-button {
    width: 8rem;
    /*margin-left: 0rem;*/
}

.dialog-standard .k-dialog-buttongroup .dialog-button-delete {
    margin-left: 0rem;

}

.ftps3-dialog .k-dialog .k-dialog-content .k-grid-layout .ftp-dialog-host-site-field {
    width: 65.2%;
    margin-right: 1.85rem;
    margin-left: 2.2rem;
}

.ftps3-dialog .k-dialog .k-dialog-buttongroup .dialog-buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.ftps3-dialog .k-dialog .k-dialog-buttongroup .dialog-buttons-left-div {
    display: flex;
    flex-direction: row;
}

.ftps3-dialog .k-dialog .k-dialog-buttongroup .dialog-buttons-right-div {
    display: flex;
    flex-direction: row;
}