.userHeaderDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /*padding-left: 1em;*/
    /*padding-right: 1em;*/
    /*padding: 1em;*/
    /*margin-left: 1em;*/
    /*margin-right: 1em;*/
    /*border-radius: 5px;*/
    /*background-color: white;*/


    /*border-width: 1px;*/
    /*border-color: rgba(0, 0, 0, 0.08);*/
    /*border-style: solid;*/
    /*box-shadow: 0 3px 10px 0 rgb(99 118 129 / 30%);*/
}

.userDropdownDiv {
    /*flex-grow: 1;*/
    font-weight: 600;
    line-height: 1.2;
    color: #00055d;
    font-family: "poppins", sans-serif !important;


    display: flex;
    flex-direction: column;
    justify-content: center;
}

.userManagerHeaderDiv {
    /*flex-grow: 1;*/
    font-weight: 600;
    line-height: 1.2;
    color: #00055d;
    font-family: "poppins", sans-serif !important;
}

.newUserButtonDiv {
    /*flex-grow: 1;*/


    /*display: flex;*/
    /*flex-direction: column;*/
    /*justify-content: center;*/
}

.newUserButtonDiv .newUserButton {
    background-color: #00424d;
    width: 7rem;
    height: 2.5rem;
    color: white;
}

.spacingLine {
    margin: 0 0 1em;
    padding: 0px;
    border-width: 0 0 2px;
    border-color: rgba(224, 224, 224, 1);
    border-style: solid;
    width: 100%;
    font-size: 14px;
    text-transform: uppercase;
}

.userManagerText {
    text-align: center;
}

.permissions-user-name-container {
    display: flex;
    flex-direction: row;
}

.permissions-user-suspended-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 1rem;
    margin-bottom: 0.5rem;
}

.permissions-user-suspended-text {
    margin: 0;
    background-color: orange;
    color: white;
    padding: 0.3rem 0.5rem;
    border-radius: 0.5rem;
}



.editCreateUserSection,
.editCreateGroupSection,
.k-drawer-content .col-12 .tab-content .rolesPageEditSection {
    /*padding: 1em;*/
    margin-top: 1em;
    /*margin-left: 1em;*/
    /*margin-right: 1em;*/
    /*border-radius: 5px;*/
    /*background-color: white;*/


    /*border-width: 1px;*/
    /*border-color: rgba(0, 0, 0, 0.08);*/
    /*border-style: solid;*/
    /*box-shadow: 0 3px 10px 0 rgb(99 118 129 / 30%);*/
}

.tab-content {

}

.permissionsUserForm .ftpFormSection .suspend-user-button {
    width: 7.5rem;
}

.permissionsUserForm .ftpFormSection .reset-pw-button {
    width: 8rem;
}

.permissionsCheckboxesDiv {
    margin: 1rem;
}


.userManagerSecondaryText {
    font-size: 15px;
    line-height: .1em;
    margin-top: 2em;
}

.dropdownLabel {
    font-size: 20px;
}

.permissionsUserForm .thirdRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.card-body .row .editGroupDiv {
    width: 100%;
}

.editCreateUserSection .row .editUserDiv,
.editCreateUserSection .row .createNewUserDiv {
    width: 100%;
    padding-left: 1em;
    padding-right: 1em;
}

.editUserDiv .permissionsUserForm .button-red:hover {
    background-color: #c9302c;
    border-color: #c9302c;
}

/*.editUserDiv .permissionsUserForm .button-white {*/
/*    background-color: #FFFFFF;*/
/*    color: #00424D;*/
/*    border-width: 1px;*/
/*    border-style: solid;*/
/*    border-color: #00424D;*/
/*}*/

.editUserDiv .col-md-12 .permissionsUserFields,
.tab-content .editCreateUserSection .permissionsCreateNewUser {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.permUserFieldDiv,
.editCreateUserSection .permissionsCreateNewUser .permUserFieldDiv {
    width: 32%;
}

.permUserFieldDiv .form-group .permUserField,
.createNewUserDiv .permissionsCreateNewUser .col-6 .form-group .form-select,
.permissionsUserForm .table .form-select {
    background-color: whitesmoke;
}

.editCreateUserSection .wrapper .row .editUserDiv .wrapper {
    padding: 1em;
}

.editCreateUserSection .wrapper .row .editUserDiv .userName {
    color: black;
}

/*for the "create new user" form*/
.permissionsCreateNewUser {
    justify-content: space-between;
}

.newUserFormButtons {
    border: none;
    border-radius: 2px;
}

.saveUserButton {
    margin-left: 1rem;
}

.permissionsGroupActionButton {
    margin-left: 1rem;
}

.editCreateGroupSection .card-body {
    padding: 0rem;
}

.permissions-checkboxes-header-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.permissions-checkboxes-header-div .role-pre-populate-section {
    position: relative;
    top: -1rem;
}

.primary-header-text {
    color: #00424d;
    /*font-family: 'Josefin Sans', sans-serif;*/
    font-weight: 600;
}

.secondary-header-text {
    color: #00424d;
    /*font-family: 'Josefin Sans', sans-serif;*/
    font-weight: 600;
    font-size: 1.25rem;
}

.permissions-group-edit-table {
    padding-right: 1rem;
    padding-left: 1rem;
}

/*.button-standard {*/
/*    background-color: #00424d;*/
/*    width: 7rem;*/
/*    height: 2.5rem;*/
/*    color: white;*/
/*}*/

/*.button-standard:hover {*/
/*    background-color: #00282e;*/
/*}*/

/*.button-red {*/
/*    background-color: #d9534f;*/
/*    width: 7rem;*/
/*    height: 2.5rem;*/
/*    color: white;*/
/*}*/

/*.button-red:hover {*/
/*    color: #fff;*/
/*    background-color: #c9302c;*/
/*    border-color: #ac2925;*/
/*}*/

.admin-users-delete-dialog .k-dialog .k-dialog-content .admin-users-delete-dialog-message-text {
    margin-bottom: 0rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    color: black;
    font-family: Helvetica,Arial,"Roboto",sans-serif;
}

.section-card .k-content .editCreateUserSection .editUserDiv .profile-page-lower-section-label {
    font-family: var(--global-font-family);
    color: rgba(0, 0, 0, 0.54);
    line-height: 20px;
    padding-bottom: 0.2rem;
    font-size: 16px;
}

.section-card .k-tabstrip .editCreateGroupSection .permissions-group-edit-table .groups-page-lower-section-label {
    font-family: var(--global-font-family);
    color: rgba(0, 0, 0, 0.54);
    line-height: 20px;
    padding-bottom: 0.2rem;
    font-size: 16px;
}

.section-card .k-tabstrip .editCreateGroupSection .permissions-group-edit-table .user_name {
    font-family: var(--global-font-family);
    color: rgba(0, 0, 0, 0.54);
    line-height: 20px;
    padding-bottom: 0.2rem;
    font-size: 14px;
    font-style: normal;
    font-weight: 100;
}