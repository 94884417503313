.container {
    display:flex;
    width: 100%;
}
.keywords-sites-column-1 {
    flex-shrink: 0;
    flex-basis: 50%;
    /*padding-right: 2.5rem;*/
}
.keywords-sites-column-2 {
    flex-shrink: 0;
    flex-basis: 50%;
}

.keywords-sites-container {
    display:flex;
    flex-direction: row;
    width: 100%;
    font-family: Helvetica, Arial, 'Roboto', sans-serif;
}

.keywords-sites-upload-error {
    color: red;
}

@media (max-width: 1130px) {
    .keywords-sites-container {
        flex-direction: column;
    }

    .keywords-sites-outer-div .keywords-sites-2 .k-form .notes-field-wrapper {
        margin-left: 0rem;
        margin-top: 1rem;
        /*width: 38rem;*/
    }

    .keywords-sites-outer-div {
        width: initial;
    }
}

.notes-field-wrapper {
    margin-left: 2rem;
}

.labels {
    line-height: 1.25rem;
    color: rgba(0, 0, 0, 0.54);
    padding-bottom: .2rem;
    font-size: .9rem;
}
.labels-error {
    line-height: 1.25rem;
    color: red;
    padding-bottom: .2rem;
    font-size: .9rem;
}
.k-dropdownlist {
    /*width:10rem;*/
    border-radius: .25rem;
    height: 2.2rem;
}
.k-input.k-input-md.k-rounded-md.k-input-solid {
    border-style: solid;
    border-width: 1.5px;
    border-color: rgb(0 0 0 / 8%);
    background-color: whitesmoke;
    font-family: Helvetica, Arial, "Roboto", sans-serif;
    /*height: 2.2rem;*/
    border-radius: 0.25rem;
}
.k-input.k-textarea {
    border-style: solid;
    border-width: 1.5px;
    border-color: rgb(0 0 0 / 8%);
    background-color: whitesmoke;
}
.section-label {
    padding-top: 1rem;
    padding-left: 1rem;
    color: #476c75;
    font-size: 1.1rem;
    font-weight: bold;
}

.keywords-sites-outer-div .keywords-sites-card .keywords-sites-container {
    padding-left: 0rem;
    margin-left: 0rem;
}

.k-multiselect.k-input.fields {
    /*height: 2.2rem;*/
    min-height: 2.2rem;
    max-height: 10rem;
    overflow-y:scroll;
}

.optional-msg-label {
    height: 1.5rem;
    background-color: #FBFCFC;
    color: lightgrey;
    width: 50rem;
    justify-content: center;
}