.k-tick.k-tick-large.k-tick-horizontal {
    padding-top:1.3rem;
}
.start-home-label {
    width:6.2rem;
    margin-right:1rem;
    background-color:whitesmoke;
    text-align:center;
}
.end-home-label {
    width:6.2rem;
    margin-left:1.5rem;
    background-color:whitesmoke;
    text-align:center;
}
