.idx-pixel-dialog.dialog-standard .k-tabstrip-items-wrapper .k-item{
    color: var(--global-landing-page-color);
    font-size: 16px;
}

.idx-pixel-dialog.dialog-standard .k-content.k-active {
    display: flex;
}

.idx-pixel-dialog.dialog-standard .k-tabstrip-items .k-item {
    /*border: 1px solid rgba(0, 0, 0, 0.08);*/
}

.k-tabstrip-top>.k-tabstrip-items-wrapper .k-item:active, .k-tabstrip-top>.k-tabstrip-items-wrapper .k-item.k-active{
    margin-bottom: -1px;
    background-color: white;
    border-bottom-width: 1px;
    border-bottom-color: rgba(0,0,0,0) !important;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    border-left: 1px solid rgba(0, 0, 0, 0.08);
    border-right: 1px solid rgba(0, 0, 0, 0.08);
    font-weight: bold;
}

.idx-pixel-dialog.dialog-standard .script-block{
    /*border: 2px solid rgba(0, 0, 0, 0.30);*/
    /*padding: 10px;*/
    /*width: auto;*/
    /*overflow-x: auto;*/
    /*display: contents;*/
}
.idx-pixel-dialog.dialog-standard .script-wrapper{
    border: 2px solid rgba(0, 0, 0, 0.30);
    padding: 10px;
}
.idx-pixel-dialog.dialog-standard .google-tag-manager-img{
    border: 2px solid rgba(0, 0, 0, 0.30);
    padding: 10px;
}
.idx-pixel-dialog.dialog-standard .screenshot-block{
    border: 2px solid rgba(0, 0, 0, 0.30);
    padding: 10px;
}
.idx-pixel-dialog.dialog-standard .paragraph-standard{
    margin: 10px;
}
.idx-pixel-dialog.dialog-standard .paragraph-standard.tag-code{
    border: 2px solid rgba(0, 0, 0, 0.30);
    padding: 10px;
    width: max-content;
}
.idx-pixel-dialog.dialog-standard .paragraph-standard.tag-code pre{
    margin: 0;
}

.idx-pixel-dialog.dialog-standard .sub-title{
    font-weight: bold;
    margin-bottom: 5px;
    margin-top: 5px;
}
.idx-pixel-dialog.dialog-standard #category-copy-button1,
.idx-pixel-dialog.dialog-standard #category-copy-button2,
.idx-pixel-dialog.dialog-standard #category-copy-button3,
.idx-pixel-dialog.dialog-standard #category-copy-button4{
    /*float: right;*/
}
/*.idx-pixel-dialog .k-content.k-active{*/
/*    width: 55vw;*/
/*}*/
.idx-pixel-dialog .k-window-content.k-dialog-content{
    overflow: auto;
}
