.k-button.test-connection {
    color: #00424D;
    border-color: #00424D;
    background-color: #FFFFFF;
}
.s3-settings-controls {
    height: 2.5rem;
    font-size:18px;
}
.s3-settings-label-font {
    /*font-weight: bold;*/
    /*font-size: 1.1rem;*/
    line-height: 20px;
    font-size: 16px;
}
.k-button.delete-settings {
    color: red;
    text-decoration: underline;
    padding-left: 3rem;
}

.dialog-standard .k-dialog-content .test-con-button {
    width: 8rem;
    margin-left: 0rem;
}