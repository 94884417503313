/*********** Table Text Align  **********/
.right{
    text-align: right;
  }

  .k-pdf-export .table-responsive.business-org .table thead{
    background-color: #ffffff;
  }

  .k-pdf-export .table-responsive.business-org .table tbody tr:nth-child(odd){
    background-color:#F9F9F9;
  }

   /********START**************** Chart Size and Position For Different Window Sizes **************START************/
   .col-xl-6.col-lg-6.col-md-6 .card .card-body-businessgraphs.business-org{
    height: 55rem;
       margin-bottom: 50px;
   }



/*@media screen and (min-width: 3840px){*/
/*  .col-xl-6.col-lg-6.col-md-6 .card .card-body-businessgraphs{*/
/*       padding-left: 10px;*/
/*           padding-right: 1100px;*/
/*           padding-bottom: 20px;*/
/*           height:30rem;*/
/*     */
/*        }*/
/*    }*/
   
  /*  @media screen and (min-width: 2880px) and (max-width: 3839px){*/
  /*.col-xl-6.col-lg-6.col-md-6 .card .card-body-businessgraphs{*/
  /*     padding-left: 10px;*/
  /*         padding-right: 600px;*/
  /*         padding-bottom: 20px;*/
  /*         height:30rem;*/
  /*   */
  /*      }*/
  /*  }*/
   
 /*   @media screen and (min-width: 2560px) and (max-width: 2879px){*/
 /*.col-xl-6.col-lg-6.col-md-6 .card .card-body-businessgraphs{*/
 /*      padding-left: 10px;*/
 /*          padding-right: 400px;*/
 /*          padding-bottom: 20px;*/
 /*          height:30rem;*/
 /*    */
 /*       }*/
 /*   }*/
   
   /* @media screen and (min-width: 1921px) and (max-width: 2559px){*/
   /*.col-xl-6.col-lg-6.col-md-6 .card .card-body-businessgraphs{*/
   /*    padding-left: 10px;*/
   /*        padding-right: 200px;*/
   /*        padding-bottom: 20px;*/
   /*        height:30rem;*/
   /*  */
   /*     }*/
   /* }*/
   
  /*  @media screen and (min-width: 1299px) and (max-width: 1920px){*/
  /*.col-xl-6.col-lg-6.col-md-6 .card .card-body-businessgraphs{*/
  /*     padding-left: 30px;*/
  /*         padding-right: 50px;*/
  /*         padding-bottom: 20px;*/
  /*         height:40rem;*/
  /*   */
  /*      }*/
  /*      .col-xl-11.col-lg-11.col-md-11 .card .card-body-businessgraphs{*/
  /*        padding-left: 30px;*/
  /*            padding-right: 50px;*/
  /*            padding-bottom: 20px;*/
  /*            height:34rem;*/
  /*      */
  /*         }*/
  /*  }*/
   
  /*  @media screen and (max-width: 1300px){*/
  /*.col-xl-6.col-lg-6.col-md-6 .card .card-body-businessgraphs{*/
  /*     padding-left: 30px;*/
  /*         padding-right: 10px;*/
  /*         padding-bottom: 20px;*/
  /*         height:35rem;*/
  /*         width:40rem;*/
  /*      }*/
  /* */
  /*  }*/
   
    /******END****************** Chart Size and Position For Different Window Sizes **************END************/