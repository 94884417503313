.tree-table-wrapper .k-table td.k-table-td{
    text-wrap: wrap !important;
}
.tree-outter-wrapper{
    display: flex;
    flex-direction: row;
    width: 100%;
    font-family: Helvetica, Arial, "Roboto", sans-serif;
    /*border: 2px solid rgba(0, 0, 0, 0.18);*/

}
.tree-outter-wrapper .k-grid{
    border-color: rgba(0, 0, 0, 0.18);
    border-width: 1px;
}
.tree-outter-wrapper .k-grid tbody.k-table-tbody{
    cursor: pointer;
}
.tree-outter-wrapper .k-table thead .k-link{
    cursor: default;
}

.tree-outter-wrapper .selected-count{
    cursor: default;
    font-weight: bold;
}

.tree-outter-wrapper .k-grid-header{
    font-size: 16px;
}
.tree-outter-wrapper .k-grid tr.k-table-row.k-master-row.k-alt{
    background-color: rgba(0, 0, 0, 0.07);
}

.tree-outter-wrapper .left-tree-wrapper{
    /*border: 2px solid rgba(0, 0, 0, 0.18);*/
    margin-top: 20px;
    padding: 15px 30px 15px 15px;
}
.tree-outter-wrapper .right-tree-wrapper{
    /*border-left: 2px solid rgba(0, 0, 0, 0.18);*/
    padding: 15px 15px 15px 30px;
}


.tree-outter-wrapper .tree-tool-bar{
    flex-direction: row;
    display: flex;
}

/*.admin-tabstrip-container-card {*/
/*    !* max-height: 90vh; *!*/
/*    !* overflow-y: auto; *!*/
/*}*/
/*.section-card.admin-tabstrip-container-card.box-shadow-standard.section-card-outer-margins{*/
/*    display: flex;*/
/*}*/

/*.section-card.admin-tabstrip-container-card.box-shadow-standard.section-card-outer-margins .k-content.k-active.admin-tabstrip-user{*/
/*    overflow: unset;*/
/*}*/

.dialog-standard {
    overflow-y: auto;
}

@media (max-width: 1048px) {
    .body_background .ftps3-dialog {
        align-items: normal;
    }
}

@media (max-height: 675px) {
    .body_background .ftps3-dialog {
        justify-content: normal;
    }
}

/*.admin-permissions-below-manager-text {*/
/*    font-size: 15px;*/
/*    line-height: 1.3em;*/
/*    margin-top: 2em;*/
/*    color:#00424d;*/
/*    font-family: Helvetica, Arial, 'Roboto', sans-serif;*/
/*}*/

/*@media (max-width: 1215px) {*/
/*    .admin-permissions-below-manager-text {*/
/*        font-size: 1.1vw;*/
/*        !*line-height: 1em;*!*/
/*    }*/

/*    .userManagerHeaderDiv {*/
/*        margin-left: 1rem;*/
/*    }*/

/*}*/

/*@media (max-width: 1100px) {*/

/*}*/

/*.admin-tabstrip-container-card .k-animation-container {*/
/*    width: 100%;*/
/*}*/

/*.admin-tabstrip-container-card .k-tabstrip-items .k-link {*/
/*    font-size: 1.1rem;*/
/*    color: #476c75;*/
/*    font-family: Helvetica, Arial, 'Roboto', sans-serif;*/
/*}*/

/*.admin-tabstrip-container-card .k-tabstrip .k-tabstrip-items .k-state-active .k-link,*/
/*.admin-tabstrip-container-card .k-tabstrip .k-tabstrip-items .k-active .k-link {*/
/*    color: #00424d;*/
/*    font-weight: bold;*/
/*    font-family: Helvetica, Arial, 'Roboto', sans-serif;*/
/*}*/
/*.admin-tabstrip-container-card .k-item:nth-child(6),*/
/*.admin-tabstrip-container-card .k-item:nth-child(7),*/
/*.admin-tabstrip-container-card .k-item:nth-child(8),*/
/*.admin-tabstrip-container-card .k-item:nth-child(9),*/
/*.admin-tabstrip-container-card .k-item:nth-child(10),*/
/*.admin-tabstrip-container-card .k-item:nth-child(11)*/

/*{*/
/*    background-color: lightgoldenrodyellow !important;*/
/*}*/

.ftp-settings-controls-container .connection-settings {
    /*margin-left: 1rem;*/
    border-radius: 0.25rem;
    width: 100%;
    height: 2.2rem;
}

.ftp-settings-controls-container .existing-connections-dropdown-div {
    width: 100%;
    /*margin-right: 1rem;*/
}

.ftp-settings-controls-container .ftp-settings-fts-row {
    padding-top: 0.3rem;
    padding-bottom: 0.2rem;
}

.ftp-settings-fts-row .fts-label {
    font-size: 1.1rem;
    font-weight: bold;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.k-animation-container {
    width: 6rem;
}

.k-animation-container .k-popup {
    border: #00424d;
}

.section-card .k-content .ftp-settings-controls-container .ftp-settings-fts-row .storage-service-button {
    margin-left: 0.3rem;
    width: 8.5rem;
}

.section-card .k-content .ftp-settings-controls-container .ftp-settings-fts-row .storage-service-bg-button {
    width: 6rem;
    height: 2.5rem;

    background-color: #FFFFFF;
    color: #00424D;
    border: 1px solid #00424D;
}

.section-card .k-content .ftp-settings-controls-container .ftp-settings-fts-row .storage-service-bg-button-active {
    width: 6rem;
    height: 2.5rem;

    background-color: #00424D;
    color: #FFFFFF;

    border: 1px solid #00424D;
}

.transfer-settings-header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.dropdown-file-transfer {
    width: 8.5rem;
    position: relative;
    left: 5px;
    /*background-color: #00424d;*/
    /*color: #00424d;*/
}

.dropdown-new-fts {
    position: relative;
    left: 17px;
}

.section-card .ftp-settings-controls-container .ftp-settings-fts-row .new-ftp-button {
    margin-left: 1rem;
}

.storage-service-dropdown-button-div {
    display: flex;
    flex-direction: column;
}

.existing-connections-dropdown-div {
    display: flex;
    flex-direction: column;
}

.fts-tab-ec-label {
    /*margin-left: 1rem;*/
    margin-bottom: .3rem;
}

.fts-tab-ss-label {
    margin-left: 0.5rem;
    margin-bottom: .2rem;
}

.fts-controls {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    align-items: end;
}

.onboarding-content .ftp-s3-tab-outer-div .connections-container-div {
    margin: 0rem 0rem 0rem 0.5rem;
}

.onboarding-outer-div .onboarding-content .ftp-s3-tab-outer-div .manage-conn-button {
    width: 13rem;
}

.selection-type-wrapper{
    align-self: end;
}
.above-tree-components-wrapper{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}
.above-tree-components-wrapper .naics-code-wrapper{
    margin-right: 0;
    width: 30%;
}
.above-tree-components-wrapper .selection-type-wrapper{
    margin-left: 20px;
}
.above-tree-components-wrapper .legislative-district-wrapper{
    margin-right: 0;
    width: 30%;
}
.above-tree-components-wrapper .legislative-district-wrapper{
    margin-right: 0;
    width: 30%;
}
.above-tree-components-wrapper .locations-wrapper .form-group{
    padding-right: 15px;
}
.tree-table-wrapper{
    margin-top: 35px;
    border: 2px solid rgba(0, 0, 0, 0.18);
}
.cost-msg-div .k-label.cost-msg-label{
    /*margin-top: 10px;*/
    /*display: flex;*/
    /*float: left;*/
    display: inline-block;
}
.cost-msg-label {
    height: 1.5rem;
    /*background-color: #FBFCFC;*/
    color: grey;
    /*width: 50rem;*/
    justify-content: center;
}
.notification-div{
    width: fit-content;
    background-color: #FFFFCC;
    align-self: center;
    border: solid 1px rgba(0,0,0,.08);
    padding: 0 5px 0 5px;
}