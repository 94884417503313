.card .card-body-ActivateSegmentForm {
  position: relative;
  display: block;
  height: fit-content;
}
.card h2,
.card h1,
.card h3,
.card h4,
.card h6,
.card p {
  color: #00424d;
  font-family: Helvetica, Arial, "Roboto", sans-serif;
}

button.btn.light.btn-primary.btn-rounded {
  background-color: white !important;
  padding: 12px 26px;
  color: #00424D !important;
  border: 0.1rem solid #00424D;
  font-family: Helvetica, Arial, 'Roboto', sans-serif;
}

button.btn.light.btn-primary.btn-rounded:hover {
  background-color: #00424D !important;
  border-color: #00424D;
  color: white !important;
  padding: 12px 26px;
}

.mt-4.pb-3.instruction{
  color:#585858;
}


