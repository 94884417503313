.k-panelbar-item-text{
    color: rgb(66, 66, 66);
    font-size: 16px;
}

.faq-page-container .panelbar-wrapper p{
    color: rgb(66, 66, 66);
    font-size: 14px;
    margin-left: 18px;
    margin-top: 5px;
}
.faq-page-container .panelbar-wrapper .image-wrapper{
    margin:15px 15px 15px 15px;
    border-color: rgb(0, 0, 0, 0.08);
    border-style: solid;
    border-width: 1px;
}
.faq-page-container .panelbar-wrapper img{
    width: 100%;
    height: auto;
}

.faq-page-container .panelbar-wrapper .k-link.k-selected{
    background-color: rgb(0, 66, 77, 0.10);
}

.faq-page-container .panelbar-wrapper .k-panelbar-item-text{
    font-weight: bold;
}
.faq-page-container .panelbar-wrapper .accepted-file-format-list ul{
    display: grid;
    grid-template-rows: repeat(5, min-content);
    grid-auto-flow: column;
}
.faq-page-container .panelbar-wrapper .k-link:hover{
    cursor: pointer;
    background-color: rgb(0, 66, 77, 0.10);
}

.faq-page-container .k-tabstrip-items .k-active{
    border-bottom-width: 1px;
}