.admin-app-status .tab-content.mt-3{
    padding-left: 0;
}
.admin-app-status .input-daterange-datepicker{
    height: fit-content;
    border: solid 1px #6e6e6e !important;
    font-size: 14px;
}
.admin-app-status .input-group {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}
.admin-app-status .input-group .btn.btn-primary.updateBtn{
    margin-left: 8px !important;
    padding: 6px 10px 6px 10px !important;
}
.admin-app-status .btn.btn-primary.float-right{
    padding: 6px 10px 6px 10px !important;
}
.admin-app-status .form-control.form-control-custom{
    height: fit-content;
    width: fit-content;
    border: solid 1px #6e6e6e !important;
    font-size: 14px;
}
.admin-app-status .card-title{
   font-size: 16px;
    font-family: Helvetica, Arial, 'Roboto', sans-serif;
    color: #00424D;
    font-weight: bold;
}
.admin-app-status .btn-primary{
    font-size: 14px;
}
.admin-app-status th.sortable{
    font-size: 16px;
}
.admin-app-status #pageDropDown{
    font-size: 12px;
    border: solid 1px rgb(222, 226, 230);
    border-radius: 5px !important;
    padding: 6px 10px 6px 10px !important;
}
.admin-app-status .dropdown-menu{
    font-size: 12px;
    right: auto !important;
    left: 50px !important;
    bottom: -10px !important;
}
.admin-app-status .col-md-6.col-lg-6.card-min-height .card .react-bootstrap-table{
    overflow-x: auto;
    width: 100%;
}
.admin-app-status input{
    border-radius: 5px !important;
}
