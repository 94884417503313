.card-body-ActivateSegmentSummary {
  position: relative;
  height: fit-content;
  width: auto;
  flex: 1 1 auto;
  padding: 1rem 1rem;
}
.row.pdf-data.reports-data-content{
width:auto;
}
.col-xl-12.col-lg-12.col-md-12.col-sm-12 .card .text-center{
  border-color: #f0f1f5;
  position: relative;
  background: transparent;
  padding: 1.2rem 1rem 1rem 1rem;
  border-bottom: 1px solid rgba(0,0,0,.2);
}

.col-xl-12.col-lg-12.col-md-12.col-sm-12 .card .row{
  width:auto;
  }

.k-pdf-export .card-body-ActivateSegmentSummary .table thead{
  background-color: #ffffff;
}

.k-pdf-export .card-body-ActivateSegmentSummary .table tbody tr:nth-child(odd){
  background-color:#F9F9F9;
}

