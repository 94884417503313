#what-is-new-button {
    /*margin-top: 1rem;*/
    /*margin-right:4rem;*/
    height: 2.8rem;
    font-size: 1em;
    color:white;
}
#modal-overall{
    font-size: 112%;
}
.icon-bullhorn:before {
    content: "\e91a";
}

.modal-content {
    width: 110%;
}

#circle {
    background: lightblue;
    border-radius: 50%;
    width: 1em;
    height: 1em;
}

.notification-icon {
    animation: notification-fadeinout 5s infinite;
}

@keyframes notification-fadeinout {
    50% {opacity: .2;}
}