#men.k-selected{
    background-color: #476c75;
    color: #FFFFFF;
    border-color: #476c75;
}
#women.k-selected {
    background-color: #476c75;
    color: #FFFFFF;
    border-color: #476c75;
}

.onboarding-content .k-card-body .targeting-full-container .targeting-full-row .column .targeting-full-onboarding-button-group {
    border-radius: 0.25rem;
    height: 2.2rem;
}