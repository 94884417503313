.resources-page-container {
    /*display: flex;*/
    align-items: start;
    align-content: center;
    flex-direction: column;
    justify-content: center;
    min-width: 860px;
}

.resources-page-container .video-1,
.resources-page-container .video-2 {
    /*display: flex;*/
    overflow: auto;
    padding: 10px 10px 38px 10px;
}
.resources-page-container iframe{
    margin: auto;
}

.resources-page-container .wistia_embed {
    display: flex;
    align-self: center;
    width: 1100px;
    height: 580px;
}

.resources-page-container .content-wrapper .section-header {
    color: #476c75;
    margin: 20px;
    /*border-color: rgba(0, 0, 0, 0.08);*/
    /*border-width: 0.18rem;*/
    /*border-style: solid;*/
}

.resources-page-container .content-wrapper {
    font-size: 18px;
    font-family: Helvetica, Arial, "Roboto", sans-serif;
    font-weight: bold;
    border-radius: 0.5rem;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.08);
    border-width: 1px;
    box-shadow: 0 3px 10px 0 rgb(99 118 129 / 30%);
    background-color: white;
    margin: 7px;
}

.resources-page-container .content-wrapper legend{
    margin-left: 10px;
    margin-right: 10px;
    width: 98%;
}
.resources-page-container .k-card.k-card-vertical:hover{
    transition: all 0.5s ease;
    transform: scale(1.03) perspective(1px);
}

.resources-page-container a{
    text-decoration: none;
}
