.k-button.annual-revenue-button {
    width: 8rem;
    background-color: #FFFFFF;
    color: #00424D;
    border: 1px solid #00424D;
}
.k-button.annual-revenue-button:hover {
    background-color: #FFFFFF;
}
.k-button.k-selected.selected-range {
    background-color: #476c75;
    color: #FFFFFF;
    border: 1px solid #FFFFFF;
}
.k-button.unselected-range {
    width: 8rem;
    background-color: #FFFFFF;
    color: #00424D;
    border: 1px solid #00424D;
}
.annual-revenue-container {
    display:flex;
    flex-flow: row wrap;
}
.k-button-group.annual-revenue-button-group {
    padding-top: .1rem;
}
.annual-revenue-label {
    font-size:10px;
}