
/*********** Table Text Align  **********/
.right{
    text-align: right;
  }

  .k-pdf-export .table-responsive.business-revenue .table thead{
    background-color: #ffffff;
  }

  .k-pdf-export .table-responsive.business-revenue .table tbody tr:nth-child(odd){
    background-color:#F9F9F9;
  }

  .row.revenue-businessreport{
    margin-bottom: 50px;
  }