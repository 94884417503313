.k-window-title.k-dialog-title{
    text-wrap: balance;
    word-break: break-word;
}
.k-dropdownlist.k-picker.day-dropdown{
    width: 60px;
    height: 20px;
}

.dialog-standard.synchronize-activation{
    /*max-width: 400px;*/
}