/*---------------- Fonts ----------------*/
.activate-media-content,
.activate-media-content h4,
.activate-media-content h2 {
  font-family:Helvetica, Arial, "Roboto", sans-serif !important;
}
.activate-media-content h2,
.activate-media-content h4 {
  color: #00424d;
}

/*----------- Style All Tiles -----------*/
.activate-media-content .tile-container .activate-media-tile {
  border: 1px solid #f4f4f4;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  box-shadow: 0 3px 10px 0 rgb(99 118 129 / 30%);
  margin-bottom: 0;

}

.activate-media-content .tile-container button {
  background-color: white;
  border: transparent;
  padding: 0;
} 

.activate-media-content .tile-container{
  width:fit-content;
}

.activate-media-content .company-list {
  /*display: grid;*/
  /*grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));*/
  /*grid-auto-flow:row;*/
  /*grid-gap: 2rem;*/
  /*width:auto;*/
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: space-around;
}
.activate-media-content .company-list .tile-container{
  padding: 9px 12px 9px 12px;
}

.activate-media-content .company-list legend{
  padding-top: 7px;
  padding-bottom: 7px;
  font-size: medium;
  text-transform: none;
}

.plan-semcast {
  text-align: center;
    padding: 20px 20px 20px 20px;
    width: 220px;
    /* height: 220px; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
}

.plan-semcast i {
  font-size: 50px;
  color: #00055d;
  margin: 20px 0px;
  display: block;
}
.plan-semcast img {
  height: 36px;
  max-width: 100%;
}
.all-in-one-tiles img{
  height: 90px;
  padding-left: 10px;
  padding-right: 10px;
  max-width: 100%;
}
.plan-semcast h5 {
  margin-top: 15px;
}
.plan-semcast p {
  font-size: 16px;
  color: #00055d;
  margin-bottom: 0px;
}

.is-error{
  border-color:#d9534f !important;
}

/*----------- Uploaded Records Section -----------*/
.uploaded-records {
  /*display: flex;*/
  /*flex-wrap: wrap;*/
  /*align-items: center;*/
  /*position: fixed;*/
  /*margin-top: 2vw;*/
  /*margin-left: 1vw;*/
  /*width: 15vw;*/

  
}

.uploaded-records .basic-list-group {
  display: flex;

}

.uploaded-records .basic-list-group .list-group{
  display: flex;
  flex-wrap: wrap;
}

.list-group-item.d-flex.justify-content-between.align-items-center{
  display: flex;
  word-break: break-word;
}

.badge-primary.badge-pill{
  display: flex;
  flex-wrap: wrap;
  word-break:keep-all;
}

/* .row.nav-pills.samcast-menu .col-md-3.col-lg-3 .card{
  min-width: fit-content;
} */

/*----------- Status Icon On Top Right Of Each Tile -----------*/
.status-icon{
    float: right;
    font-size: 27px !important;
    position: absolute;
    right: -10px;
    top: -14px;
  }
  .status-icon-error{
    color: #d9534f !important;
  }
  .status-icon-progress{
    color: #5bc0de !important;
  }
  .status-icon-success{
    color: #5cb85c !important;
  }
  .status-label{
    color: #fff;
    padding: 5px;
  }
  .status-label-error{
    background-color: #d9534f;
  }
  .status-label-progress {
    background-color: #5bc0de;
  }
  .status-label-success {
    background-color: #5cb85c;
  }


  .activate-media-content .card{
    height: fit-content;
    margin-top:0 !important;
  }
 /* .col-md-3 .col-lg-3 .card-body .card .mt-3 .mb-3{
    height:fit-content;
  } */

  .k-drawer-content .activate-media-content .container-fluid{
    padding-top:0;
    padding-left:0;
  }

  .tab-content .mt-3 .main-container{
    margin-top:0;
  }

  .k-drawer-content .activate-media-content{
    margin-left:0;
  }

  .activate-media-content .card.p-3{
    margin-top:0;
  }

  .tab-content{
    padding-left:1rem;
  }

.row .tab-content .card-body{
  height: fit-content;
}
  