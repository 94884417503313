.landing-page-login-card {
    /*margin: auto;*/
    width: 25rem;
    align-self: center;
    padding: 0.5rem;
    /*box-shadow: 0 3px 10px 0 rgba(99, 118, 129, 0.60);*/

    box-shadow: 0 3px 10px 0 rgb(22 22 22 / 90%);
    /*border: 0.2rem solid #476c75;*/

    position: relative;
    /*left: 2rem;*/
    top: 2rem;
    /*margin-right: 2rem;*/
}

.landing-page {
    /*background-image: url("../assets/img/landingPageDesk.jpg");*/

    /*object-fit: cover;*/
    background-size: cover;
    min-height: 100vh;
    min-width: 100%;
    position: fixed;
    background-position: center;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.landing-page-logo-wrapper {
    display: flex;
    justify-content: center;
    /*background-color: #10191e;*/
    /*background: rgb(49,66,75);*/
    /*background: radial-gradient(circle, rgba(49,66,75,1) 0%, rgba(16,25,30,1) 100%);*/
    /*background: radial-gradient(circle, rgba(39,56,64,1) 0%, rgba(16,25,30,1) 100%);*/
    border-radius: 0.25rem;

    /*background: radial-gradient(circle, rgba(39,56,64,1) 0%, rgba(16,25,30,1) 80%);*/
    /*background: radial-gradient(circle, rgb(109 186 221) 0%, rgba(16,25,30,1) 80%);*/
    background-color: var(--global-landing-page-color);



}

/*.landing-page-logo {*/
/*    height: 4.3rem;*/
/*    !*height: 6rem;*!*/
/*    !*background-color: #10191e;*!*/
/*    border-radius: 0.5rem;*/
/*    padding: 0.5rem;*/
/*}*/

.landing-page-login-container {
    /*padding: 0rem 1rem 1rem 1rem;*/


    /*padding: 0.75rem;*/

    padding: 1rem;
    /*border: .1rem solid #476c75;*/
    /*border-radius: 0.5rem;*/
}

.landing-page-button,
.login-form .login-button-container .landing-page-button,
.reset-password-form .login-button-container .landing-page-button {
    width: 100%;
    margin: 0rem;
}

.login-button-container {
    padding: 0px;
    width: 100%;
    align-self: flex-end;
    margin-top: 1rem;
}

.login-form {
    margin-top: 1rem;
}

.landing-page-footer-wrapper {
    background: rgb(2,0,36);
    background: linear-gradient(0deg, rgba(2,0,36,1) 0%, rgba(0,66,77,1) 0%, rgba(71,108,117,1) 100%);
    background: var(--global-footer-color);
    box-shadow: 0 6px 20px 0 rgba(99, 118, 129, 0.5);
    padding: 0.5rem;


    position: fixed;
    width: 100%;
    bottom: 0;
}

.landing-page-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.login-fields-container {
    display: flex;
    flex-direction: column;
    justify-content: start;
}

.section-card .login-form .login-fields-container .k-floating-label-container .text-field {
    border-style: solid;
    border-width: 0.1rem;
    border-color: rgb(0 0 0 / 8%);
}

.section-card .login-form .login-fields-container .k-floating-label-container .text-field:focus {
    outline: none;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 0px 2px;
}

.password-field-wrapper {
    display: flex;
    flex-direction: row;
    margin-top: 0.5rem;
}

.login-password-field,
.login-fields-container .password-field-wrapper .login-password-field {
    width: 348px;
}

.toggle-view-password,
.login-fields-container .password-field-wrapper .toggle-view-password {
    /*height: 47px;*/
    height: 2.2rem;
    position: relative;
    top: 20px;
    left: -35px;
    border-radius: 0.25rem;
    /*border-style: solid;*/
    /*border-width: 1.5px;*/
    /*border-color: rgb(0 0 0 / 8%);*/
    background-color: whitesmoke;
    box-shadow: none;
}

.captcha-container {
    text-align: center;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.forgot-password-link {
    display: inline;
    width: fit-content;
    padding-top: 5px;
    text-decoration: none;
}

.footer-link {
    margin-left: 1rem;
    margin-right: 1rem;
    color: lightgrey;
    text-decoration: none;
}

.footer-link:hover {
    color: gray;
    text-decoration: none;
}

/*.left {*/
/*    margin-right: 1rem;*/
/*}*/

.footer-links-left-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.footer-links-right {

}

/* Hide image links when window gets too small to display them on one line */
@media screen and (max-width: 1025px) {
    .footer-links-right {
        display: none;
    }
    .footer-links-left-wrapper {
        padding-top: 10.5px;
        padding-bottom: 10.5px;
    }
}

@media screen and (max-width: 585px) {
    .footer-links-left {
        font-size: small;
    }
    .footer-links-left-wrapper {
        padding-top: 12.75px;
        padding-bottom: 12.75px;
    }
}

