.locations-container {
    display:flex;
    /*width: 97%;*/
}

.locations-column-1 {
    flex-shrink: 0;
    flex-basis: 99%;
}
.locations-column-2 {
    flex-shrink: 0;
    flex-basis: 5%;
}

@media only screen and (max-width: 900px) {
    .container {
        flex-direction: column;
    }

    .box {
        margin: 0 0 1rem;
    }
}
.spacingLine {
    margin: 0 0 1em;
    padding: 0px;
    border-width: 0 0 2px;
    border-color: rgba(224, 224, 224, 1);
    border-style: solid;
    width: 100%;
    font-size: 14px;
    text-transform: uppercase;
}

.labels {
    line-height: 20px;
    color: rgba(0, 0, 0, 0.54);
    padding-bottom: .2rem;
    font-size: 16px;
}
.nameFieldsDiv {
    display: flex;
    flex-direction: row;
    flex-basis: 100%;
    justify-content: space-between;

    /*flex: 1 1 0;*/
}

.section-label {
    padding-top: 1rem;
    padding-left: 1rem;
    color: #476c75;
    font-size: 1.1rem;
    font-weight: bold;
}

.file-upload {
    display: inline-block;
    background-color: whitesmoke;
    padding: 0.5rem;
    font-family: sans-serif;
    border-radius: 0.3rem;
    cursor: pointer;
}

/*.k-multiselect.k-input.fields {*/
/*    !*height: 2.2rem;*!*/
/*    min-height: 2.2rem;*/
/*    max-height: 10rem;*/
/*    overflow-y:scroll;*/
/*}*/
.k-multiselect.k-input.fields.k-input-md.k-rounded-md.k-input-solid.k-valid{
    max-height: 100px;
    max-width: 500px;
}
.controls-layout {
    margin-top:-.4rem
}

.error-message {
    color: red;
}
.form-group .location-wrapper{
    display: flex;
    flex-direction: row;
}
.label-wrapper{
    display: flex;
    flex-direction: column;
}


