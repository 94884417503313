/**
* Template Name: Vesperr - v4.3.0
* Template URL: https://bootstrapmade.com/vesperr-free-bootstrap-template/
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
  font-family: Helvetica, Arial, 'Roboto', sans-serif !important;
  color: #444444;
}

a {
  color: #3498db;
  text-decoration: none;
}

a:hover {
  color: #5faee3;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Raleway", sans-serif;
}


/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: #3498db;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
}

.back-to-top i {
  font-size: 28px;
  color: #fff;
  line-height: 0;
}

.back-to-top:hover {
  background: #57aae1;
  color: #fff;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Disable AOS delay on mobile
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0s !important;
  }
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  z-index: 997;
  transition: all 0.5s !important;
  /*padding: 22px 0px !important;*/
  background: #fff;
  -webkit-transition: all 0.5s !important;
  -moz-transition: all 0.5s !important;
  -ms-transition: all 0.5s !important;
  -o-transition: all 0.5s !important;
  height: 60px;
  z-index: 100;
}

#header.header-scrolled {
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  padding: 12px 0 !important;
}

#header .logo h1 {
  font-size: 30px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 1px;
}

#header .logo h1 a,
#header .logo h1 a:hover {
  color: #222222;
  text-decoration: none;
}

#header .logo img {
  padding: 0;
  margin: 0;
  max-height: 70px;
}

#root #header .container-fluid {
  padding-top: 0rem;
}

.headerLandingPage {
  box-shadow: 0 1px 6px 0 rgb(32 33 36 / 28%);
  /*box-shadow: 0px 0.5px 3px #e5e5e5;*/
}

/*--------------------------------------------------------------
# Notifications
--------------------------------------------------------------*/

.k-notification-group .k-notification-container .k-notification {
  z-index: 20000;
}

.Toastify__toast-container .Toastify__toast .Toastify__toast-body {
  font-size: 0.9em;
}

.Toastify .Toastify__toast-container .Toastify__toast {
  min-height: auto;
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation
*/
.navbar {
  padding: 0 !important;
}

.navbar ul {
  margin: 0;
  padding: 0;
  /* display: flex; */
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
  padding: 0px 2px !important;
  list-style: none;
  margin-bottom: 0px;
  margin-top: 0px !important;
}
.navbar li:last-child {
  padding-right: 0px;
}

.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: initial;
  padding: 10px 0 10px 0px;
  font-size: 15px;
  font-weight: 400;
  color: #555555;
  white-space: nowrap;
  transition: 0.3s;
  float: right;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover > a {
  color: #3498db;
}

.navbar .getstarted {
  background: #3498db;
  padding: 8px 25px;
  margin-left: 30px;
  border-radius: 50px;
  color: #fff;
}

.navbar .getstarted:hover {
  color: #fff;
  background: #4aa3df;
}

/* .navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 30px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
  border-radius: 4px;
} */

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  font-size: 14px;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover > a {
  color: #3498db;
}

.navbar .dropdown:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }
  .navbar .dropdown .dropdown:hover > ul {
    left: -100%;
  }
}

.navbar .loginDropdownButton {
  background-color: #00424D;
  color: white;
}

.navbar .loginDropdownButton:hover {
  background-color: #1d7b8d
}

#navbar {
  margin: 0em;
}

.navbar .navbarListItem .nav-link {
  font-size: 1em;
  position: relative;
  top: 1px;
  /*color: slategray;*/
}

.navBarLink,
.navBarLink .k-button-text {
  /*font-family: 'Josefin Sans', sans-serif;*/
  font-weight: 300;

  /*font-family: 'Open Sans', sans-serif;*/
  /*font-family: "Roboto", sans-serif;*/

  font-family: "Google Sans","Roboto",Arial,sans-serif;
  letter-spacing: .1px;
  /*font-family: "Google Sans", sans-serif;*/
}

#main-wrapper .nav-header {
  z-index: 101;
}

#navbar .navbarListItem .headerContactUs {
  font-size: 15px;
}

/**
* Mobile Navigation
*/
.mobile-nav-toggle {
  color: #222222;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

.mobile-nav-toggle.bi-x {
  color: #fff;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }
  .navbar ul {
    display: block;
  }
}

.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(9, 9, 9, 0.9);
  transition: 0.3s;
  z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  border-radius: 8px;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}

.navbar-mobile a {
  padding: 10px 20px;
  font-size: 15px;
  color: #222222;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover > a {
  color: #3498db;
}

.navbar-mobile .getstarted {
  margin: 15px;
}

.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover > a {
  color: #3498db;
}

.navbar-mobile .dropdown > .dropdown-active {
  display: block;
}

h2 {
  font-family: Nunito;
  font-weight: 600;
  font-size: 30px;
  line-height: 40px;
  color: #3b3663;
}

h6 {
  font-family: Nunito;
  font-weight: 600;
  font-size: 14px;
  line-height: 10px;
  color: #3b3663;
}
p {
  font-family: Helvetica, Arial, "Roboto", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  /*font-family: Nunito !important;*/
  /*font-family: 'Josefin Sans', sans-serif !important;*/
  color: #696687;
}

/*----------------------------------------------------------------------
# Recaptcha checkbox styling, to scale along with sign in modal window
-----------------------------------------------------------------------*/
 /*(1440x900) WXGA+ Display*/
/*@media screen and (max-width: 1640px) {*/
/*  .g-recaptcha {*/
/*    transform:scale(.8);*/
/*    -webkit-transform:scale(.8);*/
/*    -o-transform: scale(.8);*/
/*    -moz-transform: scale(.8);*/
/*    -ms-transform: scale(.8);*/
/*  }*/
/*}*/

/*!* (1440x900) WXGA+ Display *!*/
/*@media screen and (max-width: 1440px) {*/
/*  .g-recaptcha {*/
/*    transform:scale(.7);*/
/*    -webkit-transform:scale(.7);*/
/*    -o-transform: scale(.7);*/
/*    -moz-transform: scale(.7);*/
/*    -ms-transform: scale(.7);*/
/*  }*/
/*}*/

.grecaptcha-badge {
  visibility: hidden;
}

.g-recaptcha{
  display: inline-block;
}

/*.captcha-container {*/
/*  text-align: center;*/
/*  !*padding-top: .5em;*!*/
/*  !*padding-bottom: .5em;*!*/
/*  margin-top: auto;*/
/*}*/

/*--------------------------------------------------------------
# Homepage Content
--------------------------------------------------------------*/

.homepageTextContainer .homepageText {
  font-family: 'Josefin Sans', sans-serif;
}

.homepageTextContainer .homepageTextSecondary {
  font-family: "Open Sans";
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  height: 795px;
  margin-top: 115px;
}

#hero h1 {
  margin: 0 0 10px 0;
  font-size: 64px;
  font-weight: 700;
  line-height: 73px;
  background: -webkit-linear-gradient(#269eb7, #01e8fd);
}
#hero h1 {
  --textgradient_color1: #269eb7;
  --textgradient_color2: #01e8fd;

  background: -webkit-linear-gradient(#269eb7, #01e8fd);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#hero h2 {
  color: #484848;
  margin-bottom: 20px;
  font-size: 24px;
}

.btn-get-started {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 25px !important;
  border-radius: 10px;
  transition: all 0.5s ease-out;
  color: #fff !important;

  /* border: 2px solid #3498db;*/

  background-image: linear-gradient(96deg, #259eb7 0%, #02e6fb 100%);
}

.btn-get-started:hover {
  background-image: linear-gradient(96deg, #02e6fb 0%, #259eb7 100%);
  color: #fff;
}

/* button css */

#hero .animated {
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

@media (min-width: 1024px) {
  #hero {
    background-attachment: fixed;
  }
}

@media (max-width: 991px) {
  #hero {
    height: 100vh;
  }
  #hero .animated {
    -webkit-animation: none;
    animation: none;
  }
  #hero .hero-img {
    text-align: center;
  }
  #hero .hero-img img {
    width: 50%;
  }
}

@media (max-width: 768px) {
  /* #hero {
    margin-top: 20px;
  }*/
  #hero h1 {
    font-size: 28px;
    line-height: 36px;
  }
  #hero h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }
  #hero .hero-img img {
    width: 70%;
  }
}

@media (max-width: 575px) {
  #hero .hero-img img {
    width: 80%;
  }
}

@-webkit-keyframes up-down {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}

@keyframes up-down {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 60px 0;
  overflow: hidden;
}

.section-bg {
  background-color: #f9fafb;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.section-title h2 {
  font-size: 28px;

  position: relative;
  color: #222222;
}

.section-title h2::before,
.section-title h2::after {
  /*content: '';
  width: 50px;
  height: 2px;
  background: #3498db;
  display: inline-block;*/
}

.section-title h2::before {
  margin: 0 15px 10px 0;
}

.section-title h2::after {
  margin: 0 0 10px 15px;
}

.section-title p {
  margin: 15px 0 0 0;
}

/*--------------------------------------------------------------
# Clients
--------------------------------------------------------------*/
.clients {
  background: #f3f9fd;
  padding: 10px 0;
  text-align: center;
}

.clients .col-lg-2 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.clients img {
  width: 50%;
  filter: grayscale(100);
  transition: all 0.4s ease-in-out;
  display: inline-block;
  padding: 10px 0;
}

.clients img:hover {
  filter: none;
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .clients img {
    width: 40%;
  }
}

@media (max-width: 575px) {
  .clients img {
    width: 30%;
  }
}

/*--------------------------------------------------------------
# About Us
--------------------------------------------------------------*/
.about .content h3 {
  font-weight: 600;
  font-size: 26px;
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li {
  padding-left: 28px;
  position: relative;
}

.about .content ul li + li {
  margin-top: 10px;
}

.about .content ul i {
  position: absolute;
  left: 0;
  top: 2px;
  font-size: 20px;
  color: #3498db;
  line-height: 1;
}

.about .content p:last-child {
  margin-bottom: 0;
}

.about .content .btn-learn-more {
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 32px;
  border-radius: 50px;
  transition: 0.3s;
  line-height: 1;
  color: #3498db;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  margin-top: 6px;
  border: 2px solid #3498db;
}

.about .content .btn-learn-more:hover {
  background: #3498db;
  color: #fff;
  text-decoration: none;
}

/*--------------------------------------------------------------
# Counts
--------------------------------------------------------------*/
.counts {
  padding-top: 0;
}

.counts .content {
  padding: 0;
}

.counts .content h3 {
  font-weight: 700;
  font-size: 34px;
  color: #222222;
}

.counts .content p {
  margin-bottom: 0;
}

.counts .content .count-box {
  padding: 20px 0;
  width: 100%;
}

.counts .content .count-box i {
  display: block;
  font-size: 36px;
  color: #3498db;
  float: left;
  line-height: 0;
}

.counts .content .count-box span {
  font-size: 36px;
  line-height: 30px;
  display: block;
  font-weight: 700;
  color: #222222;
  margin-left: 50px;
}

.counts .content .count-box p {
  padding: 15px 0 0 0;
  margin: 0 0 0 50px;
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  color: #484848;
}

.counts .content .count-box a {
  font-weight: 600;
  display: block;
  margin-top: 20px;
  color: #484848;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  transition: ease-in-out 0.3s;
}

.counts .content .count-box a:hover {
  color: #6f6f6f;
}

@media (max-width: 1024px) {
  .counts .image {
    text-align: center;
  }
  .counts .image img {
    max-width: 70%;
  }
}

@media (max-width: 667px) {
  .counts .image img {
    max-width: 100%;
  }
}

/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services .icon-box {
  padding: 30px;
  position: relative;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 0 29px 0 rgba(68, 88, 144, 0.12);
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
  z-index: 1;

  width: 100%;
  margin: 0px 0px 30px 0px;
  padding: 36px 30px 36px 38px;
  background-color: #ffffff;
  border-style: solid;
  border-width: 0px 0px 0px 0px;
  border-radius: 10px 10px 10px 10px;
  box-shadow: 13px 13px 51px 0px rgb(10 51 80 / 16%);
}

.abot {
  margin-top: 120px;
}

.services .icon-box::before {
  content: "";
  position: absolute;
  background: #fff;
  right: -60px;
  top: -40px;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  transition: all 0.3s;
  z-index: -1;
}

.services .icon-box:hover::before {
  background: #00a9c5;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 0px;
}

.services .icon {
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  margin-bottom: 15px;
  transition: all 0.3s ease-in-out;
}

.services .icon i {
  font-size: 36px;
  line-height: 1;
  color: #fff;
}

.services .title {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 22px;
}

.services .title a {
  color: #111;
}

.services .description {
  font-size: 15px;
  line-height: 28px;
  margin-bottom: 0;
}

.services .icon-box:hover .title a,
.services .icon-box:hover .description {
  color: #fff;
}

.services .icon-box:hover .icon {
  background: #fff;
}

.services .icon-box:hover .icon i {
  color: #3498db;
}

/*--------------------------------------------------------------
# More Services
--------------------------------------------------------------*/
.more-services {
  padding-top: 20px;
}

.more-services .card {
  border: 0;
  padding: 160px 20px 20px 20px;
  position: relative;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.more-services .card-body {
  z-index: 10;
  background: rgba(255, 255, 255, 0.9);
  padding: 15px 30px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  transition: 0.3s;
  transition: ease-in-out 0.4s;
  border-radius: 5px;
}

.more-services .card-title {
  font-weight: 700;
  text-align: center;
  margin-bottom: 15px;
}

.more-services .card-title a {
  color: #222222;
}

.more-services .card-text {
  color: #5e5e5e;
}

.more-services .read-more a {
  color: #777777;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  transition: 0.4s;
}

.more-services .read-more a:hover {
  text-decoration: underline;
}

.more-services .card:hover .card-body {
  background: #3498db;
}

.more-services .card:hover .read-more a,
.more-services .card:hover .card-title,
.more-services .card:hover .card-title a,
.more-services .card:hover .card-text {
  color: #fff;
}

/*--------------------------------------------------------------
# Features
--------------------------------------------------------------*/
.features .icon-box {
  display: flex;
  align-items: center;
  padding: 20px;
  background: #f6f6f6;
  transition: ease-in-out 0.3s;
}

.features .icon-box i {
  font-size: 32px;
  padding-right: 10px;
  line-height: 1;
}

.features .icon-box h3 {
  font-weight: 700;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-size: 16px;
}

.features .icon-box h3 a {
  color: #222222;
  transition: ease-in-out 0.3s;
}

.features .icon-box h3 a:hover {
  color: #3498db;
}

.features .icon-box:hover {
  background: #eef7fc;
}

/*--------------------------------------------------------------
# Testimonials
--------------------------------------------------------------*/
.testimonials .testimonial-wrap {
  padding-left: 50px;
  overflow: visible;
}

.testimonials .testimonial-item {
  box-sizing: content-box;
  padding: 30px 30px 30px 60px;
  margin: 30px 15px;
  min-height: 200px;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
  position: relative;
  background: #fff;

  box-shadow: 13px 13px 51px 0px rgb(10 51 80 / 16%);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 0px 0px 0px;
  padding: 35px 0px 0px 0px;
  border-radius: 30px;
}

.testimonials .testimonial-item .testimonial-img {
  width: 90px;
  border-radius: 60px;
  border: 6px solid #fff;
  float: left;
  /*  position: absolute;
  left: -45px;*/
}

.testimonials .testimonial-item:after {
  position: absolute;
  left: 0;
  bottom: -26px;
  content: "";
  width: 0;
  height: 0;
  border-color: currentcolor rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
  border-style: solid;
  border-width: 26px 45px 0 0;
  color: #fff;
}

.testimonials .bg-img {
  position: relative;
  top: -100px;
  right: -121px;
  z-index: -11;
}

.testimonials .testimonial-item h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0 5px 0;
  color: #111;
  margin-top: 37px;
}

.testimonials .testimonial-item h4 {
  font-size: 14px;
  color: #999;
  margin: 0;
}

.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
  color: #e1f0fa;
  font-size: 26px;
}

.testimonials .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}

.testimonials .testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
}

.testimonials .testimonial-item p {
  font-style: italic;
  margin: 15px auto 15px auto;
}

.testimonials .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #3498db;
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #3498db;
}

@media (max-width: 767px) {
  .testimonials .testimonial-wrap {
    padding-left: 0;
  }
  .testimonials .testimonial-item {
    padding: 30px;
    margin: 15px;
  }
  .testimonials .testimonial-item .testimonial-img {
    position: static;
    left: auto;
  }
}

/*--------------------------------------------------------------
# Portfolio
--------------------------------------------------------------*/
.portfolio .portfolio-item {
  margin-bottom: 30px;
}

.portfolio #portfolio-flters {
  padding: 0;
  margin: 0 auto 20px auto;
  list-style: none;
  text-align: center;
}

.portfolio #portfolio-flters li {
  cursor: pointer;
  display: inline-block;
  padding: 8px 15px 10px 15px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  color: #222222;
  margin-bottom: 5px;
  transition: all 0.3s ease-in-out;
  border-radius: 3px;
}

.portfolio #portfolio-flters li:hover,
.portfolio #portfolio-flters li.filter-active {
  color: #fff;
  background: #3498db;
}

.portfolio #portfolio-flters li:last-child {
  margin-right: 0;
}

.portfolio .portfolio-wrap {
  transition: 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  background: rgba(34, 34, 34, 0.6);
}

.portfolio .portfolio-wrap::before {
  content: "";
  background: rgba(34, 34, 34, 0.6);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: all ease-in-out 0.3s;
  z-index: 2;
  opacity: 0;
}

.portfolio .portfolio-wrap img {
  transition: all ease-in-out 0.3s;
}

.portfolio .portfolio-wrap .portfolio-info {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  transition: all ease-in-out 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 20px;
}

.portfolio .portfolio-wrap .portfolio-info h4 {
  font-size: 20px;
  color: #fff;
  font-weight: 600;
}

.portfolio .portfolio-wrap .portfolio-info p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
  font-style: italic;
}

.portfolio .portfolio-wrap .portfolio-links {
  text-align: center;
  z-index: 4;
}

.portfolio .portfolio-wrap .portfolio-links a {
  color: #fff;
  margin: 0 5px 0 0;
  font-size: 28px;
  display: inline-block;
  transition: 0.3s;
}

.portfolio .portfolio-wrap .portfolio-links a:hover {
  color: #3498db;
}

.portfolio .portfolio-wrap:hover::before {
  opacity: 1;
}

.portfolio .portfolio-wrap:hover img {
  transform: scale(1.2);
}

.portfolio .portfolio-wrap:hover .portfolio-info {
  opacity: 1;
}

/*--------------------------------------------------------------
# Portfolio Details
--------------------------------------------------------------*/
.portfolio-details {
  padding-top: 40px;
}

.portfolio-details .portfolio-details-slider img {
  width: 100%;
}

.portfolio-details .portfolio-details-slider .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.portfolio-details
  .portfolio-details-slider
  .swiper-pagination
  .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #3498db;
}

.portfolio-details
  .portfolio-details-slider
  .swiper-pagination
  .swiper-pagination-bullet-active {
  background-color: #3498db;
}

.portfolio-details .portfolio-info {
  padding: 30px;
  box-shadow: 0px 0 30px rgba(34, 34, 34, 0.08);
}

.portfolio-details .portfolio-info h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.portfolio-details .portfolio-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.portfolio-details .portfolio-info ul li + li {
  margin-top: 10px;
}

.portfolio-details .portfolio-description {
  padding-top: 30px;
}

.portfolio-details .portfolio-description h2 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}

.portfolio-details .portfolio-description p {
  padding: 0;
}

/*--------------------------------------------------------------
# Team
--------------------------------------------------------------*/
.team .member {
  margin-bottom: 20px;
  overflow: hidden;
  text-align: center;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

.team .member .member-img {
  position: relative;
  overflow: hidden;
}

.team .member .social {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 40px;
  opacity: 0;
  transition: ease-in-out 0.3s;
  background: rgba(255, 255, 255, 0.85);
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.team .member .social a {
  transition: color 0.3s;
  color: #222222;
  margin: 0 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.team .member .social a i {
  line-height: 0;
}

.team .member .social a:hover {
  color: #3498db;
}

.team .member .social i {
  font-size: 18px;
  margin: 0 2px;
}

.team .member .member-info {
  padding: 25px 15px;
}

.team .member .member-info h4 {
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 18px;
  color: #222222;
}

.team .member .member-info span {
  display: block;
  font-size: 13px;
  font-weight: 400;
  color: #aaaaaa;
}

.team .member .member-info p {
  font-style: italic;
  font-size: 14px;
  line-height: 26px;
  color: #777777;
}

.team .member:hover .social {
  opacity: 1;
}

/*--------------------------------------------------------------
# Pricing
--------------------------------------------------------------*/
.pricing .box {
  padding: 20px;
  background: #f9f9f9;
  text-align: center;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  border: 2px solid #f9f9f9;
}

.pricing .box h3 {
  font-weight: 400;
  padding: 15px;
  margin-top: 15px;
  font-size: 18px;
  font-weight: 600;
  color: #222222;
}

.pricing .box h4 {
  font-size: 42px;
  color: #3498db;
  font-weight: 500;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 20px;
}

.pricing .box h4 sup {
  font-size: 20px;
  top: -15px;
  left: -3px;
}

.pricing .box h4 span {
  color: #bababa;
  font-size: 16px;
  font-weight: 300;
}

.pricing .box ul {
  padding: 0;
  list-style: none;
  color: #222222;
  text-align: center;
  line-height: 20px;
  font-size: 14px;
}

.pricing .box ul li {
  padding-bottom: 16px;
}

.pricing .box ul i {
  color: #3498db;
  font-size: 18px;
  padding-right: 4px;
}

.pricing .box ul .na {
  color: #ccc;
  text-decoration: line-through;
}

.pricing .box .btn-wrap {
  padding: 15px;
  text-align: center;
}

.pricing .box .btn-buy {
  display: inline-block;
  padding: 10px 40px 12px 40px;
  border-radius: 50px;
  border: 2px solid #3498db;
  color: #3498db;
  font-size: 14px;
  font-weight: 400;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  transition: 0.3s;
}

.pricing .box .btn-buy:hover {
  background: #3498db;
  color: #fff;
}

.pricing .recommended {
  border-color: #3498db;
}

.pricing .recommended .btn-buy {
  background: #3498db;
  color: #fff;
}

.pricing .recommended .btn-buy:hover {
  background: #2383c4;
  border-color: #2383c4;
}

/*--------------------------------------------------------------
# F.A.Q
--------------------------------------------------------------*/
.faq .faq-item {
  margin: 20px 0;
  padding: 20px 0;
  border-bottom: 1px solid #eeeeee;
}

.faq .faq-item i {
  color: #8bc4ea;
  font-size: 24px;
  float: left;
  line-height: 0;
  padding: 13px 0 0 0;
  margin: 0;
}

.faq .faq-item h4 {
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  margin: 0 0 10px 32px;
  font-family: "Poppins", sans-serif;
}

.faq .faq-item p {
  font-size: 15px;
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .contact-about h3 {
  font-size: 28px;
  margin: 0 0 10px 0;
  padding: 0;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 1px;
  color: #222222;
}

.contact .contact-about p {
  font-size: 14px;
  line-height: 24px;
  font-family: "Raleway", sans-serif;
  color: #888;
}

.contact .social-links {
  padding-bottom: 20px;
}

.contact .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #fff;
  color: #3498db;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
  border: 1px solid #3498db;
}

.contact .social-links a:hover {
  background: #3498db;
  color: #fff;
}

.contact .info {
  color: #444444;
}

.contact .info i {
  font-size: 32px;
  color: #3498db;
  float: left;
  line-height: 1;
}

.contact .info p {
  padding: 0 0 10px 42px;
  line-height: 28px;
  font-size: 14px;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .error-message br + br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form .form-group {
  margin-bottom: 20px;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  padding: 10px 15px;
}

.contact .php-email-form input:focus,
.contact .php-email-form textarea:focus {
  border-color: #3498db;
}

.contact .php-email-form button[type="submit"] {
  background: #3498db;
  border: 0;
  padding: 10px 24px;
  color: #fff;
  transition: 0.4s;
  border-radius: 50px;
}

.contact .php-email-form button[type="submit"]:hover {
  background: #2383c4;
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
  padding: 15px 0;
  background: #f8f8f8;
  min-height: 40px;
  margin-top: 86px;
}

@media (max-width: 992px) {
  .breadcrumbs {
    margin-top: 72px;
  }
}

.breadcrumbs h2 {
  font-size: 28px;
  font-weight: 400;
  margin: 0;
}

.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
}

@media (max-width: 992px) {
  .breadcrumbs ol {
    margin-top: 10px;
  }
}

.breadcrumbs ol li + li {
  padding-left: 10px;
}

.breadcrumbs ol li + li::before {
  display: inline-block;
  padding-right: 10px;
  color: #3c3c3c;
  content: "/";
}

@media (max-width: 768px) {
  .breadcrumbs .d-flex {
    display: block !important;
  }
  .breadcrumbs ol {
    display: block;
  }
  .breadcrumbs ol li {
    display: inline-block;
  }
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  background: #fff;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
  padding: 30px 0;
  color: #222222;
  font-size: 14px;
}

#footer .credits {
  font-size: 13px;
  padding-top: 5px;
  color: #222222;
}

#footer .footer-links a {
  color: #222222;
  padding-left: 15px;
}

#footer .footer-links a:first-child {
  padding-left: 0;
}

#footer .footer-links a:hover {
  color: #3498db;
}

@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    /*max-width: 1190px !important;*/
    max-width: 1190px;
  }
}

.swiper-slide {
  padding: 30px 60px;
}

.testimonials .testimonial-item {
  padding: 30px !important;
  overflow: visible;
  border-bottom-left-radius: 0px;
}

.count-box h3 span {
  font-size: 72px;
  background: -webkit-linear-gradient(#269eb7, #01e8fd);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
  line-height: 1.3em;
  letter-spacing: -2px;
  font-family: Nunito;
}

.count-box h3 span {
  --textgradient_color1: #269eb7;
  --textgradient_color2: #01e8fd;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  /*font-family: Nunito !important;*/
  font-family: Helvetica, Arial, "Roboto", sans-serif;
  letter-spacing: 1px;
}

.img-hre {
  position: absolute;
  top: 125px;
  z-index: -50;
}
.hrt-img-sm {
  position: absolute;
  text-align: left;
  top: -35%;
}

.hrt-img-sm-2 {
  text-align: right;
  top: 60%;
  right: 0px;
}

.hrt-img-sm-3 {
  position: absolute;
  text-align: left;
  top: 81%;
  left: -55px;
}

.hrt-img-sm-4 {
  position: absolute;
  text-align: right;
  top: 45%;
  right: 10px;
}

.bubbles_wrap {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  overflow: hidden;
  pointer-events: none;
}

@-webkit-keyframes animateBubble {
  0% {
    margin-top: 20%;
  }
  to {
    margin-top: -30%;
  }
}

@-moz-keyframes animateBubble {
  0% {
    margin-top: 20%;
  }
  to {
    margin-top: -30%;
  }
}

@keyframes animateBubble {
  0% {
    margin-top: 20%;
  }
  to {
    margin-top: -30%;
  }
}

@-webkit-keyframes sideWays {
  0% {
    margin-left: 0;
  }
  to {
    margin-left: 25px;
  }
}

@-moz-keyframes sideWays {
  0% {
    margin-left: 0;
  }
  to {
    margin-left: 25px;
  }
}

@keyframes sideWays {
  0% {
    margin-left: 0;
  }
  to {
    margin-left: 25px;
  }
}

.x1 {
  -webkit-animation: animateBubble 15s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
  animation: animateBubble 15s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
  left: 6%;
  top: 45%;
  background: #ffb739;
}

.x2 {
  -webkit-animation: animateBubble 10s linear infinite,
    sideWays 4s ease-in-out infinite alternate;
  animation: animateBubble 10s linear infinite,
    sideWays 4s ease-in-out infinite alternate;
  left: 5%;
  top: 80%;
  background: #fc4fdd;
}

.x3 {
  -webkit-animation: animateBubble 18s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
  animation: animateBubble 18s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
  left: 10%;
  top: 40%;
  background: #5ed5cb;
}

.x4 {
  -webkit-animation: animateBubble 12s linear infinite,
    sideWays 3s ease-in-out infinite alternate;
  animation: animateBubble 12s linear infinite,
    sideWays 3s ease-in-out infinite alternate;
  left: 20%;
  top: 0;
  background: #3de4a3;
}

.x5 {
  -webkit-animation: animateBubble 19s linear infinite,
    sideWays 4s ease-in-out infinite alternate;
  animation: animateBubble 19s linear infinite,
    sideWays 4s ease-in-out infinite alternate;
  left: 30%;
  top: 50%;
  background: #fc4fb1;
}

.x6 {
  -webkit-animation: animateBubble 11s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
  animation: animateBubble 11s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
  left: 50%;
  top: 0;
  background: #2c4aff;
}

.x7 {
  -webkit-animation: animateBubble 10s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
  animation: animateBubble 10s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
  left: 65%;
  top: 70%;
  background: #ffb739;
}

.x8 {
  -webkit-animation: animateBubble 12s linear infinite,
    sideWays 3s ease-in-out infinite alternate;
  animation: animateBubble 12s linear infinite,
    sideWays 3s ease-in-out infinite alternate;
  left: 80%;
  top: 10%;
  background: #8f3de4;
}

.x9 {
  -webkit-animation: animateBubble 19s linear infinite,
    sideWays 4s ease-in-out infinite alternate;
  animation: animateBubble 19s linear infinite,
    sideWays 4s ease-in-out infinite alternate;
  left: 90%;
  top: 50%;
  background: #fff339;
}

.x10 {
  -webkit-animation: animateBubble 16s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
  animation: animateBubble 16s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
  left: 80%;
  top: 80%;
  background: #fc4f4f;
}

.bubble {
  border-radius: 50%;
  box-shadow: none;
  height: 5px;
  position: absolute;
  width: 5px;
}

.bubbles_wrap {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  overflow: hidden;
  pointer-events: none;
}

.tri img {
  text-align: left;

  left: -24%;
  width: 32px;
  height: 32px;
}

.tri-2 img {
  text-align: left;
  z-index: 50;
  width: 36px;
  max-width: 36px;
  /* top: 56%; */
  /* left: -24%; */
  position: absolute;
  right: 235px;
  /* top: 304px;*/
}

.tri-1 img,
.cros-1 img,
.cros img {
  width: 32px !important;
  height: 32px !important;

  -webkit-animation: movedelement 5s linear infinite;
  animation: movedelement 5s linear infinite;
  animation-duration: 5s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-name: movedelement;
}

.tri-1 {
  position: relative;
  left: -24%;
  width: 32px;
  height: 32px;
}

.cros-1 {
  position: relative;
  left: -35%;
  width: 32px;
  height: 32px;
  top: -50px;
}

.cros-2 {
  position: relative;
  width: 18px;
  height: 18px;
  left: -210px;
  top: 25%;
  -webkit-animation: rotatedelement 5s linear infinite;
  animation: rotatedelement 5s linear infinite;
  animation-duration: 5s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-name: rotatedelement;
}
.cros {
  position: relative;
  left: -192px;
  top: 69px;
}

.r-cros {
  position: relative;
  right: -121%;
}

.r-cros-1 {
  position: relative;
  right: -117%;
  width: 32px;
  height: 32px;
  top: 237px;
  -webkit-animation: rotatedelement 5s linear infinite;
  animation: rotatedelement 5s linear infinite;
  animation-duration: 5s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-name: rotatedelement;
}

.r-cros-2 {
  position: relative;
  width: 18px;
  height: 18px;
  right: -210px;
  top: 25%;
}

.r-cros-3 {
  position: relative;
  right: -112%;
  top: 69px;

  -webkit-animation: rotatedelement 5s linear infinite;
  animation: rotatedelement 5s linear infinite;
  animation-duration: 5s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-name: rotatedelement;
}

.r-tri-1 {
  position: relative;
  right: -85%;
  width: 32px;
  height: 32px;
  top: -36%;

  -webkit-animation: rotatedelement 5s linear infinite;
  animation: rotatedelement 5s linear infinite;
  animation-duration: 5s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-name: rotatedelement;
}

.r-cros-1 img,
.r-cros-2 img,
.r-cros-3 img,
.r-tri-1 img,
.r-cros img {
  width: 32px !important;
  height: 32px !important;
}

.woble {
  position: absolute;
  -webkit-animation: gt3pulse_out 8s linear infinite;
  animation: gt3pulse_out 8s linear infinite;
  animation-duration: 8s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-name: gt3pulse_out;
  right: 13%;
  width: 70%;
}
.woble-2 {
  -webkit-animation: gt3pulse_out 8s linear infinite;
  animation: gt3pulse_out 8s linear infinite;
  animation-duration: 8s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-name: gt3pulse_out;
  position: absolute;
  left: 6%;
  z-index: -14;
  width: 70%;
}

.elementor-element.elementor-element-86024f6 img {
  width: 100px;
  max-width: 100%;
}

.elementor-element.elementor-element-86024f6 {
  z-index: 50;
  width: 36px;
  max-width: 36px;
  top: 37%;
  position: absolute;
}

@-webkit-keyframes movedelement {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  25% {
    -webkit-transform: translate(10px, 10px);
    transform: translate(10px, 10px);
  }
  50% {
    -webkit-transform: translate(5px, 5px);
    transform: translate(5px, 5px);
  }
  75% {
    -webkit-transform: translate(10px, -5px);
    transform: translate(10px, -5px);
  }
  to {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

@keyframes movedelement {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  25% {
    -webkit-transform: translate(10px, 10px);
    transform: translate(10px, 10px);
  }
  50% {
    -webkit-transform: translate(5px, 5px);
    transform: translate(5px, 5px);
  }
  75% {
    -webkit-transform: translate(10px, -5px);
    transform: translate(10px, -5px);
  }
  to {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

@-webkit-keyframes movedelement2 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  25% {
    -webkit-transform: translate(-10px, -10px);
    transform: translate(-10px, -10px);
  }
  50% {
    -webkit-transform: translate(-5px, -5px);
    transform: translate(-5px, -5px);
  }
  75% {
    -webkit-transform: translate(-10px, 5px);
    transform: translate(-10px, 5px);
  }
  to {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

@keyframes movedelement2 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  25% {
    -webkit-transform: translate(-10px, -10px);
    transform: translate(-10px, -10px);
  }
  50% {
    -webkit-transform: translate(-5px, -5px);
    transform: translate(-5px, -5px);
  }
  75% {
    -webkit-transform: translate(-10px, 5px);
    transform: translate(-10px, 5px);
  }
  to {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

@-webkit-keyframes movedelement3 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  25% {
    -webkit-transform: translate(15px, 15px);
    transform: translate(15px, 15px);
  }
  50% {
    -webkit-transform: translate(8px, 8px);
    transform: translate(8px, 8px);
  }
  75% {
    -webkit-transform: translate(15px, -8px);
    transform: translate(15px, -8px);
  }
  to {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

@keyframes movedelement3 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  25% {
    -webkit-transform: translate(15px, 15px);
    transform: translate(15px, 15px);
  }
  50% {
    -webkit-transform: translate(8px, 8px);
    transform: translate(8px, 8px);
  }
  75% {
    -webkit-transform: translate(15px, -8px);
    transform: translate(15px, -8px);
  }
  to {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

@-webkit-keyframes rotatedelement {
  0% {
    -webkit-transform: translate(0) rotate(0deg);
    transform: translate(0) rotate(0deg);
  }
  25% {
    -webkit-transform: translate(-10px, 10px) rotate(10deg);
    transform: translate(-10px, 10px) rotate(10deg);
  }
  50% {
    -webkit-transform: translate(5px, -5px) rotate(25deg);
    transform: translate(5px, -5px) rotate(25deg);
  }
  75% {
    -webkit-transform: translate(15px, -5px) rotate(15deg);
    transform: translate(15px, -5px) rotate(15deg);
  }
  to {
    -webkit-transform: translate(0) rotate(0deg);
    transform: translate(0) rotate(0deg);
  }
}

@keyframes rotatedelement {
  0% {
    -webkit-transform: translate(0) rotate(0deg);
    transform: translate(0) rotate(0deg);
  }
  25% {
    -webkit-transform: translate(-10px, 10px) rotate(10deg);
    transform: translate(-10px, 10px) rotate(10deg);
  }
  50% {
    -webkit-transform: translate(5px, -5px) rotate(25deg);
    transform: translate(5px, -5px) rotate(25deg);
  }
  75% {
    -webkit-transform: translate(15px, -5px) rotate(15deg);
    transform: translate(15px, -5px) rotate(15deg);
  }
  to {
    -webkit-transform: translate(0) rotate(0deg);
    transform: translate(0) rotate(0deg);
  }
}

@-webkit-keyframes gt3flash {
  0%,
  50%,
  to {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0.4;
  }
}

@keyframes gt3flash {
  0%,
  50%,
  to {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0.4;
  }
}

@-webkit-keyframes gt3pulse {
  25% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  75% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

@keyframes gt3pulse {
  25% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  75% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

@-webkit-keyframes gt3pulse_figure {
  25% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
  75% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
  }
}

@keyframes gt3pulse_figure {
  25% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
  75% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
  }
}

@-webkit-keyframes gt3pulse_out {
  25% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  75% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

@keyframes gt3pulse_out {
  25% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  75% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

.gt3_moved_element {
  -webkit-animation: movedelement 5s linear infinite;
  animation: movedelement 5s linear infinite;
}

.gt3_moved_element2 {
  -webkit-animation: movedelement2 8s linear infinite;
  animation: movedelement2 8s linear infinite;
}

.gt3_moved_element3 {
  -webkit-animation: movedelement3 12s linear infinite;
  animation: movedelement3 12s linear infinite;
}

.gt3_rotated_element {
  -webkit-animation: rotatedelement 5s linear infinite;
  animation: rotatedelement 5s linear infinite;
}

.gt3_flash_element {
  -webkit-animation: gt3flash 7s linear infinite;
  animation: gt3flash 7s linear infinite;
}

.gt3_pulse_element {
  -webkit-animation: gt3pulse 7s linear infinite;
  animation: gt3pulse 7s linear infinite;
}

.gt3_pulse_figure_element {
  -webkit-animation: gt3pulse_figure 8s linear infinite;
  animation: gt3pulse_figure 8s linear infinite;
}

.gt3_pulse_out_element {
  -webkit-animation: gt3pulse_out 8s linear infinite;
  animation: gt3pulse_out 8s linear infinite;
}

.gt3_moved_element,
.gt3_rotated_element {
  pointer-events: none;
}

@keyframes bounce {
  20%,
  53%,
  80%,
  from,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0);
  }
  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -30px, 0);
  }
  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -15px, 0);
  }
  90% {
    transform: translate3d(0, -4px, 0);
  }
}
.bounce {
  animation-name: bounce;
  transform-origin: center bottom;
}
@keyframes flash {
  50%,
  from,
  to {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}
.flash {
  animation-name: flash;
}
@keyframes pulse {
  from,
  to {
    transform: scale3d(1, 1, 1);
  }
  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }
}
.pulse {
  animation-name: pulse;
}
@keyframes rubberBand {
  from,
  to {
    transform: scale3d(1, 1, 1);
  }
  30% {
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    transform: scale3d(1.05, 0.95, 1);
  }
}
.rubberBand {
  animation-name: rubberBand;
}
@keyframes shake {
  from,
  to {
    transform: translate3d(0, 0, 0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translate3d(-10px, 0, 0);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translate3d(10px, 0, 0);
  }
}
.shake {
  animation-name: shake;
}
@keyframes headShake {
  0% {
    transform: translateX(0);
  }
  6.5% {
    transform: translateX(-6px) rotateY(-9deg);
  }
  18.5% {
    transform: translateX(5px) rotateY(7deg);
  }
  31.5% {
    transform: translateX(-3px) rotateY(-5deg);
  }
  43.5% {
    transform: translateX(2px) rotateY(3deg);
  }
  50% {
    transform: translateX(0);
  }
}
.headShake {
  animation-timing-function: ease-in-out;
  animation-name: headShake;
}
@keyframes swing {
  20% {
    transform: rotate3d(0, 0, 1, 15deg);
  }
  40% {
    transform: rotate3d(0, 0, 1, -10deg);
  }
  60% {
    transform: rotate3d(0, 0, 1, 5deg);
  }
  80% {
    transform: rotate3d(0, 0, 1, -5deg);
  }
  to {
    transform: rotate3d(0, 0, 1, 0deg);
  }
}
.swing {
  transform-origin: top center;
  animation-name: swing;
}
@keyframes tada {
  from,
  to {
    transform: scale3d(1, 1, 1);
  }
  10%,
  20% {
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }
  30%,
  50%,
  70%,
  90% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  40%,
  60%,
  80% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
}
.tada {
  animation-name: tada;
}
@keyframes wobble {
  from,
  to {
    transform: none;
  }
  15% {
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
  }
  30% {
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
  }
  45% {
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }
  60% {
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }
  75% {
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }
}
.wobble {
  animation-name: wobble;
}
@keyframes jello {
  11.1%,
  from,
  to {
    transform: none;
  }
  22.2% {
    transform: skewX(-12.5deg) skewY(-12.5deg);
  }
  33.3% {
    transform: skewX(6.25deg) skewY(6.25deg);
  }
  44.4% {
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }
  55.5% {
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }
  66.6% {
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }
  77.7% {
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }
  88.8% {
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
}
.jello {
  animation-name: jello;
  transform-origin: center;
}
@keyframes bounceIn {
  20%,
  40%,
  60%,
  80%,
  from,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}
.bounceIn {
  animation-name: bounceIn;
}
@keyframes bounceInDown {
  60%,
  75%,
  90%,
  from,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }
  75% {
    transform: translate3d(0, -10px, 0);
  }
  90% {
    transform: translate3d(0, 5px, 0);
  }
  to {
    transform: none;
  }
}
.bounceInDown {
  animation-name: bounceInDown;
}
@keyframes bounceInLeft {
  60%,
  75%,
  90%,
  from,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }
  75% {
    transform: translate3d(-10px, 0, 0);
  }
  90% {
    transform: translate3d(5px, 0, 0);
  }
  to {
    transform: none;
  }
}
.bounceInLeft {
  animation-name: bounceInLeft;
}
@keyframes bounceInRight {
  60%,
  75%,
  90%,
  from,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    transform: translate3d(10px, 0, 0);
  }
  90% {
    transform: translate3d(-5px, 0, 0);
  }
  to {
    transform: none;
  }
}
.bounceInRight {
  animation-name: bounceInRight;
}
@keyframes bounceInUp {
  60%,
  75%,
  90%,
  from,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }
  75% {
    transform: translate3d(0, 10px, 0);
  }
  90% {
    transform: translate3d(0, -5px, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
.bounceInUp {
  animation-name: bounceInUp;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fadeIn {
  animation-name: fadeIn;
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
.fadeInDown {
  animation-name: fadeInDown;
}
@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
.fadeInLeft {
  animation-name: fadeInLeft;
}
@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
.fadeInRight {
  animation-name: fadeInRight;
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
.fadeInUp {
  animation-name: fadeInUp;
}
@keyframes lightSpeedIn {
  from {
    transform: translate3d(100%, 0, 0) skewX(-30deg);
    opacity: 0;
  }
  60% {
    transform: skewX(20deg);
    opacity: 1;
  }
  80% {
    transform: skewX(-5deg);
    opacity: 1;
  }
  to {
    transform: none;
    opacity: 1;
  }
}
.lightSpeedIn {
  animation-name: lightSpeedIn;
  animation-timing-function: ease-out;
}
@keyframes rotateIn {
  from {
    transform-origin: center;
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0;
  }
  to {
    transform-origin: center;
    transform: none;
    opacity: 1;
  }
}
.rotateIn {
  animation-name: rotateIn;
}
@keyframes rotateInDownLeft {
  from {
    transform-origin: left bottom;
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
  to {
    transform-origin: left bottom;
    transform: none;
    opacity: 1;
  }
}
.rotateInDownLeft {
  animation-name: rotateInDownLeft;
}
@keyframes rotateInDownRight {
  from {
    transform-origin: right bottom;
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
  to {
    transform-origin: right bottom;
    transform: none;
    opacity: 1;
  }
}
.rotateInDownRight {
  animation-name: rotateInDownRight;
}
@keyframes rotateInUpLeft {
  from {
    transform-origin: left bottom;
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
  to {
    transform-origin: left bottom;
    transform: none;
    opacity: 1;
  }
}
.rotateInUpLeft {
  animation-name: rotateInUpLeft;
}
@keyframes rotateInUpRight {
  from {
    transform-origin: right bottom;
    transform: rotate3d(0, 0, 1, -90deg);
    opacity: 0;
  }
  to {
    transform-origin: right bottom;
    transform: none;
    opacity: 1;
  }
}
.rotateInUpRight {
  animation-name: rotateInUpRight;
}
@keyframes rollIn {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
.rollIn {
  animation-name: rollIn;
}
@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
.zoomIn {
  animation-name: zoomIn;
}
@keyframes zoomInDown {
  from {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
.zoomInDown {
  animation-name: zoomInDown;
}
@keyframes zoomInLeft {
  from {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
.zoomInLeft {
  animation-name: zoomInLeft;
}
@keyframes zoomInRight {
  from {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
.zoomInRight {
  animation-name: zoomInRight;
}
@keyframes zoomInUp {
  from {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
.zoomInUp {
  animation-name: zoomInUp;
}
@keyframes slideInDown {
  from {
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
.slideInDown {
  animation-name: slideInDown;
}
@keyframes slideInLeft {
  from {
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
.slideInLeft {
  animation-name: slideInLeft;
}
@keyframes slideInRight {
  from {
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
.slideInRight {
  animation-name: slideInRight;
}
@keyframes slideInUp {
  from {
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
.slideInUp {
  animation-name: slideInUp;
}
.elementor-animation-grow {
  transition-duration: 0.3s;
  transition-property: transform;
}
.elementor-animation-grow:active,
.elementor-animation-grow:focus,
.elementor-animation-grow:hover {
  transform: scale(1.1);
}
.elementor-animation-shrink {
  transition-duration: 0.3s;
  transition-property: transform;
}
.elementor-animation-shrink:active,
.elementor-animation-shrink:focus,
.elementor-animation-shrink:hover {
  transform: scale(0.9);
}
@keyframes elementor-animation-pulse {
  25% {
    transform: scale(1.1);
  }
  75% {
    transform: scale(0.9);
  }
}
.elementor-animation-pulse:active,
.elementor-animation-pulse:focus,
.elementor-animation-pulse:hover {
  animation-name: elementor-animation-pulse;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
@keyframes elementor-animation-pulse-grow {
  to {
    transform: scale(1.1);
  }
}
.elementor-animation-pulse-grow:active,
.elementor-animation-pulse-grow:focus,
.elementor-animation-pulse-grow:hover {
  animation-name: elementor-animation-pulse-grow;
  animation-duration: 0.3s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
@keyframes elementor-animation-pulse-shrink {
  to {
    transform: scale(0.9);
  }
}
.elementor-animation-pulse-shrink:active,
.elementor-animation-pulse-shrink:focus,
.elementor-animation-pulse-shrink:hover {
  animation-name: elementor-animation-pulse-shrink;
  animation-duration: 0.3s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
@keyframes elementor-animation-push {
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}
.elementor-animation-push:active,
.elementor-animation-push:focus,
.elementor-animation-push:hover {
  animation-name: elementor-animation-push;
  animation-duration: 0.3s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
}
@keyframes elementor-animation-pop {
  50% {
    transform: scale(1.2);
  }
}
.elementor-animation-pop:active,
.elementor-animation-pop:focus,
.elementor-animation-pop:hover {
  animation-name: elementor-animation-pop;
  animation-duration: 0.3s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
}
.elementor-animation-bounce-in {
  transition-duration: 0.5s;
}
.elementor-animation-bounce-in:active,
.elementor-animation-bounce-in:focus,
.elementor-animation-bounce-in:hover {
  transform: scale(1.2);
  transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
}
.elementor-animation-bounce-out {
  transition-duration: 0.5s;
}
.elementor-animation-bounce-out:active,
.elementor-animation-bounce-out:focus,
.elementor-animation-bounce-out:hover {
  transform: scale(0.8);
  transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
}
.elementor-animation-rotate {
  transition-duration: 0.3s;
  transition-property: transform;
}
.elementor-animation-rotate:active,
.elementor-animation-rotate:focus,
.elementor-animation-rotate:hover {
  transform: rotate(4deg);
}
.elementor-animation-grow-rotate {
  transition-duration: 0.3s;
  transition-property: transform;
}
.elementor-animation-grow-rotate:active,
.elementor-animation-grow-rotate:focus,
.elementor-animation-grow-rotate:hover {
  transform: scale(1.1) rotate(4deg);
}
.elementor-animation-float {
  transition-duration: 0.3s;
  transition-property: transform;
  transition-timing-function: ease-out;
}
.elementor-animation-float:active,
.elementor-animation-float:focus,
.elementor-animation-float:hover {
  transform: translateY(-8px);
}
.elementor-animation-sink {
  transition-duration: 0.3s;
  transition-property: transform;
  transition-timing-function: ease-out;
}
.elementor-animation-sink:active,
.elementor-animation-sink:focus,
.elementor-animation-sink:hover {
  transform: translateY(8px);
}
@keyframes elementor-animation-bob {
  0% {
    transform: translateY(-8px);
  }
  50% {
    transform: translateY(-4px);
  }
  100% {
    transform: translateY(-8px);
  }
}
@keyframes elementor-animation-bob-float {
  100% {
    transform: translateY(-8px);
  }
}
.elementor-animation-bob:active,
.elementor-animation-bob:focus,
.elementor-animation-bob:hover {
  animation-name: elementor-animation-bob-float, elementor-animation-bob;
  animation-duration: 0.3s, 1.5s;
  animation-delay: 0s, 0.3s;
  animation-timing-function: ease-out, ease-in-out;
  animation-iteration-count: 1, infinite;
  animation-fill-mode: forwards;
  animation-direction: normal, alternate;
}
@keyframes elementor-animation-hang {
  0% {
    transform: translateY(8px);
  }
  50% {
    transform: translateY(4px);
  }
  100% {
    transform: translateY(8px);
  }
}
@keyframes elementor-animation-hang-sink {
  100% {
    transform: translateY(8px);
  }
}
.elementor-animation-hang:active,
.elementor-animation-hang:focus,
.elementor-animation-hang:hover {
  animation-name: elementor-animation-hang-sink, elementor-animation-hang;
  animation-duration: 0.3s, 1.5s;
  animation-delay: 0s, 0.3s;
  animation-timing-function: ease-out, ease-in-out;
  animation-iteration-count: 1, infinite;
  animation-fill-mode: forwards;
  animation-direction: normal, alternate;
}
.elementor-animation-skew {
  transition-duration: 0.3s;
  transition-property: transform;
}
.elementor-animation-skew:active,
.elementor-animation-skew:focus,
.elementor-animation-skew:hover {
  transform: skew(-10deg);
}
.elementor-animation-skew-forward {
  transition-duration: 0.3s;
  transition-property: transform;
  transform-origin: 0 100%;
}
.elementor-animation-skew-forward:active,
.elementor-animation-skew-forward:focus,
.elementor-animation-skew-forward:hover {
  transform: skew(-10deg);
}
.elementor-animation-skew-backward {
  transition-duration: 0.3s;
  transition-property: transform;
  transform-origin: 0 100%;
}
.elementor-animation-skew-backward:active,
.elementor-animation-skew-backward:focus,
.elementor-animation-skew-backward:hover {
  transform: skew(10deg);
}
@keyframes elementor-animation-wobble-vertical {
  16.65% {
    transform: translateY(8px);
  }
  33.3% {
    transform: translateY(-6px);
  }
  49.95% {
    transform: translateY(4px);
  }
  66.6% {
    transform: translateY(-2px);
  }
  83.25% {
    transform: translateY(1px);
  }
  100% {
    transform: translateY(0);
  }
}
.elementor-animation-wobble-vertical:active,
.elementor-animation-wobble-vertical:focus,
.elementor-animation-wobble-vertical:hover {
  animation-name: elementor-animation-wobble-vertical;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}
@keyframes elementor-animation-wobble-horizontal {
  16.65% {
    transform: translateX(8px);
  }
  33.3% {
    transform: translateX(-6px);
  }
  49.95% {
    transform: translateX(4px);
  }
  66.6% {
    transform: translateX(-2px);
  }
  83.25% {
    transform: translateX(1px);
  }
  100% {
    transform: translateX(0);
  }
}
.elementor-animation-wobble-horizontal:active,
.elementor-animation-wobble-horizontal:focus,
.elementor-animation-wobble-horizontal:hover {
  animation-name: elementor-animation-wobble-horizontal;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}
@keyframes elementor-animation-wobble-to-bottom-right {
  16.65% {
    transform: translate(8px, 8px);
  }
  33.3% {
    transform: translate(-6px, -6px);
  }
  49.95% {
    transform: translate(4px, 4px);
  }
  66.6% {
    transform: translate(-2px, -2px);
  }
  83.25% {
    transform: translate(1px, 1px);
  }
  100% {
    transform: translate(0, 0);
  }
}
.elementor-animation-wobble-to-bottom-right:active,
.elementor-animation-wobble-to-bottom-right:focus,
.elementor-animation-wobble-to-bottom-right:hover {
  animation-name: elementor-animation-wobble-to-bottom-right;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}
@keyframes elementor-animation-wobble-to-top-right {
  16.65% {
    transform: translate(8px, -8px);
  }
  33.3% {
    transform: translate(-6px, 6px);
  }
  49.95% {
    transform: translate(4px, -4px);
  }
  66.6% {
    transform: translate(-2px, 2px);
  }
  83.25% {
    transform: translate(1px, -1px);
  }
  100% {
    transform: translate(0, 0);
  }
}
.elementor-animation-wobble-to-top-right:active,
.elementor-animation-wobble-to-top-right:focus,
.elementor-animation-wobble-to-top-right:hover {
  animation-name: elementor-animation-wobble-to-top-right;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}
@keyframes elementor-animation-wobble-top {
  16.65% {
    transform: skew(-12deg);
  }
  33.3% {
    transform: skew(10deg);
  }
  49.95% {
    transform: skew(-6deg);
  }
  66.6% {
    transform: skew(4deg);
  }
  83.25% {
    transform: skew(-2deg);
  }
  100% {
    transform: skew(0);
  }
}
.elementor-animation-wobble-top {
  transform-origin: 0 100%;
}
.elementor-animation-wobble-top:active,
.elementor-animation-wobble-top:focus,
.elementor-animation-wobble-top:hover {
  animation-name: elementor-animation-wobble-top;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}
@keyframes elementor-animation-wobble-bottom {
  16.65% {
    transform: skew(-12deg);
  }
  33.3% {
    transform: skew(10deg);
  }
  49.95% {
    transform: skew(-6deg);
  }
  66.6% {
    transform: skew(4deg);
  }
  83.25% {
    transform: skew(-2deg);
  }
  100% {
    transform: skew(0);
  }
}
.elementor-animation-wobble-bottom {
  transform-origin: 100% 0;
}
.elementor-animation-wobble-bottom:active,
.elementor-animation-wobble-bottom:focus,
.elementor-animation-wobble-bottom:hover {
  animation-name: elementor-animation-wobble-bottom;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}
@keyframes elementor-animation-wobble-skew {
  16.65% {
    transform: skew(-12deg);
  }
  33.3% {
    transform: skew(10deg);
  }
  49.95% {
    transform: skew(-6deg);
  }
  66.6% {
    transform: skew(4deg);
  }
  83.25% {
    transform: skew(-2deg);
  }
  100% {
    transform: skew(0);
  }
}
.elementor-animation-wobble-skew:active,
.elementor-animation-wobble-skew:focus,
.elementor-animation-wobble-skew:hover {
  animation-name: elementor-animation-wobble-skew;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}
@keyframes elementor-animation-buzz {
  50% {
    transform: translateX(3px) rotate(2deg);
  }
  100% {
    transform: translateX(-3px) rotate(-2deg);
  }
}
.elementor-animation-buzz:active,
.elementor-animation-buzz:focus,
.elementor-animation-buzz:hover {
  animation-name: elementor-animation-buzz;
  animation-duration: 0.15s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
@keyframes elementor-animation-buzz-out {
  10% {
    transform: translateX(3px) rotate(2deg);
  }
  20% {
    transform: translateX(-3px) rotate(-2deg);
  }
  30% {
    transform: translateX(3px) rotate(2deg);
  }
  40% {
    transform: translateX(-3px) rotate(-2deg);
  }
  50% {
    transform: translateX(2px) rotate(1deg);
  }
  60% {
    transform: translateX(-2px) rotate(-1deg);
  }
  70% {
    transform: translateX(2px) rotate(1deg);
  }
  80% {
    transform: translateX(-2px) rotate(-1deg);
  }
  90% {
    transform: translateX(1px) rotate(0);
  }
  100% {
    transform: translateX(-1px) rotate(0);
  }
}
.elementor-animation-buzz-out:active,
.elementor-animation-buzz-out:focus,
.elementor-animation-buzz-out:hover {
  animation-name: elementor-animation-buzz-out;
  animation-duration: 0.75s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
}

.elementor-6782 .elementor-element.elementor-element-07e1766 {
  text-align: left;
  z-index: 50;
  width: 13px;
  max-width: 13px;
  top: 37%;
}

body:not(.rtl) .elementor-6782 .elementor-element.elementor-element-07e1766 {
  left: -52%;
}

body.rtl .elementor-6782 .elementor-element.elementor-element-07e1766 {
  right: -52%;
}

.elementor-6782 .elementor-element.elementor-element-e739be3 img {
  width: 100px;
  max-width: 100%;
}

.elementor-6782 .elementor-element.elementor-element-e739be3 {
  text-align: left;
  z-index: 50;
  width: 21px;
  max-width: 21px;
  top: 27%;
}

body:not(.rtl) .elementor-6782 .elementor-element.elementor-element-e739be3 {
  left: -31%;
}

body.rtl .elementor-6782 .elementor-element.elementor-element-e739be3 {
  right: -31%;
}

.elementor-6782 .elementor-element.elementor-element-de268ec img {
  width: 100px;
  max-width: 100%;
}

.elementor-6782 .elementor-element.elementor-element-de268ec {
  text-align: left;
  z-index: 50;
  width: 36px;
  max-width: 36px;
  top: 60%;
}

body:not(.rtl) .elementor-6782 .elementor-element.elementor-element-de268ec {
  left: -43%;
}

body.rtl .elementor-6782 .elementor-element.elementor-element-de268ec {
  right: -43%;
}

.elementor-6782 .elementor-element.elementor-element-fb37729 img {
  width: 100px;
  max-width: 100%;
}

.elementor-6782 .elementor-element.elementor-element-fb37729 {
  text-align: right;
  z-index: 50;
  width: 13px;
  max-width: 13px;
  top: 14%;
}

body:not(.rtl) .elementor-6782 .elementor-element.elementor-element-fb37729 {
  right: 1%;
}

body.rtl .elementor-6782 .elementor-element.elementor-element-fb37729 {
  left: 1%;
}

.elementor-6782 .elementor-element.elementor-element-e8de75d {
  padding: 0% 0% 0% 12%;
}

.elementor-6782
  .elementor-element.elementor-element-551fc34
  > .elementor-column-wrap
  > .elementor-widget-wrap
  > .elementor-widget:not(.elementor-widget__width-auto):not(.elementor-widget__width-initial):not(:last-child):not(.elementor-absolute) {
  margin-bottom: 0px;
}

.info img {
  float: left;
  width: 36px;
  margin-right: 15px;
}
.info p {
  line-height: 23px !important;
  font-size: 14px;
  padding-left: 0px;
}


/*!* (1440x900) WXGA+ Display *!*/
/*@media screen and (max-width: 1640px) {*/
/*  body {*/
/*    zoom: 80%;*/
/*  }*/
/*}*/

/*!* (1440x900) WXGA+ Display *!*/
/*@media screen and (max-width: 1440px) {*/
/*  body {*/
/*    zoom: 70%;*/
/*  }*/
/*}*/

/*!* (1366x768) WXGA Display *!*/
/*@media screen and (max-width: 1366px) {*/
/*  body {*/
/*    zoom: 70%;*/
/*  }*/
/*}*/

/*!* (1280x1024) SXGA Display *!*/
/*@media screen and (max-width: 1280px) {*/
/*  body {*/
/*    zoom: 70%;*/
/*  }*/
/*}*/

/*!* (1280x1024) SXGA Display *!*/
/*@media screen and (max-width: 1000px) {*/
/*  .abot {*/
/*    margin-top: 0px;*/
/*  }*/
/*  .testimonials .bg-img {*/
/*    display: none;*/
/*  }*/
/*}*/
/*!* (1280x1024) SXGA Display *!*/
/*@media screen and (max-width: 570px) {*/
/*  .container,*/
/*  .container-fluid,*/
/*  .container-lg,*/
/*  .container-md,*/
/*  .container-sm,*/
/*  .container-xl,*/
/*  .container-xxl {*/
/*    padding-left: 8%;*/
/*    padding-right: 8%;*/
/*  }*/
/*}*/

.dropdown-form {
  min-width: 290px;
  margin: 0 auto;
}
.extended {
  width: 100%;
}

.input-group-text {
  background: none;
  min-height: 48px;
  border: none !important;
  border-bottom: 1px solid #adadad;
  border-radius: 0px;
  padding-left: 0px !important;
  border-bottom: 1px solid #adadad !important;
  background: #fff !important;
}

.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: none !important;
  outline: 0;
  box-shadow: none !important;
}

/* @media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1140px !important;
  }
} */

.dropdown-menu {
  position: absolute !important;
  right: 0 !important;
  left: auto !important;
}

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  /* display: none; */
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

a:not([href]) {
  color: inherit;
  text-decoration: none;
}
