.ftpS3Grid {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.ftps3GridTitle {

}

.ftpS3Grid .ftps3GridTitle .ftps3GridTitleText {
    margin-bottom: 0rem;
    margin-top: 0.5rem;
}

.admin-tabstrip-container-card .k-grid-header .k-grid-header-wrap .k-header .k-checkbox {
    display: none;
}

.admin-tabstrip-container-card .k-grid-header .k-grid-header-wrap .k-header {
    background-color: var(--global-landing-page-color);
    color: white;
}

/*.body_background .k-animation-container .k-slide-down-exit-active {*/
/*    background-color: red;*/
/*}*/

.admin-tabstrip-container-card .ftpS3Grid .ftp-s3-new-button-dropdown {
    margin: 0rem;
}

.k-animation-container .ftp-s3-dropdown-popup .k-group .k-item {
    background-color: white;
    color: var(--global-landing-page-color);

    width: 6rem;
}

.admin-tabstrip-container-card .k-content .k-pager-numbers .k-link {
    color: lightslategrey;
}


.k-animation-container .ftp-s3-dropdown-popup .k-group .k-item .k-link:active {
    background-color: var(--global-landing-page-color);
    color: white;

}

.admin-tabstrip-container-card .k-content .k-grid .k-grid-table .k-selected {
    background-color: #b4c4c7;
}

.k-animation-container .ftp-s3-dropdown-popup .k-group .k-item .k-link:active,
.k-animation-container .ftp-s3-dropdown-popup .k-group .k-item,
.k-animation-container .ftp-s3-dropdown-popup .k-group,
.k-animation-container .ftp-s3-dropdown-popup {
    width: 6rem;
}

.admin-tabstrip-container-card .k-tabstrip .k-content .k-child-animation-container .connections-manager-grid .k-grid-container {
    max-height: 55vh;
}

.admin-tabstrip-container-card .k-tabstrip .k-content .k-child-animation-container .connections-manager-grid .k-filtercell-operator {
    display: none;
}