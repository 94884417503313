.k-dialog-title {
  color: white;
}

.k-form-buttons {
  padding: 10px;
}

.signInDialog .k-dialog-titlebar {
  padding: 0%;
  background-color: white;
}

.k-header {
  background-color: white;
}

.signInDialog .k-dialog-close {
  color: gray;
  position: relative;

  left: -11px;
  top: -25px;
  padding: 0%;
}

.signInDialog .k-dialog .k-header .k-dialog-title{
  position: relative;
  left: 10px;
}

.signInDialog .k-dialog .k-window-content:last-child {
  padding-bottom: clamp(24px, 12px, 12px);
}

.k-form-buttons {
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  margin-top: 0em;
}

.signInDialog .k-window-content {
  padding: 10px 25px 5px 25px;
}

.k-form .signInDialogButtonDiv {
  /*padding: 0px !important;*/
  padding: 0px;
  width: 100%;
  align-self: flex-end;
  margin-top: auto;
}

.signInDialogButtonDiv .signInDialogButton {
  /*display: block;*/
  width: 100%;
  background-color: #269DB6;
  position: relative;
  top: 0px;
  padding-left: 0px;
  padding-right: 0px;
}

.signInDialogFormFieldsDiv .k-floating-label-container
.sidField {
  /*background-color: #e8f0fe;*/
  /*background-color: #fafafa;*/
  background-color: whitesmoke !important;
  /*box-shadow: 0.1px 0.1px 1px gray;*/
  /*border-style: solid;*/
  /*border-color: lightgrey;*/
  /*border-width: 0.1px;*/

  /*box-shadow: 0 0 0 2px rgb(0 0 0 / 8%);*/

  /*border: 0;*/
  border-radius: 3px;

  border-style: solid;
  border-width: 1.5px;
  border-color: rgb(0 0 0 / 8%);
}

/* Overrides chrome's styling of autocompleted input fields */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
  /*box-shadow: 0 0 0 2px rgb(0 0 0 / 8%) !important;*/


  -webkit-box-shadow: 0 0 0 30px whitesmoke inset !important;
  /*-webkit-box-shadow: 0 0 0 30px whitesmoke inset !important;*/


  /*-webkit-border-image: linear-gradient(whitesmoke, whitesmoke) !important;*/
}

.signInDialogButtonDiv .signInDialogButton:hover {
  background-color: #1d7b8d;
}

.k-form {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.resetPwLink {
  display: inline;
  width: fit-content;
  padding-top: 5px;
}

.k-form-field .signInField {
  width: 100%;
  background-color: rgb(232, 240, 254);
}

.signInDialogResetPwDiv {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-top: 1.85em;
  /*padding-top: 3.2em;*/
}

.signInDialogFormFieldsDiv {
  display: flex;
  flex-direction: column;
  justify-content: start;
  /*padding-top: 1.2em;*/
}

.k-form .signInDialogFormFieldsDiv .k-floating-label-container .passwordSidField {
  /*width: 100%;*/
  width: 370px;
}

.signInDialogFormFieldsDiv .signInPasswordDiv {
  display: flex;
  flex-direction: row;
}

.k-form .signInDialogFormFieldsDiv .signInPasswordDiv .showHidePassword {
  height: 39px;
  align-self: end;
  box-shadow: none;

  border-style: solid;
  border-width: 1.5px;
  border-color: rgb(0 0 0 / 8%);
  background-color: whitesmoke;


  position: relative;
  left: -39px;
}


/*Hide show/hide password icon only for Edge browser*/
::-ms-reveal {
  display: none;
}

