/* BOOTSTRAP CSS Override START */
.input-group .input-group-prepend .form-control{
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.input-group .input-group-prepend + .form-control{
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.input-group .input-group-append .form-control{
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.input-group .input-group-append + .form-control{
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.input-group .input-group-prepend .form-control{
  display: flex !important;
  align-items: center !important;
}
/* BOOTSTRAP CSS Override END */

/* FONTAWESOME CSS Override START */

.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome !important;
}

.fa-20 {
  display: inline-block;
  font: normal normal normal 20px/1 FontAwesome !important;
  padding-top: .5rem;
}

/* FONTAWESOME CSS Override END */

/*NAVIGATION HEADER*/
.nav-header {
  width:235px;
  height:8rem;
  display: inline-block;
  text-align: left;
  background-color: var(--global-landing-page-color) !important;
  transition: all .2s ease;
  z-index: 5;
  border-radius: 0px !important;
}
.nav-header .brand-title {
  margin-left: 10px;
  margin-top: 1rem;
  width: 80%;
}
/*.nav-header .brand-title {*/
/*  display: inline-block !important;*/
/*}*/
/*.nav-header .logo-abbr {*/
/*  display:inline-block !important;*/
/*}*/

/*NAVIGATION HEADER (LOGO)*/
/*.nav-header .brand-logo {*/
/*  display: flex;*/
/*  height: 100%;*/
/*  width: 100%;*/
/*  justify-content: flex-start;*/
/*  align-items: center;*/
/*  font-size: 1.125rem;*/
/*  color: #fff;*/
/*  text-decoration: none;*/
/*  padding-left: 30px;*/
/*  padding-right: 30px;*/
/*  font-weight: 700;*/
/*}*/

/*SIDE NAVIGATION BAR*/
.k-drawer {
  color: #FFFFFF !important;
  background-color: var(--global-landing-page-color) !important;
  margin-top:0rem !important;
  /*border-radius: 30px;*/
  border-bottom-left-radius: 0px;
  width: 10rem !important;
}
.k-state-selected {
  background-color: #FFFFFF !important;
  color: red !important;
}
.k-drawer-items {
  /*margin-top: 1rem !important;*/
  /*margin-top: 2rem !important;*/
  padding-top: 2rem !important;
}
.k-drawer-item:hover {
  background-color: #FFFFFF !important;
  color: var(--global-landing-page-color) !important;
}
.k-drawer-item.contact-us-class:hover {
  background-color: var(--global-landing-page-color) !important;
}
.k-drawer-item.user-profile-class {
  line-height: 1.5rem !important;
}
span.k-icon.icon-user-outline {
  line-height: 1.5rem !important;
}
.k-drawer-item.permissions-class {
  line-height: 1.5rem !important;
}
span.k-icon.icon-key-outline {
  line-height: 1.5rem !important;
}
.k-drawer-item.file-transfer-settings-class {
  line-height: 1.5rem !important;
}
span.k-icon.icon-arrow-right-outline {
  line-height: 1.5rem !important;
}
.k-drawer-item {
  line-height: 2.8rem !important;
  background-color: var(--global-landing-page-color) !important;
  color: #FFFFFF !important;
}
.k-drawer-item .k-icon {
  font-size: 22px !important;;
  line-height: 2.8rem !important;;
}
.k-drawer-container {
  padding-top: 5.5rem !important;
  height: 100% !important;
  width: 100% !important;
  position:fixed !important;
  /*background-color: #f5f5fe;*/
  /*background-color: rgb(253, 249, 243);*/
  background-color: rgb(247 249 252);
  /*background-color: #f3f7f7;*/
  /*background-color: #f3f3f3;*/
}
.k-drawer-content {
  height: 100% !important;
  position: relative;
  top: -0.75em;
  overflow: auto;
}

/*.k-drawer-content {*/
/*  !*height: 100% !important;*!*/
/*  max-height: 100vh;*/
/*  overflow-y: auto;*/
/*  position: relative;*/
/*  top: -0.75em;*/
/*}*/


/*ATTRIBUTION*/
.content-body,
.attribution-content {

  height: 95%;
  overflow-y: auto;
  margin-left: 1%;
}
/*AUDIENCE DESIGN*/
.audience-design-content {
  height: 95%;
  overflow-y: auto;
  margin-left: 1%;
}

/*DASHBOARD*/
.segment-dashboard-table {
  margin-left: 1%;
}
.segment-dashboard-content {
  height: 95%;
  overflow: auto;
  margin-left: 1em;
  margin-right: 1em;
}

/*USER PROFILE*/
.user-profile-content {
  height: 95%;
  overflow-y: auto;
}

/*PERMISSIONS*/
.permissions-content {
  height: 95%;
  overflow-y: auto;
}

/*FILE TRANSFER SETTINGS*/
.file-transfer-content {
  height: 95%;
  overflow-y: auto;
}

/*CONTACT US*/
.contact-us-content {
  height: 95%;
  overflow-y: auto;
  margin-left: 2%;
  margin-right: 2%;
}

/*ONBOARDING-COLUMN MAPPING*/
.select-control-label {
  font-size:1.2rem;
}
.k-dropdownlist.file-type-select-control {
  width:14rem;
  border-radius: .5rem;
  height: 2.2rem;
}
.k-dropdownlist.header-rows-select-control {
  width:5rem;
  border-radius: .5rem;
  height: 2.2rem;
}
.k-dropdownlist.delimiter-select-control {
  width:8rem;
  border-radius: .5rem;
  height: 2.2rem;
}
.k-dropdownlist.time-zone-select-control {
  width:10rem;
  border-radius: .5rem;
  height: 2.2rem;
}
.k-dropdownlist.date-format-select-control {
  width:12rem;
  border-radius: .5rem;
  height: 2.2rem;
}
.column-names-select-control {
  width:8rem;
  border-radius: .5rem;
  height: 2.2rem;
}
/* So dropdownlist items appear as they should in dialogs */
.k-animation-container {
  z-index: 10003 !important;
}

/*HELP CENTER - CONTACT US*/
.contact-us-sidebar-content {
  padding: 20px;
  border-radius: 20px;
  margin: 20px 20px 20px 20px;
  background-image: initial;
  background-position-x: initial;
  background-position-y: initial;
  background-size: initial;
  background-repeat-x: initial;
  background-repeat-y: initial;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: #476c75;
  /*background-color: var(--global-landing-page-color) !important;*/
  color: #FFFFFF !important;
  text-align: center;
}
.contact-us-sidebar-content h3 {
  font-size: 18px;
  font-family: Helvetica, Arial, "Roboto", sans-serif;
}
.contact-us-sidebar-content:hover {
  background-color: var(--global-landing-page-color);
}
.contact-us-button {
  background-color: #FFFFFF !important;
  color: black !important;
  border-radius: 60px !important;
  padding: 10px 26px !important;
}

/*ACTIVATE SEGMENT*/
.activate-segment-content {
  height: 95%;
  overflow-y: auto;
  margin-left: 2%;
  margin-right: 2%;
}


#main-wrapper {
  background-color: #f5f5fe;
}
.card {
  margin: 0rem 1rem 1rem 1rem !important;
  /*background-color: white !important;*/
  background-color: white;
  transition: all 0.5s ease-in-out !important;
  /*position: relative !important;*/
  border: 0px solid transparent !important;
  border-radius: 0.5rem !important;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.08);
  border-width: 1px;
  box-shadow: 0 3px 10px 0 rgb(99 118 129 / 30%) !important;
  /* height: calc(100%) !important; */
}
.card-header {
  border-color: #f0f1f5 !important;
  position: relative !important;
  background: transparent !important;
  padding: 1.5rem 1.875rem 1.25rem !important;
  justify-content: space-between !important;
  align-items: center !important;
}

a.btn.light {
  background-color: #f5f5fe !important;
  background-image: transparent !important;
  border-color: #f5f5fe !important;
  color: #00055d;
  padding: 12px 26px;
  border-radius: 60px;
  font-weight: 500;
  font-size: 14px;
}

a.btn.shadow.btn-xs.mr-1.sharp {
  background: transparent !important;
  color: #fff !important;
  background-color: var(--global-landing-page-color) !important;
  border-color: var(--global-landing-page-color) !important;
  border-radius: 60px !important;
  font-weight: 500 !important;
  font-size: 19px !important;
}

a.btn.btn-danger.shadow.btn-xs.sharp {
  border-radius: 60px !important;
}

.btn-danger{
  background-color: #d9534f !important;
}
.form-group{
  margin-bottom: 15px;
}
.form-control {
  background: #fff;
  border: 1px solid #f0f1f5 !important;
  color: #6e6e6e !important;
  height: 48px;
  border-radius: 100px;
  border-bottom: 1px solid #f0f1f5 !important;
  margin-bottom: 0;
}

.form-control:focus {
  border-color: #9daaef !important;
  outline: 0 !important;
}

.contact-us-btn,
.custom-btn {
  color: #fff !important;
  background-color: var(--global-landing-page-color) !important;
  border-color: var(--global-landing-page-color) !important;
  background-image: inherit !important;
  padding: 12px 26px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  border: none !important;
}

.contact-us-btn:hover,
.custom-btn:hover {
  background-color: #f5f5fe !important;
  border-color: #f5f5fe !important;
  color: #00055d !important;
}

.modal-custom-btn {
  color: #fff !important;
  background-color: var(--global-landing-page-color) !important;
  border-color: var(--global-landing-page-color) !important;
  background-image: inherit !important;
  padding: 12px 26px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  border: none !important;
}

body.body_background {
  font-weight: 400;
  line-height: 1.5;
  color: #7e7e7e;
  text-align: left;
  /*background-color: #f5f5fe;*/
  background-color: white;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: var(--global-landing-page-color) !important;
}

.modal-backdrop,
.modal-backdrop.fade,
.modal-backdrop.fade.show {
  width: 100%;
  height: 100%;
}

@media (min-width: 768px) {
  .file-uploading-popup .modal-dialog {
    width: 1400px !important;
    margin: 30px auto !important;
    max-width: 1400px !important;
  }
}

.widget-stat {
  /*background-color: transparent !important;*/
  background-color: transparent;
}

.input-rounded-cus {
  border-radius: 100px !important;
  border: 1px solid #f0f1f5 !important;
  height: 48px !important;
  width: 100% !important;
}

.input-rounded-select {
  border-radius: 100px !important;
  border: 1px solid #f0f1f5 !important;
  height: 48px !important;
  width: 45% !important;
  padding: 0.375rem 0.75rem;
}

.input-rounded-cus:focus,
.address_custom:focus,
.input-rounded-select:focus {
  background-color: #fff !important;
  border-color: #9daaef !important;
}

.custm_head {
  font-family: "poppins", sans-serif;
}

.custom-radius a.nav-link {
  background: #fff !important;
}

.address_custom {
  border: 1px solid #f0f1f5 !important;
}

button.btn.light.btn-primary.btn-rounded {
  background: white ;
  padding: 12px 26px ;
  /* border: 1px solid #f5f5fe !important; */
  color: var(--global-landing-page-color) ;
  border: 0.1rem solid var(--global-landing-page-color) ;
}

button.btn.light.btn-primary.btn-rounded:hover {
  background-color: var(--global-landing-page-color) ;
  border-color: var(--global-landing-page-color) ;
  color: #fff ;
  padding: 12px 26px;
}
button:hover{
  box-shadow: 0 0 0 0.25rem rgb(49 132 253 / 50%) !important;
}
button:focus{
  box-shadow: none !important;
}
.col-xl-4 {
  flex: 0 0 25% !important;
  max-width: 63% !important;
}

.widget-stat .media .media-body span {
  font-family: Helvetica, Arial, 'Roboto', sans-serif;
  font-size: 19.5px !important;
}

body {
  font-family: Helvetica, Arial, 'Roboto', sans-serif;
}
/*DASHBOARD CSS END*/

.custom-page-title {
  padding-right: 15px;
  min-width: 60%;
  margin-bottom: 0px;
}

.custom-page-title h3 {
  margin-left: 40px;
  margin-bottom: 0px;
  font-family: Helvetica, Arial, "Roboto", sans-serif;
}

.header-right {
  height: 100%;
  display: flex;
  margin-right: 30px;
}

.btn {
  padding: 12px 26px;
  border-radius: 60px;
  font-weight: 500;
  font-size: 14px;
}

.form-control {
  font-family: Helvetica, Arial, 'Roboto', sans-serif;
  background: #fff;
  border: 1px solid #f0f1f5;
  color: #6e6e6e;
  height: 48px;
}

.header-left .search_bar .search_icon {
  background: #fff !important;
  height: 48px;
  padding: 9px 20px 15px 0 !important;
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  border: 1px solid #ebebeb;
  border-width: 1px 1px 1px 0;
  border-radius: 60px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  margin-top: 0px;
}

.content-body .container-fluid {
  padding-top: 40px;
  padding-right: 40px;
  padding-left: 0px;
}

.custom-radius {
  border-radius: 60px;
}

.bg-samcast {
  background: var(--global-landing-page-color);
  border-color: var(--global-landing-page-color);
}

/*.samcast-menu.nav-pills .nav-link {*/
/*  border-radius: 70px;*/
/*  padding: 0px;*/
/*}*/

.samcast-menu.nav-pills .nav-link {
  border-radius: 5px;
  padding: 0px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: var(--global-landing-page-color);
}

.nav-pills .nav-link.active .bg-samcast {
  background: #fff;
}

.nav-pills .nav-link.active h3 {
  color: #fff;
}

.sam-drop {
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%236ebdcf' fill-opacity='0.11' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E"); border: 1px dashed var(--global-landing-page-color);
  padding: 60px 0px;
  text-align: center;
  /*border-radius: 10px;*/
  box-shadow: 0px 12px 23px 0px rgb(62 73 84 / 4%);
}

.sam-drop h3 {
  color: var(--global-landing-page-color);
  font-size: 18px;
  margin-bottom: 5px;
  line-height: 16px;
  font-family: Helvetica, Arial, 'Roboto', sans-serif;
}

.sam-drop p {
  color: var(--global-landing-page-color);
  font-size: 12px;
  font-family: Helvetica, Arial, 'Roboto', sans-serif;
}

.card h1,
.card h2,
.card h3,
.card h4,
.card h5,
.card h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem !important;
  font-weight: 600;
  line-height: 1.2;
  color: #00055d;
  font-family: Helvetica, Arial, 'Roboto', sans-serif;
}

.card h4 {
  font-size: 20px !important;
  font-family: Helvetica, Arial, 'Roboto', sans-serif;
}

.btn-primary {
  color: #fff !important;
  background-color: var(--global-landing-page-color) !important;
  border-color: var(--global-landing-page-color) !important;
}

.btn-primary:hover {
  background-color: #f5f5fe;
  border-color: #f5f5fe;
  color: #00055d;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: var(--global-landing-page-color);
  border-color: var(--global-landing-page-color);
}

.light.btn-primary {
  background-color: #f5f5fe;
  border-color: #f5f5fe;
  color: #00055d;
}

.light.btn-primary:hover {
  background-color: var(--global-landing-page-color);
  border-color: var(--global-landing-page-color);
  color: #fff;
}

.light-1.btn-primary {
  background-color: #e8f4f7;
  border-color: var(--global-landing-page-color);
  color: #00055d;
  border: 1px dashed var(--global-landing-page-color);
}

.light-1.btn-primary:hover {
  background-color: var(--global-landing-page-color);
  border-color: #e8f4f7;
  color: #00055d;
}

.btn-sm,
.btn-group-sm > .btn {
  font-size: 0.813rem !important;
  padding: 5px 12px;
}

.map {
  background: #f4f4f4;
  min-height: 350px;
}

.nav-pills .nav-link .bg-samcast i {
  color: #fff !important;
}

.nav-pills .nav-link.active .bg-samcast i {
  color: var(--global-landing-page-color) !important;
}

.sam-drop span i {
  font-size: 36px;
  color: var(--global-landing-page-color) !important;
}

.plan-samcast {
  text-align: center;
    padding: 0px 20px 20px 20px;
    width: 220px;
    height: 220px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
}

.is-error{
  border-color:#d9534f !important;
}
.plan-samcast i {
  font-size: 50px;
  color: #00055d;
  margin: 20px 0px;
  display: block;
}
.plan-samcast img {
  height: 36px;
  max-width: 80%;
}
.plan-samcast h5 {
  margin-top: 15px;
}

.years-select {
  align-items: center !important;
  border: 1px solid;
  border-radius: 3px;
}
.plan-samcast p {
  font-size: 16px;
  color: #00055d;
  margin-bottom: 0px;
}

.plan-samcast .company-img {
   height:33%;
   padding: 20px 0 0 0;
}
.plan-samcast .company-img img{
 max-width: 100%;
  max-height: 40px;
}

.expand-checkbox-label {
  font-size: 20px;
  font-weight: bold;
}

.most-least-label {
  font-size: 20px;
  font-weight: bold;
}
.most-least-item {
    border-radius: 3px !important;
}

.contacts{
  padding-left: 0 !important;
}
.contacts .img_cont {
  width: 40px;
  margin-right: 5px;
}

.contacts .img_cont img {
  width: 100%;
}

.contacts li {
  padding: 5px 0 !important;
}

.contacts li.active{
  margin-left: -15px !important;
}
.contacts li.active .bd-highlight {
  padding-top: 10px;
  padding-left: 15px;
}

.user_info {
  font-size: 14px;
  color: #00055d;
}

.user_info span {
  font-size: 16px;
  color: #00055d;
  font-weight: 500;
  word-wrap: break-word;
  overflow-wrap: anywhere;
}

.nav-link.active .user_info span {
  color: #fff;
}

.user_info p {
  font-size: 13px;
  color: #00055d;
  line-height: 14px;
  margin-bottom: 0px;
}

.nav-link.active .user_info p {
  color: #fff;
}

.validation-error {
  color: #d9534f;
  padding-left: 5px;
}
.status-icon{
  float: right;
  font-size: 27px !important;
  position: absolute;
  right: -10px;
  top: -14px;
}
.status-icon-error{
  color: #d9534f !important;
}
.status-icon-progress{
  color: #5bc0de !important;
}
.status-icon-success{
  color: #5cb85c !important;
}
.status-label{
  color: #fff;
  padding: 5px;
}
.status-label-error{
  background-color: #d9534f;
}
.status-label-progress {
  background-color: #5bc0de;
}
.status-label-success {
  background-color: #5cb85c;
}

.actions {
  margin: 0 5px;
  position: relative;
  right: 0;
  padding-right: 25px;
}

.actions > * {
  margin: auto 4px;
}

.actions i.disabled {
  cursor: default;
  color: #858585;
}

.nav-link.active .actions i:not(.disabled) {
  color: #fff !important;
}

.actions > i {
  cursor: pointer;
  color: var(--global-landing-page-color);
}

.sub-text {
  padding: 0 4px;
}

.form-control:disabled,
.form-control[readonly],
[class^='form-check']:disabled,
[class^='form-check'][readonly],
.btn:disabled,
.btn[readonly]
{
  cursor: not-allowed !important;
}


.MyLoader_overlay {
  width: 100% !important;
}

.no-data {
  position: relative;
  left: 20px;
  top: 20px;
}

.scrollbar {
  float: left;

  overflow-y: scroll;
  margin-bottom: 25px;
}

#style-1::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background-color: var(--global-landing-page-color);
}

#style-1::-webkit-scrollbar {
  width: 5px;
  background-color: var(--global-landing-page-color);
}

#style-1::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #05778e;
}

#controlled-tab-example-tab-segment_errors,
#controlled-tab-example-tab-activation_errors,
#controlled-tab-example-tab-attribution_errors {
  color: #d9534f !important;
}
.card-min-height {
  min-height: 250px !important;
}

.react-bootstrap-table table {
  table-layout: auto !important;
}
.react-bootstrap-table-pagination {
  font-size: 13px;
}

.react-bootstrap-table-pagination-total {
  margin-left: 10px;
}

.react-bs-table-sizePerPage-dropdown .dropdown-toggle,
.react-bs-table-sizePerPage-dropdown .dropdown-toggle:hover,
.react-bs-table-sizePerPage-dropdown .dropdown-toggle:focus,
.react-bs-table-sizePerPage-dropdown.show .dropdown-toggle,
.react-bs-table-sizePerPage-dropdown.show .dropdown-toggle:hover,
.react-bs-table-sizePerPage-dropdown.show .dropdown-toggle:focus {
  background: transparent;
  color: inherit;
  border-radius: 0 !important;
  box-shadow: none !important;
  border: none;
}

.react-bs-table-no-data {
  color: #7e7e7e;
}
.table td {
  font-size: 15px;
}
.table th {
  font-size: 18px;
  font-weight: 500;
}

input[type="date"]:before {
  color: lightgrey;
  content: attr(placeholder) !important;
  margin-right: 0.5em;
}
input[type="date"]:focus:before {
  content: '' !important;
}

.to-text{
  margin: 11px 3px !important;
}

.float-left{
  float:left !important;
}

/*DASHBOARD STATUS/GROUP/USER SELECT CONTROLS*/
.dashboard-select-controls {
  width: 20rem;
  height: 2.8rem;
}
.dashboard-select-controls .k-input-value-text {
  text-align: left;
  font-family: Helvetica, Arial, "Roboto", sans-serif;
}
.dashboard-select-controls{
  border-radius: .5rem !important;
}

/*DASHBOARD TABLE*/
.segments-table {
  width:100%;
  font-family: Helvetica, Arial, 'Roboto', sans-serif;
}
.segments-table th:nth-child(1) {
  width:3rem;
}
.segments-table th:nth-child(2) {
  width:35%;
  overflow-x:auto;
}
.segments-table th:nth-child(3) {
  width:10%;
}
.segments-table th:nth-child(4) {
  width:10%;
}
.segments-table th:nth-child(5) {
  width:10%;
}
.segments-table th:nth-child(6) {
  width:10%;
}
.segments-table th:nth-child(7) {
  width:7%;
}
.segments-table th:nth-child(8) {
  width:10%;
}
.segments-table th:nth-child(9) {
  width:10%;
  text-align:center
}
.segments-table th:nth-child(10) {
  text-align:center
}
.segments-table td:nth-child(9) {
  text-align:center;
}

.segments-table td strong a{
  text-decoration: none !important;
}
/* Roles.js css*/
.role-pre-populate-section{
  position: relative;
  top:-31px;
}

.sort-icon{
  left: 3px;
  position: relative;
  cursor: pointer;
}
.sort-icon-up{
  top: 3px;
}

.sort-icon-down{
  bottom: 3px;
}

.remembered-password{
  padding: 10px 20px;
  font-size: 14px;
}

.remembered-password a{
  color:#3498db !important;
}

.sign-out{
  cursor: pointer !important;
}

.hidden{
  display: none !important;
}
small{
  display: block;
}

#menu > li.have-expand:not(.mm-active-new)> a, #menu > li.have-expand:not(.mm-active-new) > a i{
  background: none ;
  color: white;
}
#menu > li.mm-active-new > a, #menu > li.mm-active-new > a i{
  background: #fff !important;
  color: var(--global-landing-page-color) !important;
  border-radius: 60px;
}
.note-popover {
  display: none; }

.justify-content-end{margin-top: 35px;position:absolute;right: 60px;}
.useNavigate .pagination{
float:right;
margin-right:40px;
margin-top:10px;
}
.segmentation-dashboard-table .btn-xs{
margin-right:5px;
}
.segments-table th:hover {
    cursor: pointer;
}

.contact-us-fullscreen{
  margin-left:100px!important;
  margin-right:50px!important;
}
#contact-us-footer{
  background-color:white;
  padding:10px;
}
#contact-us-footer .links{
  display:flex;
  align-items:center;
  justify-content:center;
}
.segmentation-pagination{
float:right;
margin-top: .5rem;
margin-right: 3rem;
}
.segment-search-box{
margin-top:50px;
}