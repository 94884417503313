.admin-billing .input-group {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: start;
    /*width: fit-content;*/
}
.admin-billing .input-group .btn.btn-primary.searchBtn{
    margin: 0 0 0 8px !important;
    font-size: 14px;
    padding: 6px 10px 6px 10px !important;
}
.admin-billing .btn.btn-primary.float-right{
    padding: 6px 10px 6px 10px !important;
}
.admin-billing .form-group label{
    color: rgb(33, 37, 41);
}
.admin-billing .form-control.form-control-custom,
.admin-billing .input-daterange-datepicker{
    height: fit-content;
    /*width: fit-content;*/
    border: solid 1px #6e6e6e !important;
    font-size: 14px;
}
.admin-billing .col-6 .form-group{
    padding-left: 15px;
}

.admin-billing .card-title{
    font-size: 16px;
    font-family: Helvetica, Arial, 'Roboto', sans-serif;
    color: #00424D;
    font-weight: bold;
}
.admin-billing  .input-fields a{
    font-size: 14px;
}
.admin-billing #pageDropDown{
    font-size: 12px;
    border: solid 1px rgb(222, 226, 230);
    padding: 6px 10px 6px 10px !important;
    border-radius: 5px !important;
}
.admin-billing .dropdown-menu{
    font-size: 12px;
    right: auto !important;
    left: 50px !important;
    bottom: -10px !important;
}
.admin-billing th.sortable{
    font-size: 16px;
}
.container-fluid.admin-billing .react-bootstrap-table{
    overflow-x: auto;
    width: 100%;
}
.admin-billing input{
    border-radius: 5px !important;
}
.admin-billing select{
    border-radius: 5px !important;
}