.splash-page-background {
    /*background-image: url("../assets/img/landingPageBackground.jpg");*/
    /*background-image: url("../assets/img/landingPageDesk.jpg");*/
    /*background-color: #00424d;*/
    /*background-color: #F5F5F5;*/
    background: linear-gradient(0deg, #ebebeb 0%, #ebebeb 0%, rgba(71,108,117,1) 100%);
    background-size: cover;
    min-height: 100vh;
    max-height: 100vh;
    min-width: 100%;
    position: fixed;
    background-position: center;
    padding: 1rem;

    display: flex;
    flex-direction: column;
}

.splash-page-logo-div {
    display: flex;
    flex-direction: column;
}

.splash-page-logo {
    /*height: 5rem;*/
    max-width: 100%;
}

.splash-page-logo-div {
    width: 12rem;
}

.splash-page-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.splash-page-main-text {
    color: white;
    /*font-size: 2.5rem;*/
    position: relative;
    /*top: 15vh;*/
    top: 7vh;
    left: 15vh;
    /*width: 40rem;*/
    font-weight: 600;

    width: 80vh;
    font-size: 50px;
}

.login-dialog-container {
    position: relative;
    /*left: -13rem;*/
    /*right:25vh;*/
    top: 5vh;
    /*left: 80vh;*/
    /*right: 50vh;*/
}

.semcasting-logo-container {
    position: absolute;
    width: 8%;
    max-width: 100%;
    top: 70%;
    right: 3%;
}

.splash-page-background .splash-page-main-text-div .splash-page-links-div-center .splash-page-link-button,
.splash-page-background .splash-page-header .splash-page-links-div .splash-page-link-button,
.splash-page-header .splash-page-links-div .splash-page-link-button {
    height: 2.5rem;
    margin: 0.5rem;

    background-color: transparent;
    border-color: transparent;

    /*background-color: #10191e;*/
    /*background-color: #00424d;*/
    color: white;
}

.splash-page-background .splash-page-main-text-div .splash-page-links-div-center .splash-page-link-button .k-button-text,
.splash-page-background .splash-page-header .splash-page-links-div .splash-page-link-button .k-button-text,
.splash-page-header .splash-page-links-div .splash-page-link-button {
    font-size: 1.1rem;
}

.splash-page-link-button:focus,
.splash-page-link-button:active,
.splash-page-link-button:hover {
    outline:none;
}

.splash-page-link {
    text-decoration: none;
}

.splash-page-links-div-center {
    align-self: center;
}


.splash-page-semcasting-link:link,
.splash-page-semcasting-link:visited,
/*.splash-page-semcasting-link:hover,*/
.splash-page-semcasting-link:active {
    color: white;
    text-decoration: none;
}

.splash-page-semcasting-link:hover {
    color: lightgrey;
}

.splash-page-semcasting-link {
    font-size: 12px;
    /*position: relative;*/
    /*left: 0.5rem;*/
    align-self: center;
}

.splash-page-main-text-div {
    display: flex;
    flex-direction: row;
    /*align-self: center;*/
    height: 100vh;
    /*justify-content: center;*/
    padding-bottom: 30vh;
    justify-content: center;

    padding-top: 5vh;
}

.splash-page-outer-container {
    display: flex;
    flex-direction: row;
}

.splash-page-outer-container .splash-page-dialog-button {
    width: 100%;
    margin: 0rem;
    color: white;
    border: none;
    font-family: 'Nunito';
    border-radius: 0.5rem;
    height: 2.5rem;
    padding: 0.5rem 1rem;
    background-color: #10191e;
}

.splash-page-outer-container .splash-page-dialog-button:hover {
    width: 100%;
    margin: 0rem;
    color: white;
    border: none;
    font-family: 'Nunito';
    border-radius: 0.5rem;
    height: 2.5rem;
    padding: 0.5rem 1rem;
    background-color: #314048;
}

.semcasting-logo {
    width: 100%;
    height: auto;
}

.login-dialog-welcome-text {
    font-family: 'Nunito', sans-serif;
    font-weight: 600;
    font-size: 1.4rem;
    color: white;
}

/*.forgot-password-link {*/
/*    display: inline;*/
/*    width: fit-content;*/
/*    padding-top: 5px;*/
/*    text-decoration: none;*/
/*}*/

.no-account-link {
    display: inline;
    /*width: fit-content;*/
    padding-top: 5px;
    text-decoration: none;
    position: relative;
    top: 1.2rem;
    left: 16.6rem;
    font-size: 14px;
    color: whitesmoke;
}

.landing-page-logo-wrapper {
    flex-direction: column;
    padding-left: 5rem;
    padding-right: 5rem;
    padding-bottom: 0.3rem;
    /*background: radial-gradient(circle, rgb(109 174 204) 0%, rgba(16,25,30,1) 80%);*/
}

.landing-page-logo {
    /*height: 4.3rem;*/
    border-radius: 0.5rem;
    padding: 0.5rem;
}

input::-webkit-credentials-auto-fill-button {
    visibility: hidden;
    display: none !important;
    pointer-events: none;
    position: absolute;
    right: 0;
}

@media screen and (max-width: 1360px) {
    .splash-page-main-text {
        width: 50vh;
    }
}

@media screen and (max-width: 1200px) {
    .splash-page-main-text {
        /*left: 5vh;*/
        width: 40vh;
    }
}

@media screen and (max-width: 1060px) {
    .splash-page-main-text {
        font-size: 32px;
        width: 25vh;
    }
}

@media screen and (max-width: 1600px) {
    .splash-page-main-text {
        display: none;
    }

    .login-dialog-container {
        left: 0vh;
    }

    .splash-page-main-text-div {
        justify-content: center;
    }

    .semcasting-logo-container {
        /*left: 30vh;*/
    }
}