/*.k-checkbox.file-browse-select {*/
/*    border-color: grey;*/
/*}*/

.file-browser-header-info {
    display: flex;
    flex-direction: column;
}

.file-browser-header-item {
    padding-bottom: 0.4rem;
}

.file-browser-dialog .k-dialog .k-dialog-content {
    max-height: 50vh;
}