.permissions-users-grid-toolbar-buttons {
    display: flex;
    flex-direction: row;
    /*justify-content: end;*/
    width: 100%;
}

.perm-users-grid-toolbar-container {
    width: 100%;
}

.section-card .k-tabstrip .k-content .button-no-left-margin {
    margin-left: 0rem;
}

.dialog-standard .k-dialog .k-dialog-buttongroup .perm-user-suspend-user-button {
    width: 8rem;
}

.dialog-standard .k-dialog .k-dialog-buttongroup .perm-user-reset-pass-button {
    width: 8rem;
}

.permissions-user-dialog-suspended-container {
    display: flex;
    gap: 15px;
    /*flex-direction: column;*/
    /*justify-content: center;*/
    /*margin-left: 1rem;*/
    /*margin-bottom: 0.5rem;*/
}

.permissions-user-dialog-suspended-text {
    /*margin: 0 0 0 1rem;*/
    background-color: orange;
    color: white;
    border-radius: 0.5rem;

    margin: auto;
    padding: 0.37rem 0.5rem 0.3rem;
    /*position: relative;*/
    /*top: 0.2rem;*/
}

.perm-users-form-field {
    margin-bottom: 0.5rem;
}

.perm-users-dialog .k-dialog .k-dialog-content .perm-users-form-container .k-grid-header {
    background-color: var(--global-landing-page-color);
}

.perm-users-dialog .k-dialog .k-dialog-content .perm-users-form-container
.k-grid-header .k-header {
    background-color: var(--global-landing-page-color);
    color: white;
}

.perm-users-dialog .k-dialog .k-dialog-content .perm-users-form-container {
    margin-bottom: 1rem;
}

.perm-users-dialog .k-dialog .k-dialog-content .k-dialog-buttongroup {
    border-color: rgba(0,0,0,0.08);
    padding-bottom: 0rem;
}

.perm-users-dialog .k-dialog .k-dialog-content .k-dialog-buttongroup .perm-users-dialog-buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    padding-bottom: 0.5rem;
}

.admin-tabstrip-container-card .k-tabstrip .k-content .users-permissions-datagrid-container .k-grid-container {
    max-height: 55vh;
}

.admin-tabstrip-container-card .k-tabstrip .k-content .users-permissions-datagrid-container .k-filter-row .k-filtercell .k-filtercell-operator {
    display: none;
}

.perm-users-dialog .k-dialog .k-dialog-content .perm-users-form-container .perm-users-form-table-section .k-grid-container {
    overflow-y: auto;
    max-height: 35vh;
}

.perm-users-dialog .k-dialog {
    max-height: 100vh;
}

.permissions-edit-user-roles-dropdown .not-a-member-dropdown-option {
    color: darkgrey;
    font-style: italic;
}

.perm-users-form-container .permissions-new-user-default-group-dropdown-container {
    margin-top: 1rem;
}

.perm-users-form-container .permissions-new-user-default-group-dropdown-container .no-default-group-selected {
    color: darkgrey;
    font-style: italic;
}

.perm-users-dialog .k-dialog .k-dialog-content .k-dialog-buttongroup {
    padding-left: 0rem;
    padding-right: 0rem;
    padding-top: 0.5rem;
}

.perm-users-dialog .k-dialog .k-dialog-content .k-dialog-buttongroup .perm-dialog-delete-button {
    margin-left: 0rem;
}

.perm-users-dialog .k-dialog .k-dialog-content {
    padding-bottom: 0rem;
}