

/*********** Table Text Align  **********/
.right{
    text-align: right;
  }

  .k-pdf-export .table-responsive.business-employees .table thead{
    background-color: #ffffff;
  }

  .k-pdf-export .table-responsive.business-employees .table tbody tr:nth-child(odd){
    background-color:#F9F9F9;
  }