.k-dropdownlist.identity-connection-settings,
.k-dropdownlist.identity-scope,
.k-dropdownlist.identity-summarization,
.k-radio-list.k-list-vertical,
.k-dropdownlist.identity-idxtag,.k-daterangepicker.identity-date-picker,
.identity-connection-label,.identity-checkbox,.identity-create-segment-label,
.identity-create-segment-info-message,.spotlight-automation-outer-wrapper
{
    margin-left: 13%;
}


.k-dropdownlist.identity-connection-settings {
    width:auto;
    height: 2.2rem;
    border-color: var(--global-landing-page-color);
}

.k-dropdownlist.identity-scope {
    width:15rem;
    height: 2.2rem;
    border-color: var(--global-landing-page-color);
}
.k-dropdownlist.identity-summarization {
    width:15rem;
    height: 2.2rem;
    border-color: var(--global-landing-page-color);
}
.k-radio-list.k-list-vertical.identity-report-from{
    width:15rem;
    height: 2.2rem;
}
.k-dropdownlist.identity-idxtag {
    width:auto;
    min-width: 15rem;
    height: 2.2rem;
    border-color: var(--global-landing-page-color);
}
.identity-idxtag-overlay{
    align-self: flex-start;
    width: 30%;
}
.identity-controls-content{
    padding-left: 70px;
}

.identity-layout {
    margin-left: 15%;
}

.k-daterangepicker.identity-date-picker {
    width: 80%;
}

.identity-connection-label {
    width: 30%;
    padding-top: 2.5%;
}
.spotlight-report-tooltip-wrapper{
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.identity-checkbox-spacing {
    margin-right:5rem;
}

.identity-create-segment-label {
    padding-right: .5rem;
}

.identity-create-segment-info-message {
    width:15rem;
}
.identity-automate-header{
    padding: 5px;
    /*margin-left: 11%;*/

}
.identity-controls-content {
    padding-top:1rem;
    overflow: auto;
}
.identity-repeat-action-label{
    /*width: 45%;*/
    margin-left: 12%;
    margin-top: 10px;
}
.identity-report-from-wrapper{
    /*margin-left: 5%;*/
    width: fit-content;
    padding-bottom: 15px;
}
.identity-report-from{
    padding-top: 2.5%;
    margin-left: 12%;
}
.spotlight-automation-outer-wrapper{
    padding: 5px;
    border: 1px solid lightgrey;
    width: min-content;
}

.spotlight-automation-tooltip-wrapper{
    display: flex;
    flex-direction: row;
    align-items: baseline;
    width: fit-content;
    border-bottom: 1px solid lightgrey;
    justify-self: center;
}