.k-button.k-selected.selected-ownership {
    background-color: #476c75;
    color: #FFFFFF;
    border-color: #476c75;
}
.k-button.default-ownership {
    background-color: whitesmoke;
    color: black;
    /*border-color: whitesmoke;*/
}
