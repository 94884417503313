.targeting-full-container {
    display:flex;
    flex-direction: row;
    width: 100%;
}
.targeting-full-column-1 {
    flex-shrink: 0;
    flex-basis: 50%;
    padding-right: 2.5rem;
}
.targeting-full-column-2 {
    flex-shrink: 0;
    flex-basis: 50%;
    /*margin-left: 3rem;*/
    /*padding-right: 1rem;*/
}
.targeting-full-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 25rem;
}
.column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    width: 15rem;
}
@media only screen and (max-width: 1110px) {
    .targeting-full-container {
        flex-direction: column;
    }
    .box {
        margin: 0 0 1rem;
    }
    .targeting-selection-type-label {
        margin-top: 1rem;
    }
    .targeting-full-column-1 {
        padding-right: 0rem;
    }
}
.labels {
    line-height: 20px;
    color: rgba(0, 0, 0, 0.54);
    padding-bottom: .2rem;
    font-size: 16px;
}
.section-label {
    padding-top: 1rem;
    padding-left: 1rem;
    color: #476c75;
    font-size: 1.1rem;
    font-weight: bold;
}

.body_background .k-card-body .k-form .column .targeting-full-onboarding-button-group {
    border-radius: 0.25rem;
    height: 2.2rem;
}

.targeting-full-legislative-div {
    padding-top: 1rem;
}
