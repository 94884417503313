.unknown-filetype-dialog {
    overflow: auto;
}

.unknown-filetype-container {
    display:flex;
    flex-direction: row;
    width: 100%;
    font-family: Helvetica, Arial, 'Roboto', sans-serif;
}

.unknown-filetype-instructions {
    font-size: 1rem;
}

.unknown-filetype-templates-text {
    padding-top: 1rem;
}

.unknown-filetype-notification {
    background-color: #fff3cd;
    padding: 1rem;
}

.unknown-filetype-list-items li {
    margin-left: 1.5rem;
    list-style-type: square;
    font-size: 1rem;
    margin-bottom: .5rem;
}
