.app-bar-tooltip {
    box-shadow: 0 10px 8px 0 rgba(0,0,0,0.07), 0 4px 5px 0 rgba(0,0,0,0.09);
    & .k-tooltip {
        /*background-color: white;*/
        /*color: black;*/
        max-width: 260px;
        text-align: center;
    }

    & .k-callout {
        color: white;
    }
}