/*----------- Pinterest Grant Access Button -----------*/
.form-check.grant-access {
    padding-left: 0;
}

.form-check.grant-access .fa.fa-check-circle {
    color: #5cb85c;
}

.form-check.grant-access .fa.fa-times-circle {
    color: #d9534f;
}

.form-check.grant-access i {
    font-size: 25px !important;
}


/*----------- Label Styles -----------*/
.form-label {
    color: #585858;
}

label {
    color: #585858;
    font-family: Helvetica, Arial, "Roboto", sans-serif;
}

/*----------- Text Input Fields -----------*/
.act-seg.form-control {
    height: 2rem;
    width:auto;
    border-radius: 0.3rem;
    border-bottom: 1px solid #f0f1f5;
    border-left-color: transparent;
    border-right-color: transparent;
    border-top-color: transparent;
    border-width: 1.5px;
    background-color: whitesmoke;
}

.input-group.w-50.gap-5.date-picker{
    display: flex;
    flex-flow: row;
}
.k-animation-container.k-animation-container-relative.k-calendar-container.k-group.k-reset.k-animation-container-shown{
    width: inherit;
    /*padding: 20px 20px 20px 20px;*/
}
.input-group .w-25 {
    height: 1.5rem;
}


/*----------- Radio Buttons Temporary-----------*/
.k-radio {
    border-color: #bbbbbb !important;
}

/*----------- Checkbox Temporary -----------*/
.k-checkbox {
    border-color: #bbbbbb !important;
}


/*----------- Dropdown List Form Check Styles -----------*/
.form-check-dropdown {
    position: relative !important;
    display: block !important;
    padding-left: 0 !important;
}
/***************/
.activate-segment-content .subtext-info{
    font-size: small;
    width: 25%;
}
/*----------- Direct Mail Manage Connection -----------*/
.activate-segment-content .nav-link-to-admin .button-submit{
    border-radius: 8px;
    margin-left: 30px;
    height: 35px;
}
.activate-segment-content .ftp-dropdown{
    flex-direction: row;
}

.k-listbox .k-list-scroller .k-list.k-list-md{
    height: auto;
    max-height: 100%;
}

.activate-segment-content #deal_id_wrapper{
width: 29% !important;
}

.activate-segment-content .k-list-scroller.k-selectable{
    border-color: rgba(0, 0, 0, 0.5);
}

.tree-component-wrapper{
    flex-direction: row;
    display: flex;
}