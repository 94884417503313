@import url('https://fonts.googleapis.com/css?family=Josefin+Sans&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');


.deznav {
  background-color: var(--global-landing-page-color); }

.nav-text {
  color: white; }

.nav-text-happy {
  color: var(--global-landing-page-color);
}

.k-appbar-light {
background-color:#ffffff !important; }

.k-appbar {
box-shadow: none !important;}
.k-button-solid-primary {
  border-color: rgba(0, 0, 0, 0.05) !important; }

.k-window-titlebar.k-dialog-titlebar {
  background-color: var(--global-landing-page-color); }

.k-window-title.k-dialog-title {
  font-size: 1.4rem }

.k-window-title {
  font-size: 1.4rem }

.k-button.k-button-solid.dialog-cancel {
  background-color: #FFFFFF;
  border-color: lightgrey;
  border-radius: .5rem;
  height: var(--global-element-height);
  color: black; }

/*.k-button.dialog-cancel:hover {*/
/*  background-color: #FFFFFF;*/
/*  height: 2.7rem;*/
/*  color: black; }*/

.k-button.k-button-solid.dialog-save {
  background-color: var(--global-landing-page-color);
  color: #FFFFFF;
  min-width: 4.2rem;
  height: var(--global-element-height);
  border-radius: .5rem;
  border-color: var(--global-landing-page-color); }

/*.k-button.dialog-save:hover {*/
/*  background-color: #00424D ; }*/

input::placeholder {
  color: lightgrey;
  font-style: italic }

textarea::placeholder {
  color: lightgrey !important;
  font-style: italic }

.k-radio:checked,
.k-radio.k-checked {
  border-color: var(--global-landing-page-color) !important;
  color: var(--global-landing-page-color) !important;
  background-color: var(--global-landing-page-color) !important;
}

.k-checkbox:checked,
.k-checkbox.k-checked {
  border-color: var(--global-landing-page-color) !important;
  color: white;
  background-color: var(--global-landing-page-color) !important;
}

.k-list-item.k-selected, .k-selected.k-list-optionlabel {
  color: black;
  background-color: #E0E0E0 !important;
}

.k-button.k-button-solid.save-changes-button {
  background-color: var(--global-landing-page-color);
  color: #FFFFFF;
  height: var(--global-element-height);
  border-color: #FFFFFF;
}
.k-button.k-button-solid.cancel-button {
  background-color: #FFFFFF;
  color: black;
  height: var(--global-element-height);
  border-color: black;
}
.k-button.k-button-solid.save-button {
  background-color: var(--global-landing-page-color);
  color: #FFFFFF;
  height: var(--global-element-height);
  border-color: #FFFFFF;
}

.k-notification-container .k-notification-wrap .k-notification-content,
.k-notification-container .k-notification-wrap {
  /*font-size: 15px !important;*/
  /*align-items: center !important;*/
  /*height: 30px !important;*/
  font-size: 15px;
  align-items: center;
  height: 30px;
}

#root .k-notification-group {
  z-index: 999;
}

/*                NEW GLOBAL STYLES                */

/*List of site components styles are defined for:*/

/*Buttons----------------------------------------*/
/*Field Labels-----------------------------------*/
/*Text Fields------------------------------------*/
/*Dropdowns--------------------------------------*/
/*Button Groups----------------------------------*/
/*Radio Buttons----------------------------------*/
/*Page Sections/Cards----------------------------*/
/*Headers----------------------------------------*/
/*Dialogs----------------------------------------*/
/*Checkboxes-------------------------------------*/
/*Overlay----------------------------------------*/
/*FieldWrapper-----------------------------------*/
/*FieldSets--------------------------------------*/


/*----------- Variables -----------*/

:root {
  --global-border-radius: 0.25rem;
  --global-element-height: 2.2rem;
  --global-font-family: Helvetica, Arial, "Roboto", sans-serif;

  /*--global-landing-page-color: #1d3557;*/
  /*--global-footer-color: #457B9D;*/
  --global-landing-page-color: #00424D;
  --global-footer-color: #476c75;
}

/*var(--global-border-radius);*/
/*var(--global-element-height);*/
/*var(--global-font-family);*/

/*----------- Buttons -----------*/

/* General styles for all buttons */
.button-standard,
.section-card .button-standard,
.dialog-standard .button-standard {
  font-family: var(--global-font-family);
  border-radius: var(--global-border-radius);
  height: var(--global-element-height);
  margin-left: 0.5rem;
  width: 6rem;
  /*padding: .5rem 1rem;*/
}

/* Standard green button */
/* Use for save/submit actions */
.button-submit,
.section-card .button-submit,
.dialog-standard .button-submit {
  background-color: var(--global-landing-page-color);
  color: white;
  border: none;
}

.button-submit:hover,
.section-card .button-submit:hover,
.dialog-standard .button-submit:hover {
  background-color: var(--global-landing-page-color);
  border-color: var(--global-landing-page-color);
}

/* Inverse color buttons */
/* Use for cancel buttons */
.button-white,
.section-card .button-white,
.dialog-standard .button-white {
  background-color: #FFFFFF;
  color: var(--global-landing-page-color);
  border: 1px solid var(--global-landing-page-color);
}

.button-white:hover,
.section-card .button-white:hover,
.dialog-standard .button-white:hover {
  background-color: var(--global-landing-page-color);
  border-color: var(--global-landing-page-color);
  color: #fff;
}

/* Red button */
/* Use for delete actions */
.button-red,
.section-card .button-red,
.dialog-standard .button-red {
  background-color: #d9534f;
  color: white;
  border: none;
}

.button-red:hover,
.section-card .button-red:hover,
.dialog-standard .button-red:hover {
  color: #fff;
  background-color: #c9302c;
  border-color: #c9302c;
}

/*----------- Field Labels -----------*/

.field-label,
.section-card .field-label,
.dialog-standard .field-label {
  font-family: var(--global-font-family);
  /*font-size: 0.9rem;*/
  /*padding-bottom: 0.2rem;*/
  /*color: rgba(0, 0, 0, 0.65);*/
  color: #424242;
  line-height: 20px;
  padding-bottom: 0.2rem;
  /*font-size: 16px;*/
  font-size: 14px;
}


.field-label-large,
.section-card .field-label-large,
.dialog-standard .field-label-large {
  /*color: #00424d;*/
  /*font-family: var(--global-font-family);*/
  /*font-weight: 550;*/
  /*font-size: 1.1rem;*/

  /*padding-top: 1rem;*/
  /*padding-left: 1rem;*/
  color: #476c75;
  font-size: 18px;
  font-weight: bold;
  font-family: Helvetica, Arial, 'Roboto', sans-serif;
  padding-bottom: 24px;
}

/*----------- Text Fields -----------*/

.text-field,
.section-card .text-field,
.dialog-standard .text-field {
  font-family: var(--global-font-family);
  background-color: whitesmoke;
  /*line-height: 1.75rem;*/
  height: var(--global-element-height);
  font-size: 0.9rem;
  font-weight: 400;
  border-radius: var(--global-border-radius);
  border-style: solid;
  border-width: 0.1rem;
  border-color: rgb(0 0 0 / 8%);
}

.text-field:focus,
.section-card .text-field:focus,
.dialog-standard .text-field:focus {
  outline: none;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 0px 2px;
}

/*bring over form-control styles */
.text-field-formik,
.section-card .text-field-formik,
.dialog-standard .text-field-formik {
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.9rem;
  font-weight: 400;
  /*appearance: none;*/
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  /*border: 1px solid #f0f1f5 !important;*/
  color: #6e6e6e;
  margin-bottom: 0;
  padding: 8px 16px;
}

/*----------- Dropdowns/ComboBox -----------*/

/*Use for Kendo Dropdown component*/
.dropdown-standard,
.section-card .dropdown-standard,
.dialog-standard .dropdown-standard,
.about-audience-card .dropdown-standard {
  background-color: whitesmoke;
  line-height: 1.75rem;
  font-family: var(--global-font-family);
  border-radius: var(--global-border-radius);
  border-bottom: var(--global-landing-page-color);
  /*height: 2.68rem;*/
  /*height: 2.39rem;*/
  height: var(--global-element-height);
  border-style: solid;
  border-width: 0.1rem;
  border-color: rgb(0 0 0 / 8%);
}

/*Use for <select> dropdowns*/
.select-dropdown,
.section-card .select-dropdown,
.dialog-standard .select-dropdown {
  width: 100%;
  text-align: left;
  padding-left: 0.5rem;
  background-color: whitesmoke;
  /*line-height: 1.75rem;*/
  font-family: var(--global-font-family);
  border-radius: var(--global-border-radius);
  border-bottom: var(--global-landing-page-color);
  /*height: 2.68rem;*/
  /*height: 2.39rem;*/
  height: var(--global-element-height);
  border-style: solid !important;
  border-width: 0.1rem !important;
  border-color: rgb(0 0 0 / 8%) !important;
  font-size: 0.9rem !important;
}

.select-dropdown:focus ,
.section-card .select-dropdown:focus,
.dialog-standard .select-dropdown:focus {
  outline: none !important;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 0px 2px !important;
  border: none !important;
}


/*Need to figure out how to target selected dropdown items within expanded dropdown list to apply styles*/
.dropdown-selected {

}

.section-card .combobox-standard {
  border-radius: var(--global-border-radius);
  height: var(--global-element-height);
}








/*----------- Button Groups -----------*/

.button-group-button,
.section-card .button-group-button {
  background-color: #FFFFFF;
  color: var(--global-landing-page-color);
  border: 1px solid var(--global-landing-page-color);
}

/*is :active the correct way to target a selected button? or is it with the "aria-checked" class?*/
.button-group-button:active {
  background-color: var(--global-landing-page-color);
  border-color: var(--global-landing-page-color);
  color: #fff;
}

/*----------- Radio Buttons -----------*/

/*How do we target selected radio button? In chrome devconsole only see the tabindex change when I click button */
.radio-button {
  border-color: #bbbbbb;
}

.radio-button:checked {
  background-color: var(--global-landing-page-color);
  color: white;
}


/*----------- Page Sections/Cards -----------*/

.section-card {
  border-width: 0.18rem;
  border-color: rgba(0, 0, 0, 0.08);
  border-style: solid;
  /*box-shadow: 0 3px 10px 0 rgb(99 118 129 / 30%);*/
  /*padding: 0rem 1rem 1rem 1rem;*/

  padding: 1rem;

  background-color: white;
  border-radius: var(--global-border-radius);
  /*margin: 0rem 1rem 1rem 1rem;*/
}

/*Use to add box shadow */

.box-shadow-standard {
  box-shadow: 0 3px 10px 0 rgb(99 118 129 / 30%);
}

.section-card-no-shadow {
  border-width: 0.18rem;
  border-color: rgba(0, 0, 0, 0.08);
  border-style: solid;
  /*box-shadow: 0 3px 10px 0 rgb(99 118 129 / 30%);*/
  /*padding: 0rem 1rem 1rem 1rem;*/

  padding: 1rem;

  background-color: white;
  border-radius: var(--global-border-radius);
  margin: 0rem 1rem 1rem 1rem;
}

.section-card-outer-margins {
  margin: 0rem 1rem 1rem 1rem;
}

.section-card-inner-margins {
  margin: 0rem 0rem 1rem 0rem;
}



/*----------- Headers -----------*/

/* Use h3 tag */
.header-primary,
.section-card .header-primary {
  color: var(--global-landing-page-color);
  font-family: var(--global-font-family);
  font-weight: 600;
  font-size: 1.4rem;
}

/* Use h4 tag */
.header-secondary,
.section-card .header-secondary {
  color: var(--global-landing-page-color);
  /*font-family: 'Josefin Sans', sans-serif;*/
  font-family: var(--global-font-family);
  font-weight: 600;
  font-size: 1.2rem;
}

/* Could use a class for general text */

/* Use h6 tag */
.header-tertiary,
.section-card .header-tertiary {
  font-size: 0.9rem;
  font-family: var(--global-font-family);
}

/*----------- Dialogs -----------*/

/* May need to use [.k-window-titlebar.k-dialog-titlebar] to target the kendo dialogs we use */
.dialog-title,
.k-window-titlebar.k-dialog-titlebar {
  background-color: var(--global-landing-page-color);
  color: white;
}

/* May need to use [.k-dialog-wrapper .k-dialog .k-dialog-content] to target the kendo dialogs we use */
.dialog-standard,
.k-dialog-wrapper .k-dialog .k-dialog-content {
  padding: 1rem;
}


.dialog-standard .k-dialog {
  border-radius: var(--global-border-radius);
}

.dialog-standard .k-dialog-titlebar {
  border-top-left-radius: var(--global-border-radius);
  border-top-right-radius: var(--global-border-radius);
}


/*----------- Checkboxes -----------*/

.checkbox-standard:active {
  background-color: var(--global-landing-page-color);
  color: white;
}

/*----------- Overlay -----------*/

.k-drawer-container .MyLoader_overlay {
  padding-left: 0rem;
}

/*----------- FieldWrapper -----------*/

.field-wrapper,
.section-card .k-form .field-wrapper {
  margin-top: 0rem;
}

.field-wrapper-formik {
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
}


/*----------- FieldSets -----------*/

/* Use this class for fieldsets. fieldsets used with kendo forms */

.fieldset {

}

/* Default kendo component class styles */

.k-form .k-form-buttons {
  margin-top: auto;
  padding: 0;
}

.k-floating-label-container input.k-input,
textarea.k-textarea {
  padding: 8px 16px;
}

.section-card .k-tabstrip-top .k-tabstrip-items-wrapper .k-tabstrip-items .k-active {
  border-bottom-color: transparent;
}

.section-card .k-tabstrip .k-tabstrip-left .k-tabstrip-items-wrapper .k-tabstrip-items .k-active {
  border-right-color: transparent;
  border-bottom-color: rgba(0,0,0,.08);
}

.section-card .k-tabstrip .k-tabstrip-items-wrapper .k-active,
.section-card .k-tabstrip .k-item .k-state-selected .k-tabstrip-items-wrapper .k-item:active {
  border-color: rgba(0,0,0,.08);
  color: #424242;
  background-color: #fff;
}

.section-card .k-tabstrip .k-active {
  display: block;
}

/* Miscellaneous */

/*Style for * (asterisk) used to signify required form fields*/
.required-field {
  color: var(--global-landing-page-color);
  font-weight: 900;
}

/*Style for message telling users that * signifies required form fields*/
.required-fields-dialog-message {
  margin-bottom: 0.5rem;
  font-style: italic;
  color: rgb(0 0 0 / 45%);
  position: relative;
  top: -0.5rem;
}

/*Style for spacing line. Is this unused?*/
.body_background .section-card .spacing-line {
  padding-top: 16px;
}

#root .Toastify .Toastify__toast-container {
  z-index: 10003
}

/*----------- Hints -----------*/

.hint-standard,
.section-card .hint-standard,
.dialog-standard .hint-standard {
  color: rgb(0 0 0 / 45%);
  font-size: 14px;
}

/*----------- Tooltips -----------*/

.section-card .tooltip-label-and-icon-wrapper,
.dialog-standard .tooltip-label-and-icon-wrapper,
.onboarding-content .tooltip-label-and-icon-wrapper,
.audience-design-content .tooltip-label-and-icon-wrapper{
  display: flex;
  flex-direction: row;
}

.section-card .tooltip-label-and-icon-wrapper .tooltip-question-icon,
.dialog-standard .tooltip-label-and-icon-wrapper .tooltip-question-icon,
.onboarding-content .tooltip-label-and-icon-wrapper .tooltip-question-icon,
.audience-design-content .tooltip-question-icon {
  padding-left: .2rem;
  position: relative;
  top: -0.2rem;
}
