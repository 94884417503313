.section-card .k-tabstrip .adx-gear-button .k-button {
    color: white;
    background-color: var(--global-landing-page-color);
}

.section-card .adx-grid .k-filtercell-operator {
    display: none;
}

.adx-dialog .adx-dialog-submit-button-div .button-submit {
    width: 9rem;
}

.section-card .adx-grid .k-master-row .adx-tag-gear-dropdown-div {
    vertical-align: middle;
    padding: 0px;
    text-align: center;
}

.section-card .adx-grid .k-toolbar .adx-grid-toolbar-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
}

.adx-dialog .k-dialog-content .adx-dialog-columns-container {
    display: flex;
    flex-direction: column;
}

.adx-dialog .k-dialog-content .adx-dialog-row-bottom {
    padding-top: 1rem;
}

.adx-dialog .k-dialog-content .adx-dialog-row-bottom .adx-save-to-group-div,
.adx-dialog .k-dialog-content .adx-dialog-row-bottom .adx-please-confirm-div {
    padding-top: 1rem;
}

.adx-dialog .k-dialog-content .adx-dialog-row-top {
    display: flex;
    flex-direction: row;
}

.adx-dialog .k-dialog-content .adx-dialog-row-bottom .adx-dialog-domain-tag-names-div,
.adx-dialog .k-dialog-content .adx-dialog-row-bottom .adx-dialog-group-checkbox-div {
    display: flex;
    flex-direction: row;
    flex-basis: 100%;
    justify-content: space-between;
}

.adx-dialog .k-dialog .k-dialog-content .adx-disclaimer-1,
.adx-dialog .k-dialog .k-dialog-content .adx-disclaimer-2 {
    background-color: #bdd8dd;
}

.adx-dialog .k-dialog .k-dialog-content .adx-disclaimers-div {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.adx-dialog .k-dialog .k-dialog-content .adx-form-field {
    margin-bottom: 1rem;
}

.adx-dialog .k-dialog .k-dialog-content .adx-disclaimer-1 {
    width: 100%;
    padding-bottom: 0rem;
}

.adx-dialog .k-dialog .k-dialog-content .adx-disclaimer-2 {
    width: 85%;
    padding-bottom: 0rem;
    margin-left: 1rem;
}

.adx-dialog .k-dialog .adx-dialog-submit-button-div {
    display: flex;
    flex-direction: row;
    justify-content: end;
}

.adx-dialog-save-group-hint {
    font-family: Helvetica, Arial, "Roboto", sans-serif;
    font-size: 12px;
    font-style: italic;
    color: gray;
}

.adx-dialog .k-dialog .adx-please-confirm-div .k-checkbox-label {
    font-weight: 600;
    font-size: 13px;
}

.adx-pixel-dialog .k-dialog .pixel-dialog-text-container {
    display: flex;
    flex-direction: column;
}

.adx-pixel-dialog .k-dialog .pixel-dialog-text-container .pixel-dialog-text {

}

.adx-pixel-dialog .k-dialog .pixel-dialog-text-container .pixel-dialog-text-1,
.adx-pixel-dialog .k-dialog .pixel-dialog-text-container .pixel-dialog-text-2 {
    margin-bottom: 1rem;
}

.adx-pixel-dialog .k-dialog .pixel-dialog-text-container .pixel-dialog-text-2 {
    background-color: #bdd8dd;
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-self: center;

    white-space: pre;
}

.adx-pixel-dialog .adx-pixel-dialog-button-container {
    display: flex;
    flex-direction: row;
    justify-content: end;
}

.adx-pixel-dialog .k-dialog .pixel-dialog-text-container .pixel-dialog-text-1,
.adx-pixel-dialog .k-dialog .pixel-dialog-text-container .pixel-dialog-text-3 {
    text-align: center;
}

.adx-pixel-dialog .pixel-dialog-text-3 .adx-gear-icon {
    height: 1.5rem;
}