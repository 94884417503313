.append-replace-container {
    width: 80%;
}

.append-replace-warning-msg {
    color: #00424d;
    margin-top: 2rem;
}

.append-replace-platform-image {
    height: 1.5rem;
    padding-right: 1.2rem;
}

.append-replace-current-segment {
    color: #198754;
    font-weight: bold;
}