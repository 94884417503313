.btn{
    border-radius: 4px !important;
    margin: 4px;
}

.segment-dashboard-content{
    display: inline-table;
    width:98%;
}

.k-drawer-content .segment-dashboard-content .segmentation-dashboard-card {
    overflow: visible;
}

.dashboard-app-bar-delete {
    position: absolute;
    float: right;
    display: block;
    z-index: 101;
    padding: 0 !important;
    right:0;
    margin-right:50px;
    margin-top: 18px;
}

#dashboard-app-bar {
    order: -1;
    position: absolute;
    float:left;
    display:block;
    z-index: 101;
    padding: 0 !important;
    margin-left: 37px;
    margin-top:18px;
    /*background-color: transparent;*/
}
#dashboard-app-bar .k-appbar{
    padding: 0;
    /*margin-top: 2px;*/
    background-color: transparent;
}
#dashboard-app-bar .k-appbar .k-appbar-section{
    /*padding: 0;*/
    padding-right: 5px;
    padding-bottom: 2px;
    padding-top: 2px;

}

.segment-dashboard-content .k-appbar-light{
    /*background-color: transparent !important;*/
    background-color: transparent !important;
}