.row .col-xl-12.col-lg-12.col-md-12.col-sm-12.prevent-split .card.prevent-split.zip5{
    box-shadow: none !important;
}
.row .card.prevent-split.top-states{
    box-shadow: none !important;
}
.shepherd-button-primary.shepherd-button{
    background: var(--global-landing-page-color);
    color: white;
}
.shepherd-button-primary.shepherd-button:hover {
    background-color: var(--global-landing-page-color) !important;
}
.shepherd-header{
    background-color: var(--global-landing-page-color) !important;
}
.shepherd-title{
    color: white !important;
}
.row.consumer-topcontextual{
    margin-bottom: 60px;
}

.card-body.consumer-dashboard-notes.prevent-split{
    padding-bottom: 180px;
}

.reports-display-content .indent-wrapper{
    padding-left: 20px;
}
.reports-display-content .indent-wrapper-logo{
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
#history-logo-img{
 position:relative;
}
#logo-history-dropdown{
    border-radius: 0.375rem;
    color: rgba(88, 88, 88, 88);
    font-family: Helvetica, Arial, 'Roboto', sans-serif;
    font-size: 16px;
}
.reports-display-content .include-exclude-outerwrapper{
    display: flex;
    flex-direction: row;
}

.reports-display-content .include-exclude-section-wrapper{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.edit-dialogue-labels .reset-btn.btn{
    background-color: #ffffff;
    color: rgba(88, 88, 88, 88);
    height: 24px;
    border-color: var(--global-footer-color);
    padding: 0px 4.5px 0px 4.5px;
    margin-left: 50px;
}
/*********************** Map Radio Control ****************** */
.card-body-graphs-map.prevent-split .map-style-radio-btn{
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
}
.card-body-graphs-map.prevent-split .map-style-radio-btn .k-radio-item{
    padding-top: 0;
}

/*********************** Edit Dialogue ****************** */
.reports-display-content .edit-dialogue-labels{
    /*border-bottom: 1px solid #dee2e6;*/
    margin-bottom: 5px;
    color: rgba(88, 88, 88, 88);
    font-size: 18px;
    padding-left: 0 !important;
}
/*********************** Color Drop Down ****************** */
.consumer-report-dashboard .color-dropdown{
    display: flex;
    align-self: center;
    padding-top: 5px;
}
.consumer-report-dashboard .color-dropdown-component{
    height: inherit;
}

.row.pdf-data.consumer-reports-data-content .card{
  border-bottom: 1px solid rgba(0,0,0,.2);
}

.reports-display-content .include-rearrange-wrapper{
    display: flex;
    /*flex-direction: row;*/
}

.reports-display-content .include-exclude-wrapper{
    border-right: 1px solid #dee2e6;
    padding-right: 70px;
    margin-right: 70px;

}
/************* Edit Dialogue-include/exclude items ***************/
.reports-display-content .include-exclude-header{
  padding-bottom: 2px;
  display: flex;
}

.reports-display-content .include-exclude-header label{
  padding-right: 5px;
}

.reports-display-content .label-select-all label{
    padding-left: 3px;
}
.reports-display-content .label-select-all{
    padding-left: 10px;
}

.reports-display-content input:indeterminate {
  background-color: var(--global-footer-color) !important;
}

/************* Edit Dialogue-logo ***************/
.reports-display-content .form-check.mb-2 .logo-default-display{
  width:200px;
  height:20px;
  padding-left: 10px;
  vertical-align:text-bottom;
}

.reports-display-content .input-group .custom-logo-display{
  width:200px;
  height:20px;
  padding-left: 10px;
  display: inline-flex;
  vertical-align:text-bottom;
}
.reports-display-content .input-group{
  align-items: baseline;
  display: flex;
}
/************* Sticky Header ***************/
.consumer-report-dashboard.sticky{
  margin-right: 97px;
  margin-left: 28px;
  flex-direction: row;
  display:flex;
  border-radius: 0.5rem;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.08);
  border-width: 1px;
  box-shadow: 0 3px 10px 0 rgb(99 118 129 / 30%);
  background-color: white;
  margin-bottom: 5px;
}

/************* Button Group ***************/
.report-dashboard.row .report.button-group{
  position: -webkit-sticky;
  position: sticky;
  top:0;
  display:flex;
  flex-grow: 1;
}


/************* Title ***************/
.report-dashboard.segment-name{
  font-family: Helvetica, Arial, "Roboto", sans-serif;
  color:var(--global-landing-page-color);
  font-size: 20px;
  font-weight: 600;
  letter-spacing:1px;
  display:flex;
  flex-grow: 2;
  align-self: center;
  padding-left: 10px;

}

/************* Fonts ***************/
.reports-display-content,
.reports-display-content h4,
.reports-display-content .modal-dialog.modal-lg label,
.reports-display-content .modal-dialog.modal-lg .modal-title,
.report.button-group .generateBtn,
.report.button-group .editBtn{
    font-family: Helvetica, Arial, "Roboto", sans-serif !important;
}

.report-dashboard.segment-name {
    align-items: center;
}
.report-dashboard.segment-name .generateBtn {
    margin-right: 3rem;
}

.reports-display-content .modal.fade.bd-example-modal-lg .modal-content .modal-body .row label,
.reports-display-content .modal.fade.bd-example-modal-lg .modal-content .modal-body .row input,
.reports-display-content .modal.fade.bd-example-modal-lg .modal-content .modal-body .row textarea{
  color:rgba(88, 88, 88, 88) !important;
}
.reports-display-content .modal.fade.bd-example-modal-lg .modal-content .modal-body .form-control.input-rounded.report-date{
  width:fit-content;
}
.reports-display-content .modal.fade.bd-example-modal-lg .modal-content .modal-body form{
  border:none !important;
}
.reports-display-content .modal.fade.bd-example-modal-lg .form-control.form-control.input-rounded{
  border-radius: 0.375rem !important;
}
.reports-display-content .modal.fade.bd-example-modal-lg .modal-content .modal-body .logo-tooltip{
  font-size: 14px;
}

.reports-display-content .table td{
  font-size: 16px;
}

.reports-display-content .table th{
  font-size: 18px;
}
.reports-display-content .consumer-dashboard-notes p{
  color:black;
  font-family: inherit;
  font-size: 16px;
}
.modal.fade.bd-example-modal-lg textarea{
  font-family: inherit;
  word-wrap: normal;
  word-break: keep-all;
  -ms-word-break: keep-all;
  -ms-word-wrap: normal;
  min-height:100px;
}

.modal.fade.bd-example-modal-lg .form-group {
  outline-color:none;
}



/*REPORTS DISPLAY DASHBOARD*/
.reports-display-content {
  height: 91%;
  overflow-y: auto;
  margin-left: 2%;
  margin-right: 2%;
}
.reports-data-content {
  width: 100%;
}

/*********** PDF Report Formatting(.page-template)  **********/
.page-template.consumer-report .header {
  width: 100%;
  height: 90px;
  background-color: #d7eef2;
  position: absolute;
    text-align: left;
}

/*PDF Footer Position*/
.page-template.consumer-report .footer .copyright,
.page-template.consumer-report .footer .page-number-wrapper{
    vertical-align: bottom;
    padding: 0;
    height: fit-content;
    display: inline-block;
}
.page-template.consumer-report .footer .page-number-wrapper{
    float: right;
}
.page-template.consumer-report .footer {
    background-color: #ffffff;
    border-top: 1px solid black;
    font-size: 20px;
    font-family: Helvetica, Arial, "Roboto", sans-serif !important;
    color:black;
    text-align: left;
    margin-left: 2%;
    margin-right: 2%;
    width: 96%;
    height: 90px;
    padding-left:0;
    position:absolute;
    bottom: 0;
}

/*****PDF Logo Position *****/
.page-template.consumer-report .header .report-logo{
    float: left;
    width:auto;
    text-align: left;
    margin-top: 20px;
    height: 30px;
    /*margin-left: -300px;*/
    display: block;
}
.page-template.consumer-report .header .report-information.aside{
    text-align: right;
    font-size: 25px;
    float: right;
    margin-left: auto;
    margin-right: 30px;
    font-family:Helvetica, Arial, "Roboto", sans-serif;
}

.page-template.consumer-report .report-information.aside .report-title{
    margin-left: auto;
    margin-right: 0;
}
.page-template.consumer-report .report-information.aside .report-date{
    margin-left: auto;
    margin-right: 0;
}

.page-template.consumer-report table td {
  font-size: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.page-template.consumer-report table th {
  font-size: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
   font-weight: bolder;
}
.row.pdf-data.consumer-reports-data-content .col-xl-6.col-lg-6.col-md-6 .report-card {
  position:relative;
  margin:0rem 0.7rem 0.7rem 0.7rem;
transition: all 0.5s ease-in-out;
height:fit-content;
width:auto;
}
.row.pdf-data.consumer-reports-data-content .col-xl-6.col-lg-6.col-md-6.col-sm-6 .card {
  border-color: none;
  box-shadow: none !important;
}
.reports-display-content .button-group{
  margin:1rem 1rem 1rem 1rem;
  display: flex;
  }

  .row.pdf-data.consumer-reports-data-content .text-nowrap{
    white-space:nowrap;
  }

  .row.pdf-data.consumer-reports-data-content .white-space{
    white-space:normal;
  }

  .row.pdf-data.consumer-reports-data-content .row .mx-auto.col-xl-7.col-lg-7.col-md-7.col-sm-7 .card{
    border-color: none;
    box-shadow: none !important;
  }

.row.pdf-data.consumer-reports-data-content .card .row{
width:auto;
}

.row.pdf-data.consumer-reports-data-content .card .card-header h4 {
  font-size: 28px;
  font-weight: bolder;
}

.row.pdf-data.consumer-reports-data-content .card .text-center{
  border-color: #f0f1f5;
  position: relative;
  background: transparent;
  padding: 1.5rem 1.875rem 1.25rem;
  border-bottom: 1px solid rgba(0,0,0,.2);
}
.row.pdf-data.consumer-reports-data-content .card .card-header .card-title{
  align-self: center;
}

.row.pdf-data.consumer-reports-data-content{
  width: 100%;
}

.page-template.consumer-report{
  display: flex;
  flex-direction: column;
}


.page-template.consumer-report .row.d-flex.inner-footer{
  width:100%;
  }

/******************************************* PDF styles *******************************************/

.k-pdf-export .color-dropdown,
.k-pdf-export .card-body-graphs-map .map-style-radio-btn{
    /*hide drop down in pdf*/
    display: none;
}

/************ Hide Map *********************/
.k-pdf-export .report-map{
    /*display: none;*/
}
/*******************************************/
.k-pdf-export .row.pdf-data.consumer-reports-data-content .table thead{
  background-color: #ffffff;
  /*font-size: 30px;*/
}

.k-pdf-export .row.pdf-data.consumer-reports-data-content .table tbody tr:nth-child(odd){
  background-color:#F9F9F9;
  /*font-size: larger;*/
}

.k-pdf-export h4{
  /*font-size: 26px !important;*/
  font-family:Helvetica, Arial, sans-serif !important;
}

.k-pdf-export td,
.k-pdf-export strong,
.k-pdf-export th,
.k-pdf-export p {
  /*font-size: 20px !important;*/
  font-family:Helvetica, Arial, sans-serif !important;
}
.k-pdf-export canvas{
    width: 600px !important;
    height: auto !important;
}
.row.pdf-data.consumer-reports-data-content .card-body-graphs canvas{
    /*max-width: 680px !important;*/
    margin: 30px 0 10px 0;
    /*height: 460px !important;*/
}
.row.pdf-data.consumer-reports-data-content .card-body-graphs.social-matrix{
height: 600px;
    width: 900px;
align-self: center;
    text-align:-webkit-center;

}

/******** Tabel and Graphs Cards ******/

.col-xl-6.col-lg-6.col-md-6 .card .card-body {
  position: relative;
  height: 600px;
  width: auto;
  /*height: fit-content;*/
}
.col-xl-6.col-lg-6.col-md-6 .card{
  margin-right: 0;
}
.card-body-ActivateSegmentSummary {
  position: relative;
  /* height: fit-content; */
  width: auto;
  flex: 1 1 auto;
  padding: 1rem 1rem;
}

.modal-dialog.modal-lg .form-control.input-rounded{
height:2.2rem ;
}


 /********START**************** Chart Size and Position For Different Window Sizes **************START************/
.row.pdf-data.consumer-reports-data-content .card-body-graphs{
    /*height: 400px;*/
}
/* @media screen and (min-width: 3840px){*/
/*  .row.pdf-data.consumer-reports-data-content .col-xl-6.col-lg-6.col-md-6 .card .card-body-graphs{*/
/*    padding-left: 10px;*/
/*      margin-bottom: 0px;*/
/*        height:30rem;*/
/*  */
/*     }*/
/* }*/

/* @media screen and (min-width: 2880px) and (max-width: 3839px){*/
/*  .row.pdf-data.consumer-reports-data-content .col-xl-6.col-lg-6.col-md-6 .card .card-body-graphs{*/
/*    padding-left: 10px;*/
/*      margin-bottom: 0px;*/
/*      height:30rem;*/
/*  */
/*     }*/
/* }*/

/* @media screen and (min-width: 2560px) and (max-width: 2879px){*/
/*  .row.pdf-data.consumer-reports-data-content .col-xl-6.col-lg-6.col-md-6 .card .card-body-graphs{*/
/*    padding-left: 10px;*/
/*      margin-bottom: 0px;*/
/*      height:30rem;*/
/*  */
/*     }*/
/* }*/

/* @media screen and (min-width: 1921px) and (max-width: 2559px){*/
/*  .row.pdf-data.consumer-reports-data-content .col-xl-6.col-lg-6.col-md-6 .card .card-body-graphs{*/
/*    padding-left: 10px;*/
/*        margin-bottom: 0px;*/
/*        height:30rem;*/
/*     }*/
/* }*/

/* @media screen and (min-width: 1299px) and (max-width: 1920px){*/
/*  .row.pdf-data.consumer-reports-data-content .col-xl-6.col-lg-6.col-md-6 .card .card-body-graphs{*/
/*    padding-left: 30px;*/
/*      margin-bottom: 0px;*/
/*      height:35rem;*/
/*     }*/
/*        .row.pdf-data.consumer-reports-data-content .card .card-body-graphs-map.prevent-split{*/
/*         padding-bottom: 20px;*/
/*            margin: 10px 5px 30px 5px;*/
/*     }*/
/* }*/

/* @media screen and (min-width:1281px) and (max-width: 1300px){*/

/*  .row.pdf-data.consumer-reports-data-content .col-xl-6.col-lg-6.col-md-6 .card {*/
/*    overflow-x: auto;*/
/*     }*/

/*  .row.pdf-data.consumer-reports-data-content .col-xl-6.col-lg-6.col-md-6 .card .card-body-graphs{*/

/*      margin-left: 5px;*/
/*      margin-right: 5px;*/
/*      !*padding-bottom: 60px;*!*/

/*      !*margin-bottom: 25px;*!*/
/*      margin-bottom: 0px;*/

/*      width: 38rem;*/
/*      height:35rem;*/
/*     }*/

/* }*/

/* @media screen and (min-width: 1000px)and (max-width: 1280px){*/

/*  .row.pdf-data.consumer-reports-data-content .col-xl-6.col-lg-6.col-md-6 .card {*/
/*    overflow-x: auto;*/
/*     }*/

/*.row.pdf-data.consumer-reports-data-content .col-xl-6.col-lg-6.col-md-6 .report-card {*/
/*    position:relative;*/
/*    margin:0rem 1rem 1rem 1rem;*/
/*    transition: all 0.5s ease-in-out;*/

/*}*/

/*  .row.pdf-data.consumer-reports-data-content .col-xl-6.col-lg-6.col-md-6 .card .card-body-graphs{*/

/*        margin-left: 5px;*/
/*        margin-right: 5px;*/
/*        !*padding-bottom: 60px;*!*/

/*        !*margin-bottom: 25px;*!*/
/*      margin-bottom: 0px;*/

/*        height:35rem;*/
/*        width: 38rem;*/
/*     }*/

/* }*/

 /******END****************** Chart Size and Position For Different Window Sizes **************END************/

.reports-display-content{
  margin-left: 0;
}
.example-config{
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

/*********** Cancel Button  **********/

button.btn.light.btn-primary.btn-rounded {
  background: white ;
  padding: 12px 26px ;
  /* border: 1px solid #f5f5fe !important; */
  color: var(--global-landing-page-color) ;
  border: 0.1rem solid var(--global-landing-page-color) ;
}

button.btn.light.btn-primary.btn-rounded:hover {
  background-color: var(--global-landing-page-color);
  border-color: var(--global-landing-page-color);
  color: #fff ;
  padding: 12px 26px;
}

/*********** Table Text Align  **********/
.card .right{
  text-align: right;
}


.footer .left{
  text-align: left;
}

.footer .center{
  text-align: center;
}

/*********** Download Data Dialogue  **********/
.dialog-standard.download-data .k-window-title.k-dialog-title{
    font-size: 18px;
    text-wrap: pretty;
}