.dialog-standard .k-dialog .k-dialog-content .left-of-field-label {
    padding-bottom: 0rem;
}


.merge-non-zero-segments {

}

li.merge-segments-item {
    display: inline-block;
    margin-right: .5rem;
}

li.merge-segments-item:after {
    content: "\00b7";
    margin-left: .5rem;
}

li.merge-segments-item:last-child:after {
    content: "";
}

.merge-nonzero-segments-list {
    padding-top: 1.2rem;
    padding-bottom: .5rem;
    font-size: 14px
}

.merge-zero-segments-list {
    padding-bottom: .5rem;
    font-size: 14px
}