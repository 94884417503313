.not-found-page-wrapper{
 font-family: Helvetica, Arial, 'Roboto', sans-serif;
}

.not-found-page-wrapper .error-header{
    text-align: center;
    margin: 0 0 30px 0;
}
.not-found-page-wrapper .button-wrapper{
    margin: 30px 0 0 0;
}
.not-found-page-wrapper .error-message{
    text-align: center;
}

.not-found-page-wrapper .k-card.not-found-page-card.k-card-vertical{
    border: none;
    max-width: 1000px;
    max-height: 500px;
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.not-found-page-wrapper .error-message-text,
.not-found-page-wrapper .error-page-icon,
.not-found-page-wrapper .error-page-error-label{
    font-size: 16px;
}