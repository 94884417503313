.business-map.card-body-graphs-map.prevent-split{
    padding-bottom: 20px;
    margin: 10px 5px 30px 5px;
}

/*********************** Map Radio Control ****************** */
.card-body-graphs-map.prevent-split .map-style-radio{
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
}
.card-body-graphs-map.prevent-split .map-style-radio .k-radio-item{
    padding-top: 0;
}
.card-body-graphs-map.prevent-split .map-style-radio .k-radio-list.k-list-horizontal{
    padding-left: 5px;
}
