.labels {
    line-height: 20px;
    color: rgba(0, 0, 0, 0.54);
    padding-bottom: .2rem;
    font-size: 16px;
}
.nameFieldsDiv {
    display: flex;
    flex-direction: row;
    flex-basis: 100%;
    justify-content: space-between;

    /*flex: 1 1 0;*/
}
.k-multiselect.k-input.fields {
    border-style: solid;
    border-width: 2px;
    border-color: rgb(0 0 0 / 8%);
    background-color: whitesmoke;
}
.k-multiselect.k-input.fields.legislative-districts-multi-select.k-input-lg.k-rounded-md.k-input-solid.k-valid{
    max-height: 100px;
}

.naics-code-wrapper .k-multiselect.k-input.fields.k-input-lg.k-rounded-md.k-input-solid.k-valid{
    max-height: 100px;
    max-width: 500px;
}
.legislative-district-wrapper{
    padding-right: 15px;
}

.legislative-districts-multi-select .k-chip .k-chip-content .k-chip-label {
    white-space: normal;
}