.shepherd-has-title .shepherd-content .shepherd-header{
    padding:10px !important;
}

.side-nav-item-wrapper .contactUsSidebar {
    width: 100%;
    padding-top: 0%;
}
.side-nav-item-wrapper .contactUsSidebar:hover {
    background-color: var(--global-landing-page-color) !important;
}

.side-nav-item-wrapper .k-drawer-items .k-item-text{
font-size: 18px !important;
font-family: Helvetica, Arial, "Roboto", sans-serif;
align-self: center;
}

/* #root .side-nav-drawer .k-drawer .k-drawer-wrapper,
#root .side-nav-drawer .k-drawer .k-drawer-wrapper .k-drawer-items {
    height: 100%;
    max-height: 100%;
} */

/*style tour icon to be at the bottom*/
.side-nav-item-wrapper .k-drawer-wrapper li:last-child{
    position: absolute;
    bottom: 0;
}
.side-nav-item-wrapper .k-drawer-expanded .k-drawer-item{
    /*flex-grow: 1;*/
    flex-flow: column nowrap;
    padding: 8px 8px 8px 15px;
    width: 100%;
    text-align: left;
    display: table-cell;
    /*align-self: baseline;*/
}

.side-nav-item-wrapper .k-drawer-item{
    /*flex-grow: 1;*/
    flex-flow: column nowrap;
    /*padding: 8px 8px 8px 0px;*/
    padding-left: .3rem;
    width: 100%;
    text-align: left;
    display: table-cell;
    /*align-self: baseline;*/
}


.side-nav-item-wrapper .k-drawer-items{
    /*padding-left: 10px;*/
    /*align-items: flex-start;*/
    width: 100%;
    margin-top: 0.5rem;

}
.contact-us-class{
    flex-grow: 2;
    padding-left: 5px !important;
    /*margin-left: 20px;*/

}
.side-nav-item-wrapper .k-widget.k-drawer.k-drawer-start, .k-widget.k-drawer.k-drawer-start .k-drawer-wrapper{
    height:100%;
    display: flex;
}
/* #root .side-nav-drawer .k-drawer .k-drawer-wrapper .k-drawer-items .contact-us-class {
    position: absolute;
    bottom: 0;
    width: 100%;

} */

/* @media screen and (max-height: 730px) {
    #root .side-nav-drawer .k-drawer .k-drawer-wrapper .k-drawer-items .contact-us-class {
        position: relative;
    }
} */

.side-nav-item-wrapper .k-drawer-container .k-drawer .k-drawer-wrapper .k-drawer-items .k-drawer-item .measurement-graph-icon-sidebar {
    display: flex;
}

.side-nav-item-tooltip {
    width: 8rem;
    text-align:center;
}

.shepherd-footer {
    align-items: center;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    display: flex;
    justify-content: space-between;
    padding: 0 .75rem .75rem;

    .shepherd-button:last-child {
        margin-right: 0;
    }

    .shepherd-progress {
        font-size: .8rem;
    }
}


