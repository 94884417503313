.admin-tabstrip-container-card {
    /* max-height: 90vh; */
    /* overflow-y: auto; */
}
.section-card.admin-tabstrip-container-card.box-shadow-standard.section-card-outer-margins{
    display: flex;
}

.section-card.admin-tabstrip-container-card.box-shadow-standard.section-card-outer-margins .k-content.k-active.admin-tabstrip-user{
    overflow: unset;
}

.dialog-standard {
    overflow-y: auto;
}

@media (max-width: 1048px) {
    .body_background .ftps3-dialog {
        align-items: normal;
    }
}

@media (max-height: 675px) {
    .body_background .ftps3-dialog {
        justify-content: normal;
    }
}

.admin-permissions-below-manager-text {
    font-size: 15px;
    line-height: 1.3em;
    margin-top: 2em;
    color:var(--global-landing-page-color);
    font-family: Helvetica, Arial, 'Roboto', sans-serif;
}

@media (max-width: 1215px) {
    .admin-permissions-below-manager-text {
        font-size: 1.1vw;
        /*line-height: 1em;*/
    }

    .userManagerHeaderDiv {
        margin-left: 1rem;
    }

}

@media (max-width: 1100px) {

}

.admin-tabstrip-container-card .k-animation-container {
    width: 100%;
}

.admin-tabstrip-container-card .k-tabstrip-items .k-link {
    font-size: 1.1rem;
    color: var(--global-landing-page-color);
    font-family: Helvetica, Arial, 'Roboto', sans-serif;
}

.admin-tabstrip-container-card .k-tabstrip .k-tabstrip-items .k-state-active .k-link,
.admin-tabstrip-container-card .k-tabstrip .k-tabstrip-items .k-active .k-link {
    color: var(--global-landing-page-color);
    font-weight: bold;
    font-family: Helvetica, Arial, 'Roboto', sans-serif;
}
.admin-tabstrip-container-card .k-item:nth-child(7),
.admin-tabstrip-container-card .k-item:nth-child(8),
.admin-tabstrip-container-card .k-item:nth-child(9),
.admin-tabstrip-container-card .k-item:nth-child(10),
.admin-tabstrip-container-card .k-item:nth-child(11)

{
    background-color: lightgoldenrodyellow !important;
}

.ftp-settings-controls-container .connection-settings {
    /*margin-left: 1rem;*/
    border-radius: 0.25rem;
    width: 100%;
    height: 2.2rem;
}

.ftp-settings-controls-container .existing-connections-dropdown-div {
    width: 100%;
    /*margin-right: 1rem;*/
}

.ftp-settings-controls-container .ftp-settings-fts-row {
    padding-top: 0.3rem;
    padding-bottom: 0.2rem;
}

.ftp-settings-fts-row .fts-label {
    font-size: 1.1rem;
    font-weight: bold;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.k-animation-container {
    width: 6rem;
}

.k-animation-container .k-popup {
    border: var(--global-landing-page-color);
}

.section-card .k-content .ftp-settings-controls-container .ftp-settings-fts-row .storage-service-button {
    margin-left: 0.3rem;
    width: 8.5rem;
}

.section-card .k-content .ftp-settings-controls-container .ftp-settings-fts-row .storage-service-bg-button {
    width: 6rem;
    height: 2.5rem;

    background-color: #FFFFFF;
    color: var(--global-landing-page-color);
    border: 1px solid var(--global-landing-page-color);
}

.section-card .k-content .ftp-settings-controls-container .ftp-settings-fts-row .storage-service-bg-button-active {
    width: 6rem;
    height: 2.5rem;

    background-color: var(--global-landing-page-color);
    color: #FFFFFF;

    border: 1px solid var(--global-landing-page-color);
}

.transfer-settings-header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.dropdown-file-transfer {
    width: 8.5rem;
    position: relative;
    left: 5px;
    /*background-color: #00424d;*/
    /*color: #00424d;*/
}

.dropdown-new-fts {
    position: relative;
    left: 17px;
}

.section-card .ftp-settings-controls-container .ftp-settings-fts-row .new-ftp-button {
    margin-left: 1rem;
}

.storage-service-dropdown-button-div {
    display: flex;
    flex-direction: column;
}

.existing-connections-dropdown-div {
    display: flex;
    flex-direction: column;
}

.fts-tab-ec-label {
    /*margin-left: 1rem;*/
    margin-bottom: .3rem;
}

.fts-tab-ss-label {
    margin-left: 0.5rem;
    margin-bottom: .2rem;
}

.fts-controls {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    align-items: end;
}

.onboarding-content .ftp-s3-tab-outer-div .connections-container-div {
    margin: 0rem 0rem 0rem 0.5rem;
}

.onboarding-outer-div .onboarding-content .ftp-s3-tab-outer-div .manage-conn-button {
    width: 13rem;
}