.admin-account-pricing .form-control.form-control-custom.form-select,
.admin-create-account .form-control.form-control-custom.form-select,
.admin-account-update .form-control.form-control-custom.form-select{
    height: fit-content;
    width: 100%;
    border: solid 1px #6e6e6e !important;
    font-size: 14px;
}
.admin-account-pricing .form-group,
.admin-create-account .form-group,
.admin-account-update .form-group{
    margin-bottom: 0;
}
.admin-account-pricing form,
.admin-create-account form,
.admin-account-update form{
    border-color: transparent;
}
.admin-account-pricing .form-control,
.admin-create-account .form-control,
.admin-account-update .form-control{
    height: fit-content;
    border: solid 1px #6e6e6e !important;
    font-size: 14px;
}
.admin-account-pricing .react-bootstrap-table th,
.admin-create-account .react-bootstrap-table th,
.admin-account-update .react-bootstrap-table th{
    font-size: 16px;
}
.admin-account-pricing .react-bootstrap-table td,
.admin-create-account .react-bootstrap-table td,
.admin-account-update .react-bootstrap-table td{
    font-size: 14px;
}
.admin-account-pricing select,
.admin-create-account select,
.admin-account-update select{
    border-radius: 5px !important;
}

.admin-account-pricing .card-title,
.admin-create-account .card-title,
.admin-account-update .card-title{
    font-size: 16px;
    font-family: Helvetica, Arial, 'Roboto', sans-serif;
    color: var(--global-landing-page-color);
    font-weight: bold;
}
.admin-account-pricing input,
.admin-create-account input,
.admin-account-update input{
    border-radius: 5px !important;
}
.admin-account-pricing .btn.btn-primary.float-right,
.admin-create-account .btn.btn-primary.float-right,
.admin-account-update .btn.btn-primary.float-right{
    padding: 6px 10px 6px 10px !important;
}
.admin-account-pricing .btn.btn-primary,
.admin-create-account .btn.btn-primary,
.admin-account-update .btn.btn-primary{
    padding: 8px 12px 8px 12px !important;
    font-size: 14px;
}

.admin-account-pricing td .pricing-cell .form-control,
.admin-account-pricing td .default_media_cost_rate .form-control,
.admin-account-pricing td .default_cpm_min_cap .form-control,
.admin-account-pricing td .default_cpm .form-control{
    cursor: pointer;
    border: none !important;
    border-radius: 5px;
}

.admin-account-pricing td .default_media_cost_rate .form-control:hover,
.admin-account-pricing td .default_cpm_min_cap .form-control:hover,
.admin-account-pricing td .default_cpm .form-control:hover{

    border: solid 1px #6e6e6e !important;
    border-radius: 5px;
}
.admin-account-pricing td .pricing-cell .form-control:hover{
    cursor: pointer;
    border: solid 1px #6e6e6e !important;
    border-radius: 5px;
}
.admin-tabstrip-container-card.box-shadow-standard.section-card-outer-margins .btn.btn-outline-primary{
    padding: 6px 10px 6px 10px !important;
}

.disabled-form input,
.disabled-form select,
.disabled-form textarea,
.disabled-form button {
    pointer-events: none;  /* Prevents the elements from being clickable or focusable */     /* Reduces the opacity to visually indicate they are disabled */
}

.section-card.section-card-inner-margins.admin-profile-account .form-group{
    cursor: not-allowed;
}
.section-card.section-card-inner-margins.admin-profile-account .col-4.account-details-ads-type-div.field-left-margin{
    cursor: not-allowed;
}
